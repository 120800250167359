<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：仓储相关物仓库配置请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addWarehouseConfig(-1)">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="configTable" row-key="id" :indent="25" :data="warehouseData"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <!-- <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column> -->
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateWareHouseConfig(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" v-if="scope.row.is_system==0" @click="deleteWareHouseConfig(scope.row)"
              title="删除"></i>
            <i class="el-icon-circle-plus-outline icon" @click="addWarehouseConfig(scope.row.id)" title="添加子级"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="encoding" label="编码" width="120"></el-table-column>
        <el-table-column prop="name" label="名称" sortable width="120"></el-table-column>
        <el-table-column prop="name" label="仓位号" width="80">
          <template slot-scope="scope">
            <span class="visable" @click="getWarehousePlaceData(scope.row)">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="departmentStr" label="所属部门" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="personnelStr" label="仓管员" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="仓库地址" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_system==1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>

    <!-- 新增/修改弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '仓库配置'" :visible.sync="show_addWareHouseBox" width="450px"
      append-to-body :before-close="cancleWareHouseConfig">
      <!-- 内容 -->
      <div class="dictionaryAddBox">
        <el-form :model="warehouseStr" ref="wareHouse_ruleform" :rules="wareHouse_rules" label-width="90px"
          class="demo-ruleForm" size="mini">
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="编码:" prop="encoding">
                <el-input v-model.trim="warehouseStr.encoding" placeholder="请输入编码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="warehouseStr.name" placeholder="请输入仓库名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="所属部门:" prop="department">
                <el-input v-model="warehouseStr.department" @click.native="SHOW_DEPARTMENTBOX(true)"
                  suffix-icon="el-icon-more" placeholder="请选择部门"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="仓管员:" prop="warehouseman">
                <el-input v-model="warehouseStr.warehouseman" @focus="SHOW_PERSONNELBOX(true)"
                  suffix-icon="el-icon-more" placeholder="请选择仓管人员"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="warehouseStr.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="电话:" prop="phone">
                <el-input v-model.number="warehouseStr.phone" placeholder="请输入联系电话"
                  oninput="value=value.replace(/[^\d]/g,'')">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="详细地址" prop="address">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="warehouseStr.address" resize="false">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="warehouseStr.remark" resize="false">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleWareHouseConfig">取 消</el-button>
        <el-button size="small" type="primary" @click="saveWareHouseConfig">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 增加仓位号弹框 -->
    <el-dialog title="仓位号" :visible.sync="show_placeBox" width="450px" append-to-body
      :before-close="cancleWareHousePlace">
      <!-- 内容 -->
      <div class="warehousePlaceBox">
        <!-- 操作按钮 -->
        <div class="operateBtn">
          <el-button size="mini" @click="showAddPlaceBox(-1)" type="primary">新增</el-button>
        </div>
        <!-- 树形结构 -->
        <div class="treeView">
          <el-tree :data="placeData" :props="defaultProps" :default-expand-all="true" :expand-on-click-node="false">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span class="icon">
                <i class="el-icon-circle-plus-outline" title="新增" @click="showAddPlaceBox(data.id)"></i>
                <i class="el-icon-edit-outline" title="编辑" @click="showUpdatePlaceBox(data)"></i>
                <i class="el-icon-delete" title="删除" @click="deletePlaceBox(data)"></i>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleWareHousePlace">取 消</el-button>
        <el-button size="small" type="primary" @click="saveWareHouseConfig">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 仓位号新增,修改弹框 -->
    <el-dialog title="新增仓位号" :visible.sync="show_placeAddBox" top="25vh" width="320px" append-to-body
      :before-close="cancleAddPlace" class="placeBox">
      <div class="addPlaceBox">
        <!-- 操作类型 -->
        <div class="operateType">
          <el-radio v-model="placeAddType" label="0">单条新增</el-radio>
          <el-radio v-model="placeAddType" label="1">批量新增</el-radio>
        </div>
        <!-- 单条新增 -->
        <div class="addPlaceContent" v-if="placeAddType == 0">
          <el-form :model="placeObj" ref="wareHousePlace_oneRuleform" :rules="warehousePlace_oneRuls" label-width="90px"
            class="demo-ruleForm" size="mini">
            <el-row>
              <el-col :span="24">
                <el-form-item label="仓位名称:" prop="name">
                  <el-input v-model.trim="placeObj.name" placeholder="请输入仓位"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="仓位编码:" prop="unit">
                  <el-input v-model.trim="placeObj.encoding" placeholder="请输入编码"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!-- 批量新增 -->
        <div class="addPlaceContent" v-if="placeAddType == 1">
          <el-form :model="placeBatchObj" ref="wareHousePlace_BatchRuleform" :rules="warehousePlace_batchRuls"
            label-width="90px" class="demo-ruleForm" size="mini">
            <el-row>
              <el-col :span="24">
                <el-form-item label="开始仓位:" prop="startName">
                  <el-input v-model.trim="placeBatchObj.startName" placeholder="请输入开始仓位"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="结束仓位:" prop="endName">
                  <el-input v-model.trim="placeBatchObj.endName" placeholder="请输入结束仓位"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="仓位单位:" prop="unit">
                  <el-input v-model.trim="placeBatchObj.unit" placeholder="请输入单位"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="编号前缀:" prop="encodingPrefix">
                  <el-input v-model.trim="placeBatchObj.encodingPrefix" placeholder="请输入前缀"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="编号顺序:" prop="encodingNum">
                  <el-input v-model.trim="placeBatchObj.encodingNum" placeholder="请输入顺序号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleAddPlace">取 消</el-button>
        <el-button size="small" type="primary" @click="saveAddPlace">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 仓位号修改弹框 -->
    <el-dialog title="修改仓位号" :visible.sync="show_placeUpdateBox" top="25vh" width="320px" append-to-body
      :before-close="cancleUpdatePlace" class="placeBox">
      <div class="addPlaceBox">
        <el-form :model="curUpdateObj" ref="wareHousePlace_updateRuleform" :rules="warehousePlace_updateRuls"
          label-width="90px" class="demo-ruleForm" size="mini">
          <el-row>
            <el-col :span="24">
              <el-form-item label="仓位号:" prop="name">
                <el-input v-model.trim="curUpdateObj.name" placeholder="请输入仓位"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="仓位号编码:" prop="encoding">
                <el-input v-model.trim="curUpdateObj.encoding" placeholder="请输入单位"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleUpdatePlace">取 消</el-button>
        <el-button size="small" type="primary" @click="saveUpdatePlace">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 部门选择组件 -->
    <department-tree @SelectedData="getDepartmentData"></department-tree>

    <!-- 负责人选择组件 -->
    <personnel-list @SelectedData="getPersonnelData" :defaultSelected="defaultSelected"></personnel-list>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入组件
  import personnelList from '@/components/commComponent/list/list_personnel'; //负责人列表组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件

  //导入方法
  import api from '@/network/system/dictionary'
  import Sortable from "sortablejs";

  export default {
    name: 'storage_warehouseConfig',
    data() {
      return {
        //当前页面数据
        warehouseData: [],//列表数据
        operateState: 0, //操作类型(0:新增  1:修改)
        //编辑框的值
        warehouseStr: {
          encoding: '', //仓库编码
          name: '', //仓库名称
          is_enable: '', //是否启用
          department: '', //所属部门
          departmentId: '', //所属部门Id
          warehouseman: '', //仓库管理
          warehousemanId: '', //仓库管理Id
          address: '', //地址
          remark: '', //备注
          phone: 0, //联系电话
          parentId: '', //父级id
          old_name: '' //编辑之前的名称
        },
        sourceData: {}, //定义编辑源数据
        defaultSelected: [], //默认选中

        //控制开关
        show_addWareHouseBox: false, //控制显示新增弹框
        show_placeBox: false, //控制显示仓位号弹框
        show_placeAddBox: false, //控制显示新增仓位号弹框
        show_placeUpdateBox: false, //控制显示修改仓位号弹框

        //表单验证规则
        wareHouse_rules: {}, //仓库添加规则
        warehousePlace_ruls: {}, //仓位号验证规则

        // 仓位号数据
        curSetWarehouseData: {}, //当前操作的仓库数据
        operatePlaceType: 0, //仓位号操作类型(0:新增  1:修改)
        placeObj: {//仓位数据(单条)
          name: '', //仓位
          encoding:'',//编码
        },
        placeBatchObj: {//仓位数据(多条)
          startName: '', //开始仓位
          endName: '', //结束仓位
          unit: '', //仓位单位
          encodingPrefix: '', //编号前缀
          encodingNum: '', //编码顺序号
        },
        curUpdateObj: {}, //当前修改的仓位号数据
        curOldUpdateObj: {}, //当前修改的旧仓位号数据
        placeAddType: '0', //仓位新增类型(0:单条新增  1:批量新增)
        placeParentId: -1, //仓位父级id
        placeData: [], //仓位号数据
        defaultProps: {
          children: 'children',
          label: 'name'
        }
      }
    },
    watch: {
      //监听仓库相关数据字典选项卡下标
      StorageTabIndex(newVal) {
        if (newVal == "2.3") {
          this.getWarehouseConfig(0)
        }
      },
      //监听拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    created() {
      this.initRules(); //初始化表单验证
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#configTable>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        StorageTabIndex: state => state.system.StorageTabIndex, //仓库配置点击下标
        SortIndex: state => state.system.SortIndex, //排序下标
        departmentData: state => state.hr.departmentData, //部门信息
        personnelData: state => state.hr.personnelData, //人员信息
      })
    },
    components: {
      personnelList,
      departmentTree,
    },
    methods: {
      ...mapMutations([
        'WAREHOUSE_CONFIG',
        'SHOW_DEPARTMENTBOX', //部门选择组件
        'SHOW_PERSONNELBOX' //员工选择组件
      ]),
      /* 获取点击部门数据 */
      getDepartmentData(data) {
        let departmentStr = '' //部门人员字符串
        let departmentIdStr = '' // 部门人员Id
        data.forEach((item, index) => {
          departmentStr = departmentStr + item.dept_name
          departmentIdStr = departmentIdStr + item.dept_id
          if (index != data.length - 1) {
            departmentStr = departmentStr + ','
            departmentIdStr = departmentIdStr + ','
          }
        })
        this.warehouseStr.department = departmentStr;
        this.warehouseStr.departmentId = departmentIdStr;
        this.$forceUpdate()
      },

      /* 点击获取负责人信息 */
      getPersonnelData(data) {
        this.defaultSelected = data
        let personnelStr = '' //负责人字符串
        let personnelIdStr = '' //负责人id
        data.forEach((item, index) => {
          personnelStr = personnelStr + item.user_name
          personnelIdStr = personnelIdStr + item.user_id
          if (index != data.length - 1) {
            personnelStr = personnelStr + ','
            personnelIdStr = personnelIdStr + ','
          }
        })
        this.warehouseStr.warehouseman = personnelStr;
        this.warehouseStr.warehousemanId = personnelIdStr;
        this.$forceUpdate()
      },

      /*点击新增触发*/
      addWarehouseConfig(parentId) {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.warehouseStr.parent_id = parentId //父级id
        //设置类型为新增
        this.operateState = 0;
        //显示新增弹框
        this.show_addWareHouseBox = true;
      },

      /* 点击编辑触发*/
      updateWareHouseConfig(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addWareHouseBox = true;
        this.warehouseStr.name = data.name;
        this.warehouseStr.encoding = data.encoding;
        this.warehouseStr.id = data.id;
        this.warehouseStr.parent_id = data.parent_id;
        this.warehouseStr.old_name = data.name;
        this.warehouseStr.is_enable = data.is_enable == 1 ? true : false;
        this.operateState = 1;
        this.warehouseStr.address = data.address
        this.warehouseStr.phone = Number(data.phone)
        this.warehouseStr.remark = data.remark
        //获得部门名称的字符串和ID
        this.warehouseStr.departmentId = data.dept_id
        this.warehouseStr.department = data.departmentStr
        // 获取负责人名称的字符串和ID
        this.warehouseStr.warehousemanId = data.principal_user
        this.warehouseStr.warehouseman = data.personnelStr
        this.sourceData = JSON.parse(JSON.stringify(this.warehouseStr)) //将源数据储存
      },

      /* 获取仓库配置数据*/
      getWarehouseConfig(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#configTable", 3);
        //发送请求
        api.storageDict.findAllWarehouse().then(res => {
          this.loading.close(); //关闭loading框
          this.show_addWareHouseBox = false; //关闭新增输入框
          if (res.code == 200) {
            res.data.forEach(item => {
              if (item.dept_id != null) {
                let departmentIdArr = item.dept_id.split(",")
                let departmentNameArr = []
                departmentIdArr.forEach(foo => {
                  this.departmentData.forEach((obj, index) => {
                    if (foo == obj.dept_id) {
                      departmentNameArr.push(obj.dept_name)
                    }
                  })
                })
                this.$set(item, 'departmentStr', departmentNameArr.join(","))
              }
              if (item.principal_user != null) {
                let warehousemanIdArr = item.principal_user.split(",")
                let warehousemanNameArr = []
                warehousemanIdArr.forEach(foo => {
                  this.personnelData.forEach((obj, index) => {
                    if (foo == obj.user_id) {
                      warehousemanNameArr.push(obj.user_name)
                    }
                  })
                })
                this.$set(item, 'personnelStr', warehousemanNameArr.join(","))
              }
            })
            //获取列表数据(只显示主仓和普通仓)
            this.warehouseData = res.data.filter(item=>[0,1].includes(item.is_main));
            //把查询的数据放入vuex中
            this.WAREHOUSE_CONFIG(res.data);
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '数据排序成功',
                duration: this.elDuration
              })
            }
          }
        })
      },

      /* 删除数据 */
      deleteWareHouseConfig(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#configTable", 2);
          //发送删除请求
          api.storageDict.deleteWarehouse({
            id: data.id, //仓库配置id
            name: data.name //仓库配置名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.getWarehouseConfig(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 取消新增或修改操作 */
      cancleWareHouseConfig() {
        this.warehouseStr = {
          encoding: '', //仓库编码
          name: '', //仓库名称
          is_enable: '', //是否启用
          department: '', //所属部门
          departmentId: '', //所属部门Id
          warehouseman: '', //仓库管理
          warehousemanId: '', //仓库管理Id
          address: '', //仓库地址
          remark: '', //备注
          phone: '', //联系电话
          old_name: '', //编辑之前的名称
          parent_id: '', //父级id
        }
        this.$refs['wareHouse_ruleform'].resetFields(); //移除表单验证规则
        this.show_addWareHouseBox = false;
      },

      /* 修改添加仓库配置*/
      saveWareHouseConfig() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['wareHouse_ruleform'].validate(valid => {
          // 表单验证全部合格
          if (valid) {
            // 判断新增还是修改
            if (this.operateState == 0) { //新增
              //定义传入后端的数据
              let data = {
                encoding: this.warehouseStr.encoding,
                name: this.warehouseStr.name,
                is_enable: this.warehouseStr.is_enable ? 1 : 0,
                address: this.warehouseStr.address,
                remark: this.warehouseStr.remark,
                dept_id: this.warehouseStr.departmentId,
                principal_user: this.warehouseStr.warehousemanId,
                latitude: '', //纬度
                longitude: '', //经度
                phone: this.warehouseStr.phone, //联系电话
                parent_id: this.warehouseStr.parent_id,
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#configTable", 0);
              //发送请求
              api.storageDict.addWarehouse(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) { //新增成功
                  this.getWarehouseConfig(1); //获取新数组
                  this.cancleWareHouseConfig()
                } else { //新增失败
                  // 显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            } else { //修改
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.warehouseStr, '仓库配置');
              //定义传入后端的数据
              let data = {
                id: this.warehouseStr.id,
                parent_id: this.warehouseStr.parent_id,
                name: this.warehouseStr.old_name,
                new_name: this.warehouseStr.name,
                encoding: this.warehouseStr.encoding,
                is_enable: this.warehouseStr.is_enable ? 1 : 0,
                address: this.warehouseStr.address,
                latitude: '', //纬度
                longitude: '', //经度
                phone: this.warehouseStr.phone,
                dept_id: this.warehouseStr.departmentId,
                principal_user: this.warehouseStr.warehousemanId,
                remark: this.warehouseStr.remark,
                logContent //日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#configTable", 1);
              //发送请求
              api.storageDict.updateWarehouse(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) { //修改成功
                  this.getWarehouseConfig(2); //获取新数组
                  this.cancleWareHouseConfig()
                } else {
                  //显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            }
          }
        });
      },

      /* 获取仓库位置号信息数据 */
      getWarehousePlaceData(data) {
        //显示弹框
        this.show_placeBox = true;
        //获取当前操作的仓库数据
        this.curSetWarehouseData = data;
        //获取仓位号数据
        this.getWarehousePlaceById(data.id);
      },

      /* 关闭仓库货位号弹框 */
      cancleWareHousePlace() {
        this.show_placeBox = false;
      },

      /* 显示仓库新增仓位号弹框 */
      showAddPlaceBox(parentId) {
        //设置父级id
        this.placeParentId = parentId;
        //清空数据
        this.placeObj = {//单条添加数据
          name: '', //仓位
          encoding:'',//编码
        }
        this.placeBatchObj = {//仓位数据(多条)
          startName: '', //开始仓位
          endName: '', //结束仓位
          unit: '', //仓位单位
          encodingPrefix: '', //编号前缀
          encodingNum: '', //编码顺序号
        },
        //设置类型
        this.placeAddType = "0";//设置为单条添加
        //显示弹框
        this.show_placeAddBox = true;
      },

      /* 显示仓位号修改弹框 */
      showUpdatePlaceBox(data) {
        //获取仓位号数据
        this.curUpdateObj = data;
        //存一份旧名称
        this.curUpdateObj.oldName = data.name;
        //显示修改弹框
        this.show_placeUpdateBox = true;
      },

      /* 删除仓位号 */
      deletePlaceBox(data) {
        this.$confirm('确认删除该数据及其子项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let deleteData = {
            id: data.id,
            logContent: '',
          }
          console.log(data);
          api.storageDict.deleteDepotFormPlace(deleteData).then(res => {
            if (res.code == 200) {
              //获取新的数据
              this.getWarehousePlaceById(this.curSetWarehouseData.id);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      /* 保存仓位号 */
      saveAddPlace() {
        let data = [];
        //判断是单条新增还是批量新增
        if (this.placeAddType == 0) { //单条新增
          // 验证表单数据
          this.$refs['wareHousePlace_oneRuleform'].validate(valid => {
            if (valid) {
              let item = {
                depot_form_id: this.curSetWarehouseData.id, //仓库id
                name: this.placeObj.name, //仓位
                parent_id: this.placeParentId, //父级id
                encoding: this.placeObj.encoding, //编码
              }
              data.push(item)
            } else {
              return
            }
          })
        } else if (this.placeAddType == 1) { //批量新增
          this.$refs['wareHousePlace_BatchRuleform'].validate(valid => {
            if (valid) {
              // 判断仓位差
              if (parseInt(this.placeBatchObj.endName) <= parseInt(this.placeBatchObj.startName)) {
                this.$message({
                  type: 'warning',
                  message: '开始仓位与结束仓位不合法',
                  duration: this.elDuration
                })
                return
              }
              //循环获取需要添加的数据
              for (let i = this.placeBatchObj.startName; i <= this.placeBatchObj.endName; i++) {
                let item = {
                  depot_form_id: this.curSetWarehouseData.id, //仓库id
                  name: i + this.placeBatchObj.unit, //仓位
                  parent_id: this.placeParentId, //父级id
                  encoding: this.placeBatchObj.encodingPrefix + this.placeBatchObj.encodingNum, //编码
                }
                data.push(item);
              }
            } else {
              return
            }
          })
        }
        //判断当未填数据时不发送请求
        if(data.length == 0){
          return
        }
        //定义后端添加数据
        let addData = {
          sysDepotFormPlace: JSON.stringify(data)
        }
        // 发送请求
        api.storageDict.addDepotFormPlace(addData).then(res => {
          if (res.code == 200) {
            //关闭弹框
            this.show_placeAddBox = false;
            //获取新的数据
            this.getWarehousePlaceById(this.curSetWarehouseData.id);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 修改仓位号数据 */
      saveUpdatePlace() {
        let data = {
          id: this.curUpdateObj.id, //需要修改的数据
          name: this.curUpdateObj.oldName, //旧的仓位名称
          newName: this.curUpdateObj.name, //新的仓位名称
          encoding: this.curUpdateObj.encoding, //仓位编码
          logContent: '', //日志
        }
        api.storageDict.updateDepotFormPlace(data).then(res => {
          if (res.code == 200) {
            //关闭弹框
            this.show_placeUpdateBox = false;
            //获取新的数据
            this.getWarehousePlaceById(this.curSetWarehouseData.id);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 根据仓库获取仓位号 */
      getWarehousePlaceById(id) {
        let data = {
          depotFormId: id,
        }
        api.storageDict.findDepotFormPlaceByFormId(data).then(res => {
          if (res.code == 200) {
            //获取数据
            this.placeData = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 取消新增仓位号弹框 */
      cancleAddPlace() {
        //判断当前界面是单条添加界面还是批量添加界面
        if(this.placeAddType == 0){
          //移除表单验证规则(单条)
          this.$refs['wareHousePlace_oneRuleform'].clearValidate();
        }else if(this.placeAddType == 1){
          //移除表单验证规则(多条)
          this.$refs['wareHousePlace_BatchRuleform'].clearValidate();
        }
        //关闭弹框
        this.show_placeAddBox = false;
      },

      /* 取消变价仓位号弹框 */
      cancleUpdatePlace() {
        this.show_placeUpdateBox = false;
      },

      /* 初始化表单验证规则*/
      initRules() {
        //添加仓库验证规则
        this.wareHouse_rules = {
          name: [ //仓库配置名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'change'
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'change'
            }
          ],
        }
        //仓位号验证规则
        this.warehousePlace_oneRuls = {
          name: [{ //规则1
              required: true, //是否必填
              message: '请输入仓位', //该规则错误信息提示
              trigger: 'change'
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'change'
            }
          ]
        }
        // 仓位号批量新增验证规则
        this.warehousePlace_batchRuls = {
          startName: [{ //规则1
              required: true, //是否必填
              message: '请输入开始仓位', //该规则错误信息提示
              trigger: 'change'
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'change'
            }
          ],
          endName: [{ //规则1
              required: true, //是否必填
              message: '请输入结束仓位', //该规则错误信息提示
              trigger: 'change'
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'change'
            }
          ]
        }
        //仓位号修改规则
        this.warehousePlace_updateRuls = {
          name: [{ //规则1
              required: true, //是否必填
              message: '请输入仓位号', //该规则错误信息提示
              trigger: 'change'
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'change'
            }
          ],
        }
      },

      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.StorageTabIndex == "2.3" && this.DictionaryLeftIndex == 2) {
          let data = {
            sourceId: this.warehouseData[newVal.oldIndex].id,
            targetId: this.warehouseData[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#configTable", 1);
          //发送请求
          api.storageDict.updateSysDepotFormSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getWarehouseConfig(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    }
  }
</script>

<style>
  /* 仓位号弹框样式 */
  .placeBox .el-dialog__body {
    padding: 10px !important;
  }
</style>
<style lang="less" scoped="scoped">
  /* 查看仓位号文字 */
  .visable {
    color: @theme;
    cursor: pointer;
  }

  /* 仓库仓位号弹框样式 */
  .warehousePlaceBox {
    height: 400px;
    overflow: auto;

    /* 操作按钮样式 */
    .operateBtn {}

    /* 仓位号显示数据 */
    .treeView {
      padding: 10px 0;

      // border: 1px solid black;
      //自定义样式
      .custom-tree-node {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // border: 1px solid black;

        //图标样式
        .icon {
          display: none;
          // border: 1px solid black;

          i {
            padding: 0 5px;
          }

          i:hover {
            color: @theme
          }
        }
      }

      //图标移入样式
      .custom-tree-node:hover {
        .icon {
          display: block;
        }
      }
    }
  }

  /* 添加仓位号弹框 */
  .addPlaceBox {
    height: 230px;

    //操作类型
    .operateType {
      padding: 5px 0 10px 0;
      text-align: center;
      // border: 1px solid black;
    }

    //内容
    .addPlaceContent {}
  }
</style>
