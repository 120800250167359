<template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="customerTabIndex" v-if="isPower">
      <el-tab-pane label="客户分类" name="0.1">
        <customerClassfi></customerClassfi>
      </el-tab-pane>
      <el-tab-pane label="客户状态" name="0.2">
        <customerState></customerState>
      </el-tab-pane>
      <el-tab-pane label="客户来源" name="0.3">
        <customerSource></customerSource>
      </el-tab-pane>
      <el-tab-pane label="客户关系" name="0.4">
        <cstomerRelation></cstomerRelation>
      </el-tab-pane>
      <el-tab-pane label="销售进程" name="0.5">
        <customerSellprocess></customerSellprocess>
      </el-tab-pane>
      <el-tab-pane label="跟进方式" name="0.6">
        <customerFollowway></customerFollowway>
      </el-tab-pane>
      <el-tab-pane label="会员等级" name="0.7">
        <customerMember></customerMember>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import customerClassfi from './customerItem/customer_classfi.vue'; //客户分类
  import customerState from './customerItem/customer_state.vue'; //客户状态
  import customerSource from './customerItem/customer_source.vue'; //客户来源
  import cstomerRelation from './customerItem/customer_relation.vue'; //客户关系
  import customerSellprocess from './customerItem/customer_sellprocess.vue'; //销售进程
  import customerFollowway from './customerItem/customer_followway.vue'; //跟进方式
  import customerMember from './customerItem/customer_member.vue'; //会员等级

  export default {
    name: 'dictonary_customer',
    data() {
      return {
        //选项卡下标
        customerTabIndex: "0.1",
        isPower: false, //是否有查询权限
      }
    },
    watch: {
      // 当选项卡下标发生改变时
      customerTabIndex(newVal) {
        this.CUSTOMER_TAB_INDEX(newVal);
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
    },
    methods: {
      ...mapMutations([
        'CUSTOMER_TAB_INDEX', //将客户数据字典选项卡下标存入vuex
      ]),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),
    },
    components: {
      customerClassfi,
      customerState,
      customerSource,
      cstomerRelation,
      customerSellprocess,
      customerFollowway,
      customerMember,

    }
  }
</script>
