<template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="storageTabIndex" v-if="isPower">
      <el-tab-pane label="物流方式" name="2.1">
        <logisticsMode></logisticsMode>
      </el-tab-pane>
      <el-tab-pane label="物流公司" name="2.2">
        <logisticsCompany></logisticsCompany>
      </el-tab-pane>
      <el-tab-pane label="仓库配置" name="2.3">
        <warehouseConfig></warehouseConfig>
      </el-tab-pane>
      <el-tab-pane label="送货方式" name="2.4">
        <deliveryType></deliveryType>
      </el-tab-pane>
      <el-tab-pane label="质检类型" name="2.5">
        <qualityType></qualityType>
      </el-tab-pane>
      <el-tab-pane label="检验方式" name="2.6">
        <testWay></testWay>
      </el-tab-pane>
      <!--      <el-tab-pane label="单据类型" name="2.5">
      <typeofDocument></typeofDocument>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  // 导入组件
  import logisticsMode from './storageItem/storage_logisticsMode.vue'; //物流方式
  import logisticsCompany from './storageItem/storage_logisticsCompany.vue'; //物流公司
  import warehouseConfig from './storageItem/storage_warehouseConfig.vue'; //仓库配置
  import deliveryType from './storageItem/storage_deliveryType.vue'; //送货方式
  import testWay from './storageItem/storage_testWay.vue'; //检验方式
  import qualityType from './storageItem/storage_qualityType.vue'; //质检类型
  import typeofDocument from './storageItem/storage_typeofDocument.vue'; //单据类型

  export default {
    name: 'dictonary_storage',
    data() {
      return {
        storageTabIndex: '2.1',
        isPower: false, //是否有查询权限
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    watch: {
      // 当选项卡下标发生改变时
      storageTabIndex(newVal) {
        this.STORAGE_TAB_INDEX(newVal)
      }
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
    },
    components: {
      logisticsMode,
      logisticsCompany,
      warehouseConfig,
      deliveryType,
      typeofDocument,
      qualityType,
      testWay
    },
    methods: {
      ...mapMutations([
        'STORAGE_TAB_INDEX', //将客户数据字典选项卡小标存入vuex
      ]),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),
    }
  }
</script>
