<template>
  <div class="systemItemIndex">
    <el-tabs tab-position="left" v-model="dictionaryIndex" style="height: 100%;">
      <el-tab-pane label="客户相关" name="0">
        <dictonaryCustomer v-if="dictionaryIndex==0"></dictonaryCustomer>
      </el-tab-pane>
      <el-tab-pane label="订单相关" name="1">
        <dictonarySales v-if="dictionaryIndex==1"></dictonarySales>
      </el-tab-pane>
      <el-tab-pane label="仓储相关" name="2">
        <dictonaryStorage v-if="dictionaryIndex==2"></dictonaryStorage>
      </el-tab-pane>
      <el-tab-pane label="财务相关" name="3">
        <dictonaryFinance v-if="dictionaryIndex==3"></dictonaryFinance>
      </el-tab-pane>
      <el-tab-pane label="生产相关" name="4">
        <dictonaryProduction v-if="dictionaryIndex==4"></dictonaryProduction>
      </el-tab-pane>
      <el-tab-pane label="供应商" name="5">
        <dictonarySupplier v-if="dictionaryIndex==5"></dictonarySupplier>
      </el-tab-pane>
      <el-tab-pane label="货品相关" name="6">
        <dictonaryProduct v-if="dictionaryIndex==6"></dictonaryProduct>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'

  //导入组件
  import dictonaryCustomer from './dataDictionaryItem/dictonary_customer.vue'; //客户
  import dictonaryStorage from './dataDictionaryItem/dictonary_storage.vue'; //仓储
  import dictonaryFinance from './dataDictionaryItem/dictonary_finance.vue'; //财务
  import dictonaryProduction from './dataDictionaryItem/dictonary_production.vue'; //生产
  import dictonarySupplier from './dataDictionaryItem/dictonary_supplier.vue'; //供应商
  import dictonaryProduct from './dataDictionaryItem/dictonary_product.vue'; //货品
  import dictonarySales from './dataDictionaryItem/dictonary_sales.vue'; //销售
  export default {
    name: '',
    data() {
      return {
        dictionaryIndex: '0', //数据字典模块分类选择下标
      }
    },
    computed: {
      ...mapState({
        CustomerTabIndex: state => state.system.CustomerTabIndex, //客户相关tab下标
        SalesTabIndex: state => state.system.SalesTabIndex, //销售相关tab下标
        StorageTabIndex: state => state.system.StorageTabIndex, //仓储相关tab下标
        FinanceTabIndex: state => state.system.FinanceTabIndex, //财务相关tab下标
        ProductionTabIndex: state => state.system.ProductionTabIndex, //生产相关tab下标
        SupplierTabIndex: state => state.system.SupplierTabIndex, //供应商相关tab下标
        ProductTabIndex: state => state.system.ProductTabIndex, //货品相关tab下标
      })
    },
    watch: {
      // 监听数据字典左侧模块分类切换
      dictionaryIndex(newVal) {
        // 调用方法设置默认选中右侧tab
        this.getDefaultTab(newVal);
      },
    },
    methods: {
      ...mapMutations([
        'DICTIONARY_LEFT_INDEX', //修改数据字典左侧导航栏下标
        'CUSTOMER_TAB_INDEX', //修改客户相关下标方法
        'SALES_TAB_INDEX', //修改销售相关下标方法
        'STORAGE_TAB_INDEX', //修改仓储相关下标方法
        'FINANCE_TAB_INDEX', //修改财务相关下标方法
        'PRODUCTION_TAB_INDEX', //修改生产相关下标方法
        'SUPPLIER_TAB_INDEX', //修改供应商下标方法
        'PRODUCT_TAB_INDEX', //修改货品相关下标方法
      ]),
      /* 获取默认选中tab页面*/
      getDefaultTab(index) {
        // 将下标存入vuex
        this.DICTIONARY_LEFT_INDEX(index);
        //左侧下标更换设置默认值
        switch (index) {
          case "0": //客户相关
            if (this.CustomerTabIndex == 0) {
              this.CUSTOMER_TAB_INDEX("0.1");
            }
            break;
          case "1": //销售相关
            if (this.SalesTabIndex == 0) {
              this.SALES_TAB_INDEX("1.1");
            }
            break;
          case "2": //仓储相关
            if (this.StorageTabIndex == 0) {
              this.STORAGE_TAB_INDEX("2.1");
            }
            break;
          case "3": //财务相关
            if (this.FinanceTabIndex == 0) {
              this.FINANCE_TAB_INDEX("3.1");
            }
            break;
          case "4": //生产相关
            if (this.ProductionTabIndex == 0) {
              this.PRODUCTION_TAB_INDEX("4.1");
            }
            break;
          case "5": //供应商
            if (this.SupplierTabIndex == 0) {
              this.SUPPLIER_TAB_INDEX("5.1");
            }
            break;
          case "6": //货品相关
            if (this.ProductTabIndex == 0) {
              this.PRODUCT_TAB_INDEX("6.1");
            }
            break;
        }
      }
    },
    components: {
      dictonaryCustomer,
      dictonaryStorage,
      dictonaryFinance,
      dictonaryProduction,
      dictonarySupplier,
      dictonaryProduct,
      dictonarySales,

    }
  }
</script>

<style>
</style>
