<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关货品来源请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="show_add" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="ProductSource" id="productsource" row-key="id" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="show_updatePD(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteProductSource(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system == 1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>
    <!-- 新增货品来源弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '货品来源'" :visible.sync="show_addProductSourceBox"
      :before-close="cancleProductSource" width="400px" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="AddProduct_Source" :rules="ProductSource_rules" ref="ProductSource_from" size="mini"
          label-width="80px">
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="编码:">
                <el-input v-model.trim="AddProduct_Source.encoding" placeholder="请输入编码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="AddProduct_Source.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="AddProduct_Source.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="AddProduct_Source.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleProductSource">取 消</el-button>
        <el-button size="small" type="primary" @click="addUpdate_PD">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: "product_source",
    data() {
      return {
        ProductSource_rules: [], //表单验证规则
        operateState: '0', //修改或者增加标记
        old_name: '', //修改之前的name
        AddProduct_Source: { //增加框属性
          encoding: '',
          name: '',
          remark: '',
          is_enable: true, //是否启用(0:否 1:是)
          is_system: true, //是否为系统默认(0:否 1:是)
        }, //新增货品来源属性

        //定义编辑源数据
        sourceData: {},
        show_addProductSourceBox: false, //控制新增修改货品来源控制开关
        id: '', //编辑时用到的id
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#productsource>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProductSource: state => state.system.ProductSource, //货品来源
        ProductTabIndex: state => state.system.ProductTabIndex, //客户数据字典选中下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //监听货品相关数据字典选项卡下标
      ProductTabIndex(newVal) {
        //判断下标是否为货品来源
        if (newVal == "6.5") {
          //判断vuex是否存在数据
          this.judjeFollowupWay();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);


        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCT_SOURCE', //存放货品来源到vuex
      ]),
      /* 取消新增或修改操作 */
      cancleProductSource() {
        this.$refs['ProductSource_from'].resetFields(); //移除表单验证规则
        this.show_addProductSourceBox = false;
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.ProductSource_rules = {
          name: [ //货品来源名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 展示增加货品来源框*/
      show_add() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.operateState = 0;
        this.AddProduct_Source = {}, //刷新增加弹框里面的数据
          this.show_addProductSourceBox = true;
      },
      /* 展示修改货品来源框*/
      show_updatePD(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.operateState = 1;
        this.AddProduct_Source.encoding = data.encoding
        this.AddProduct_Source.name = data.name;
        this.AddProduct_Source.remark = data.remark;
        this.old_name = data.name;
        this.id = data.id;
        this.AddProduct_Source.is_enable = data.is_enable == 1 ? true : false;
        this.AddProduct_Source.is_system = data.is_system == 1 ? true : false;
        this.show_addProductSourceBox = true;
        this.sourceData = JSON.parse(JSON.stringify(this.AddProduct_Source)) //将源数据储存
      },
      /* 判断vuex是否存在数据*/
      judjeFollowupWay() {
        // vuex中没有数据,从服务器请求
        if (this.ProductSource.length == 0) {
          this.findAllProductSource();
        }
      },
      /* 查询货品来源*/
      findAllProductSource(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#productsource", 3);
        api.productDict.findAllProductSource().then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.PRODUCT_SOURCE(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
            this.show_addProductSourceBox = false; //关闭新增输入框
          }
        });
      },
      /*增加货品来源*/
      addUpdate_PD() {
        this.$refs['ProductSource_from'].validate(valid => {
          if (valid) {
            if (this.operateState == 0) {
              let data = {
                encoding:this.AddProduct_Source.encoding,
                name: this.AddProduct_Source.name,
                remark: this.AddProduct_Source.remark,
                is_enable: this.AddProduct_Source.is_enable ? 1 : 0,
                is_system: '0',
              }
              this.loading = this.commonJsExtend.customLoading("#productsource", 1);
              api.productDict.addProductSource(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllProductSource(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_addProductSourceBox = false; //关闭新增输入框
              });
            } else if (this.operateState == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.AddProduct_Source, '货品来源');
              this.loading = this.commonJsExtend.customLoading("#productsource", 1);
              var data = {
                id: this.id,
                encoding:this.AddProduct_Source.encoding,
                name: this.old_name,
                remark: this.AddProduct_Source.remark,
                is_enable: this.AddProduct_Source.is_enable ? 1 : 0,
                is_system: '0',
                new_name: this.AddProduct_Source.name,
                logContent //日志
              }
              api.productDict.updateProductSource(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllProductSource(2);
                  this.show_addProductSourceBox = false;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration,
                  })
                }
                this.loading.close(); //关闭loading框
              });
            }
          }
        })
      },
      /* 删除货品来源*/
      deleteProductSource(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#productsource", 2);
          //发送删除请求
          api.productDict.deleteProductSource({
            id: data.id, //货品来源id
            name: data.name //货品来源名称
          }).then(res => {
            this.loading.close(); //关闭加载框
            if (res.code == 200) {
              this.findAllProductSource(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
        });
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.5" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.ProductSource[newVal.oldIndex].id,
            targetId: this.ProductSource[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#productsource", 1);
          api.productDict.updateSysProductSourceSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllProductSource(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
  }
</script>

<style>
</style>
