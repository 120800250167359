<template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="SalesTabIndex" v-if="isPower">
      <el-tab-pane label="报价类型" name="1.1">
        <orderType></orderType>
      </el-tab-pane>
      <!-- <el-tab-pane label="单据类型" name="1.2">
        <documentType></documentType>
      </el-tab-pane> -->
      <el-tab-pane label="结算方式" name="1.3">
        <paymentsWay></paymentsWay>
      </el-tab-pane>
      <el-tab-pane label="采购类型" name="1.4">
        <purchaseType></purchaseType>
      </el-tab-pane>
      <el-tab-pane label="价格条款" name="1.5">
        <price-clasue></price-clasue>
      </el-tab-pane>
      <el-tab-pane label="收汇方式" name="1.6">
        <paymentMode></paymentMode>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入组件
  import documentType from './sales/sales_orderDocumentType.vue'; //单据类型
  import orderType from './sales/sales_orderType.vue'; //报价类型
  import paymentsWay from './sales/sales_paymentsWay.vue'; //结算方式
  import purchaseType from './sales/purchase_type.vue'; //采购方式
  import priceClasue from './sales/price_clause'; //价格条款
  import paymentMode from './sales/payment_mode.vue'; //付汇方式

  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  export default {
    data() {
      return {
        SalesTabIndex: '1.1',
        isPower: false, //是否有查询权限
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    watch: {
      SalesTabIndex(newVal) {
        this.SALES_TAB_INDEX(newVal)
      }
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
    },
    methods: {
      ...mapMutations([
        'SALES_TAB_INDEX',
      ]),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),
    },
    components: {
      documentType,
      orderType,
      paymentsWay,
      purchaseType,
      priceClasue,
      paymentMode,
    }
  }
</script>

<style>
</style>
