<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关多计量单位请求接口
开始时间：2022-08-1
开发人员：刘巍骏
最后修改：2022-08-1
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addMoreMeasure">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="moreUnitTable" row-key="id" :data="moreUnitList" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateUnit(scope.row)" title="修改"></i>
              <i class="el-icon-delete icon" @click="deleteProductUnit(scope.row)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="计量名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="productTypeName" label="货品分类" width="120">
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.isEnable == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>

    <!-- 多计量设置弹框 -->
    <el-dialog :title="(operateMore == 0 ?'新增':'修改') + '多计量'" :visible.sync="show_addMoreMeasure"
      :before-close="cancleMoreUnit" width="570px" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="moreUnit" size="mini" label-width="120px" :rules="rules" ref="test">
          <!-- 分行显示 -->
          <el-row>
            <el-col :span="20">
              <el-form-item label="多计量名:" prop="name">
                <el-input v-model.trim="moreUnit.name" placeholder="请输入多计量名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="基础单位指定:" prop="basicDesignatedUnit" >
                <el-select class="input_unit_base" v-model="moreUnit.basicDesignatedUnit" size="mini" placeholder="基础单位指定,建议使用最小单位"
                :disabled="!(this.moreUnitData.length==1)">
                  <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.id"
                   @click.native="getMorefirstUnitId(item)"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="货品分类:" prop="product_type">
                <el-input v-model="moreUnit.product_type" @click.native="show_productType" placeholder="请选择货品分类">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="状态:" prop="is_enable">
                <el-checkbox v-model="moreUnit.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="说明:" prop="remark">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="moreUnit.remark" resize="none">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- 计量内容 -->
      <div class="moreUnitBox">
        <!-- 计量明细 -->
        <div class="itemUnit" v-for="(elem,indexI) in moreUnitData">
          <span class="title">计量设置:</span>
          <!-- 数量框 -->
          <el-input class="input_num" v-model="elem.firstCount" size="mini" placeholder="数量"
            oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
          <!-- 计量单位 -->
          <el-select class="input_unit" v-model="elem.firstUnit" size="mini" placeholder="单位" :disabled="true">
            <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- 分隔符 -->
          <span class="input_split">=</span>
          <span class="input_split">1</span>
          <!-- 计量单位 -->
          <el-select class="input_unit" v-model="elem.finalUnit" size="mini" placeholder="单位">
            <el-option v-for="(item,index) in ProductUnit" :key="item.id" :label="item.name" :value="item.id"
              @click.native="getMoreFinalUnitId(indexI,item)"></el-option>
          </el-select>
          <!-- 操作按钮 -->
          <div class=" operateright">
            <!-- 新增按钮 -->
            <div class="operateitem" v-if="indexI == 0">
              <i class="el-icon-circle-plus-outline" @click="addMoreUnit(indexI)"></i>
            </div>
            <!-- 删除按钮 -->
            <div class="operateitem" v-else>
              <i class="el-icon-remove-outline" @click="removeMoreUnit(indexI)"></i>
            </div>
          </div>
        </div>

        <!-- 指定单位设置 -->
        <div class="assignUnit" >
          <ul>
            <li>
              <span class="title">销售指定单位:</span>
              <el-select class="input_unit" v-model="moreUnit.saleAssignUnit" size="mini" placeholder="单位">
                <el-option v-for="item in moreUnitSelData" :key="item.id" :label="item.name" @click.native="flush()" :value="item.id"></el-option>
              </el-select>
            </li>
            <li>
              <span class="title">采购指定单位:</span>
              <el-select class="input_unit" v-model="moreUnit.purchaseAssignUnit" size="mini" placeholder="单位">
                <el-option v-for="item in moreUnitSelData" :key="item.id" :label="item.name"  @click.native="flush()" :value="item.id"></el-option>
              </el-select>
            </li>
            <li>
              <span class="title">bom指定单位:</span>
              <el-select class="input_unit" v-model="moreUnit.bomAssignUnit" size="mini" placeholder="单位">
                <el-option v-for="item in moreUnitSelData" :key="item.id" :label="item.name"  @click.native="flush()" :value="item.id"></el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>

      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleMoreUnit">取 消</el-button>
        <el-button size="small" type="primary" @click="saveMoreUnit">确 定</el-button>
      </span>
    </el-dialog>


    <!--  货品分类选择组件 -->
    <producttype :selProductTypeId="selProductTypeId" @SelectedData="getSelectClassfiy" v-if="show_productTypebox"></producttype>
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'; //数据字典相关方法

  //导入货品分类选择公共组件
  import producttype from '@/components/commComponent/tree/tree_productType'; //货品分类组件

  export default {
    name: "product_unit",
    data() {

      return {
        moreUnitList:[],//多计量列表数据
        oldName:'',//旧名称
        moreUnit: { //多计量设置数据
          id:'',
          name: '', //计量名称
          product_type: '', //货品分类名称
          product_typeId: '', //货品分类id
          is_enable: true, //是否启用
          is_system:0,//是否为系统默认0/1
          remark: '', //说明/备注
          saleAssignUnit: '', //销售指定单位
          purchaseAssignUnit: '', //采购指定单位
          bomAssignUnit: '', //bom指定单位
          basicDesignatedUnit:'',//基础指定单位
        },
        moreUnitData: [{
          id:'',
          firstCount: 0, //基础数量
          firstUnit: '', //基础单位
          finalUnit: '', //结束单位
        }], //多计量表格数据
        moreUnitSelData:[],//多计量当前已经选择的计量单位

        sourceData: {},//定义编辑源数据
        sourceDataval:[],//定义修改计量单位源数据
        firstid:'',//第一条id
        // 控制开关
        show_addMoreMeasure: false, //控制显示多计量设置弹框
        show_productTypebox:false,//控制是否显示

        //校验信息
         rules:{
                name:[
                 { required: true, message: '请输入多计量名', trigger: 'blur' },
                 {validator:(rule, value, callback) => {
            if (value) {
               if (value.trim().length >100) {
                callback(new Error('字符长度不可超过100'))
              } else {
                callback()
              }
            } else if (rule.required) {
              //如果没值又是必填
              callback(new Error(rule.msg))
            } else {
              // this.$refs['test'].resetFields(); //移除表单验证规则
              callback()
            }
          },trigger: 'blur'}
                 ],
                product_type:[
                 { required: true, message: '请选择货品分类', trigger: 'blur' },
                 {validator:(rule, value, callback) => {
                   if (value) {
                      if (value.trim().length >100) {
                       callback(new Error('字符长度不可超过100'))
                     } else {
                       callback()
                     }
                   } else if (rule.required) {
                     //如果没值又是必填
                     callback(new Error(rule.msg))
                   } else {
                     // this.$refs['test'].resetFields(); //移除表单验证规则
                     callback()
                   }
                 },trigger: 'change'},
                 ]
                 // ,
                 // remark:[
                 //   { required: true, message: '请填写说明', trigger: 'blur' },
                 //   {validator:(rule, value, callback) => {
                 //     if (value) {
                 //        if (value.trim().length >100) {
                 //         callback(new Error('字符长度不可超过100'))
                 //       } else {
                 //         callback()
                 //       }
                 //     } else if (rule.required) {
                 //       //如果没值又是必填
                 //       callback(new Error(rule.msg))
                 //     } else {
                 //       // this.$refs['test'].resetFields(); //移除表单验证规则
                 //       callback()
                 //     }
                 //   },trigger: 'blur'}
                 // ]
            },

        //判断条件
        operateMore: 0, //判断是新增多计量还是修改多计量

        // 子组件相关
        selProductTypeId:[],//货品分类选中的数据

        //loading加载
        loading: '',
      }
    },
    created() {
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#moreUnitTable>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProductUnit: state => state.system.ProductUnit, //计量单位属性
        ProductTabIndex: state => state.system.ProductTabIndex, //鼠标点击下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //监听货品相关数据字典选项卡下标
      ProductTabIndex(newVal) {
        //判断下标是否为计量单位
        if (newVal == "6.7") {
          //判断vuex是否存在数据
          this.juejeUnit();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCT_UNIT', //存放计量单位数据到vuex
        'SHOW_PRODUCTTYPEBOX', //控制货品分类弹框显示
      ]),
      ...mapActions([
        'getProductUnit', //计量单位
      ]),
      /* 判断vuex是否有多计量数据 */
      juejeUnit() {
        if (this.ProductUnit.length == 0) {
          this.getProductUnit();
        }

        //获取列表数据
        this.getProductUnitName();
      },
     getMorefirstUnitId(item){
       this.moreUnitSelData=[]
       this.moreUnitSelData.splice(0,1,item)
       this.moreUnitData[0].firstUnit=item.id
       this.moreUnitData[0].finalUnit=''
       this.moreUnit.bomAssignUnit=''
       this.moreUnit.purchaseAssignUnit=''
       this.moreUnit.saleAssignUnit=''

     },
      /* ********************************多计量相关******************************** */
      getProductUnitName(){
         api.productDict.findAllProductUnitName().then(res=>{
           console.log(res)
           if(res.code==200){
             this.moreUnitList=res.data
           }else{
             this.$message({
               type: 'error',
               message: res.message,
               duration: 1500
             });
           }
         })
      },
      /* 显示新增多计量弹框 */
      addMoreMeasure() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }


        //显示弹框
        this.show_addMoreMeasure = true;
        this.operateMore=0;
        this.$nextTick(()=>{
          //初始化数据
          this.initialization()
          this.$refs['test'].resetFields()//重置校验规则
        })
      },
      /*刷新页面*/
      flush(){
        this.$forceUpdate();
      },
      /* 关闭多计量设置弹框 */
      cancleMoreUnit() {
        //关闭弹框
        this.show_addMoreMeasure = false;
      },

      /* 显示货品分类弹框 */
      show_productType() {
        this.show_productTypebox=true;

        this.SHOW_PRODUCTTYPEBOX(true);

        this.selProductTypeId = this.moreUnit.product_typeId.split(",");
        // setTimeout(function(){
        //   producttype.methods.setCheckedKeysout();
        // },1000)

      },
      //初始化数据
      initialization(){
         this.moreUnit= { //多计量设置数据
          id:'',
          name: '', //计量名称
          product_type: '', //货品分类名称
          product_typeId: '', //货品分类id
          is_enable: true, //是否启用
          is_system:0,//是否为系统默认0/1
          remark: '', //说明/备注
          saleAssignUnit: '', //销售指定单位
          purchaseAssignUnit: '', //采购指定单位
          bomAssignUnit: '', //bom指定单位
          basicDesignatedUnit:''
        },
        this.moreUnitData= [{
          id:'',
          firstCount: 0, //基础数量
          firstUnit: '', //基础单位
          finalUnit: '' //结束单位
        }],
        this.moreUnitSelData=[]
      },
      /* 修改数据 */
      updateUnit(data) {
        let moreUnitDataval={}//数据回显
        let moreUnitDataList=[]//数据回显需要使用

        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
       this.oldName=data.name
       this.show_addMoreMeasure = true; //显示编辑框
       this.operateMore = 1; //设置当前状态为修改
       this.moreUnit={
         id:data.id,
         name:data.name,
         product_typeId:data.productTypeId,
         product_type:data.productTypeName,
         is_enable:data.isEnable==1?true:false,
         remark:data.remark,
       }
       this.sourceDataval = JSON.parse(JSON.stringify(data.sysProductUnitVals)) //将源数据储存
       this.moreUnitSelData = [];
       data.sysProductUnitVals.forEach((item,index)=>{
         if(index==0){
           this.firstid=item.id
           if(item.isPurchaseDefault==1){
             this.moreUnit.purchaseAssignUnit=item.finalCalculateId
           }
           if(item.isOrderDefault==1){
             this.moreUnit.saleAssignUnit=item.finalCalculateId
           }
           if(item.isBomDefault==1){
             this.moreUnit.bomAssignUnit=item.finalCalculateId
           }
           if(item.isBase==1){
             this.moreUnit.basicDesignatedUnit=item.finalCalculateId
           }
         }
         else{
           moreUnitDataval={
             id:item.id,
             firstCount: item.baseNum, //基础数量
             firstUnit: item.firstCalculateId, //基础单位
             finalUnit: item.finalCalculateId, //结束单位
           }
           if(item.isPurchaseDefault==1){
             this.moreUnit.purchaseAssignUnit=moreUnitDataval.finalUnit
           }
           if(item.isOrderDefault==1){
             this.moreUnit.saleAssignUnit=moreUnitDataval.finalUnit
           }
           if(item.isBomDefault==1){
             this.moreUnit.bomAssignUnit=moreUnitDataval.finalUnit
           }
           if(item.isBase==1){
             this.moreUnit.basicDesignatedUnit=moreUnitDataval.firstUnit
           }
           moreUnitDataList.push(moreUnitDataval)
         }
        this.ProductUnit.forEach((itemI,indexI)=>{
          if(itemI.id == item.finalCalculateId){
            this.moreUnitSelData.push(itemI);
          }
        })

       })
       this.sourceData = JSON.parse(JSON.stringify(this.moreUnit)) //将源数据储存
       this.moreUnitData=moreUnitDataList

      },
      /*删除数据*/
      deleteProductUnit(data){
        this.$confirm('您确定要删除此信息吗?删除此信息也会将其关联数据一并删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
              this.loading = this.commonJsExtend.customLoading("#specificationTable", 2);
              api.productDict.deleteProductUnitName({
                id:data.id,
                name:data.name
              }).then(res=>{
                 if (res.code == 200) {
                   this.loading.close()
                  this.getProductUnitName()
                  this.$message({
                    type: 'success',
                    message: "数据删除成功",
                    duration: 1500
                  })
                 } else {
                   this.loading.close()
                   this.$message({
                     type: 'error',
                     message: res.message,
                     duration: 1500
                   })
                 }
              })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        })

      },
      /* 获取多计量最 */
      getMoreFinalUnitId(indexI,item){
        if(this.moreUnitData[0].firstUnit==item.id){
          this.$message({
            type: 'warning',
            message: '请勿设置和基础指定单位相同的值!',
            duration: this.elDuration
          })
          //清空当前选择
          this.moreUnitSelData.splice(indexI+1,1,"")
          this.moreUnitData[indexI].finalUnit = '';
        }else{
          console.log(item)
          if(this.moreUnitSelData.indexOf(item)!= -1){
            this.$message({
              type: 'warning',
              message: '请勿设置重复计量单位!',
              duration: this.elDuration
            })
            //清空当前选择
             this.moreUnitSelData.splice(indexI+1,1,"")
            this.moreUnitData[indexI].finalUnit = '';
          }else{
            this.moreUnitSelData.splice(indexI+1,1,item)
            if(indexI+1<=this.moreUnitData.length-1){
              this.moreUnitData[indexI+1].firstUnit=this.moreUnitData[indexI].finalUnit
            }

          }
        }
        this.moreUnit.bomAssignUnit='',
        this.moreUnit.purchaseAssignUnit='',
        this.moreUnit.saleAssignUnit=''
      },

      /* 获取选择的分类 */
      getSelectClassfiy(data) {
        if (data && data.length > 0) {
          let nameArr = []; //名称数组
          let idArr = []; //id数组
          //获取分类类数据
          data.forEach((item, index) => {
            nameArr.push(item.name);
            idArr.push(item.id);

          })

          //赋值到数据
          this.moreUnit.product_type = nameArr.join(",");
          this.moreUnit.product_typeId = idArr.join(",");

          this.show_productTypebox = false;
        }
      },

      /* 新增多计量设置 */
      addMoreUnit(index) {
        // 获取最后一个计量单位
        let finalUnit = this.moreUnitData[this.moreUnitData.length - 1].finalUnit;

        //判断当前是否设置计量
        if (!finalUnit) {
          this.$message({
            type: "warning",
            message: "请先设置最后一个计量单位",
            duration: this.elDuration
          });
          return
        }

        //定义新增加的数据
        let addUnitObj = {
          firstCount: 0,
          firstUnit: finalUnit,
          finalUnit: '',
        }

        //添加到数组
        this.moreUnitData.push(addUnitObj);
      },

      /* 减少多计量设置 */
      removeMoreUnit(index) {
        //判断只能从最后一个开始删除
        if (index != (this.moreUnitData.length - 1)) {
          this.$message({
            type: "warning",
            message: "删除时请从最后一条开始删除!",
            duration: this.elDuration
          });
          return
        }

        //删除数据
        this.moreUnitData.splice(index, 1);
        this.moreUnitSelData.splice(index+1, 1);
        this.moreUnit.bomAssignUnit='',
        this.moreUnit.purchaseAssignUnit='',
        this.moreUnit.saleAssignUnit=''
      },

      /* 添加或修改多计量设置数据 */
      saveMoreUnit() {
        console.log(this.moreUnit.basicDesignatedUnit)
        let judge=true
        this.$refs['test'].validate(valid =>{
          if(valid){
              if(!!!this.moreUnit.basicDesignatedUnit){
                this.$message({
                  type: 'error',
                  message: '请填写基础单位指定!',
                  duration: 1500
                })
              }else{
                this.moreUnitData.forEach(item=>{
                  if(!!!item.firstCount||!!!item.firstUnit||!!!item.finalUnit){
                    judge=false
                  }
                })
                if(!judge){
                  this.$message({
                    type: 'error',
                    message: '请将计量设置的值填充完整!',
                    duration: 1500
                  })
                }else{
                  if(!!!this.moreUnit.bomAssignUnit||!!!this.moreUnit.purchaseAssignUnit||!!!this.moreUnit.saleAssignUnit){
                    this.$message({
                      type: 'error',
                      message: '销售,采购,bom指定单位填写完整!',
                      duration: 1500
                    })
                  }else{
                    let sysProductUnitValStr = [];
                    if(this.operateMore==0){//新增
                    let itemObj={
                      baseNum:1,
                      firstCalculateId:0,
                      finalCalculateId:this.moreUnit.basicDesignatedUnit,
                      isOrderDefault:this.moreUnit.basicDesignatedUnit==this.moreUnit.saleAssignUnit?1:0,
                      isPurchaseDefault:this.moreUnit.basicDesignatedUnit==this.moreUnit.purchaseAssignUnit?1:0,
                      isBomDefault:this.moreUnit.basicDesignatedUnit==this.moreUnit.bomAssignUnit?1:0,
                      isBase:1
                    }

                    sysProductUnitValStr.push(itemObj);
                      this.moreUnitData.forEach((item,index)=>{
                        let itemObj = {
                          baseNum:item.firstCount,
                          firstCalculateId:item.firstUnit,
                          finalCalculateId:item.finalUnit,
                          isOrderDefault:item.finalUnit==this.moreUnit.saleAssignUnit?1:0,
                          isPurchaseDefault:item.finalUnit==this.moreUnit.purchaseAssignUnit?1:0,
                          isBomDefault:item.finalUnit==this.moreUnit.bomAssignUnit?1:0,
                          isBase:0
                        }

                        sysProductUnitValStr.push(itemObj);
                      })
                      let data={
                        name:this.moreUnit.name,
                        isEnable:this.moreUnit.is_enable?1:0,
                        productTypeId:this.moreUnit.product_typeId,
                        remark:this.moreUnit.remark,
                        isSystem:this.moreUnit.is_system,
                        sysProductUnitValStr:JSON.stringify(sysProductUnitValStr)
                      }
                      //加载loading
                      this.loading = this.commonJsExtend.customLoading("#unitTable", 3);
                      api.productDict.addProductUnitName(data).then((res)=>{
                        this.loading.close() //关闭loading
                        if (res.code == 200) {
                          this.getProductUnit(1) //获取新数组
                          this.$message({
                            type: 'success',
                            message: '数据添加成功!',
                            duration: 1500
                          })
                          //获取列表数据
                          this.getProductUnitName();
                        } else {
                          this.$message({
                            type: 'error',
                            message: '数据添加失败!',
                            duration: 1500
                          })
                        }
                        this.show_addUNitBox = false; //关闭弹窗
                      })
                    }
                    else if(this.operateMore==1){//修改
                    let itemObj={
                      id:this.firstid,
                      baseNum:1,
                      firstCalculateId:0,
                      finalCalculateId:this.moreUnit.basicDesignatedUnit,
                      isOrderDefault:this.moreUnit.basicDesignatedUnit==this.moreUnit.saleAssignUnit?1:0,
                      isPurchaseDefault:this.moreUnit.basicDesignatedUnit==this.moreUnit.purchaseAssignUnit?1:0,
                      isBomDefault:this.moreUnit.basicDesignatedUnit==this.moreUnit.bomAssignUnit?1:0,
                      isBase:1
                    }

                    sysProductUnitValStr.push(itemObj);
                      this.moreUnitData.forEach((item,index)=>{
                        let itemObj = {
                          id:item.id,
                          baseNum:item.firstCount,
                          firstCalculateId:item.firstUnit,
                          finalCalculateId:item.finalUnit,
                          isOrderDefault:item.finalUnit==this.moreUnit.saleAssignUnit?1:0,
                          isPurchaseDefault:item.finalUnit==this.moreUnit.purchaseAssignUnit?1:0,
                          isBomDefault:item.finalUnit==this.moreUnit.bomAssignUnit?1:0,
                          isBase:index==0?1:0
                        }
                        sysProductUnitValStr.push(itemObj);
                      })
                      this.sourceData.is_enable=this.sourceData.is_enable?1:0
                      let logContent = '';//日志
                      //计量设置日志拼接
                      if (JSON.stringify(this.sourceDataval) != JSON.stringify(sysProductUnitValStr)) { //当规格值进行编辑操作之后
                        let sourceSpeDetailsLog = '更改了【多计量】中的【计量设置】,更改前计量设置值数据:{[' //定义更改前规格值数据字符串变量
                        this.sourceDataval.forEach((item, index) => { //循环规格值源数据数组
                          if (index == this.sourceDataval.length - 1) { //当为数组最后一个元素时
                            sourceSpeDetailsLog += '计量数量:' + item['baseNum'] + ',开始计量单位:' + (this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name==undefined?'无':this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name) +
                            ',结束计量单位:'+this.commonJsExtend.getDictMatchingData(item['finalCalculateId'],this.ProductUnit).name+']' //日志拼接
                          } else { //当不为数组最后一个元素时
                            sourceSpeDetailsLog += '计量数量:' + item['baseNum'] + ',开始计量单位:' + (this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name==undefined?'无':this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name) +
                            ',结束计量单位:'+this.commonJsExtend.getDictMatchingData(item['finalCalculateId'],this.ProductUnit).name +
                              '],[' //日志拼接
                          }
                        })
                        let afterEditLog = '更改后规格值数据:{[' //定义更改后规格值数据字符串变量
                        sysProductUnitValStr.forEach((item, index) => { //循环规格值编辑后数据数组
                          if (index == sysProductUnitValStr.length - 1) { //当为数组最后一个元素时
                            afterEditLog += '计量数量:' + item['baseNum'] + ',开始计量单位:' + (this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name==undefined?'无':this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name) +
                            ',结束计量单位:'+this.commonJsExtend.getDictMatchingData(item['finalCalculateId'],this.ProductUnit).name+']' //日志拼接
                          } else { //当不为数组最后一个元素时
                            afterEditLog += '计量数量:' + item['baseNum'] + ',开始计量单位:' + (this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name==undefined?'无':this.commonJsExtend.getDictMatchingData(item['firstCalculateId'],this.ProductUnit).name) +
                            ',结束计量单位:'+this.commonJsExtend.getDictMatchingData(item['finalCalculateId'],this.ProductUnit).name +
                              '],[' //日志拼接
                          }
                        })
                        logContent += sourceSpeDetailsLog + '};' + afterEditLog + '}' //日志拼接
                      }
                      if(JSON.stringify(this.sourceData) != JSON.stringify(this.moreUnit)){
                        let sourceDatalog='更改了【多计量】中的【基础信息】,更改前基础数据:{['
                          sourceDatalog += '多计量名:' + this.sourceData.name + ',货品分类:' + this.sourceData.product_type +
                          ',状态:'+(this.sourceData.is_enable==1?'启用':'不启用')+ ',说明:'+this.sourceData.remark+
                          ',销售指定单位:'+this.commonJsExtend.getDictMatchingData(this.sourceData.saleAssignUnit,this.ProductUnit).name
                          +',采购指定单位:'+this.commonJsExtend.getDictMatchingData(this.sourceData.purchaseAssignUnit,this.ProductUnit).name
                          +',bom指定单位:'+this.commonJsExtend.getDictMatchingData(this.sourceData.bomAssignUnit,this.ProductUnit).name+']' //日志拼接
                        let afterDataEditLog = '更改后规格值数据:{[' //定义更改后规格值数据字符串变量
                          afterDataEditLog += '多计量名:' + this.moreUnit.name + '货品分类:' + this.moreUnit.product_type +
                          ',状态:'+(this.moreUnit.is_enable?'启用':'不启用')+',说明:'+this.moreUnit.remark+
                          ',销售指定单位:'+this.commonJsExtend.getDictMatchingData(this.moreUnit.saleAssignUnit,this.ProductUnit).name
                          +',采购指定单位:'+this.commonJsExtend.getDictMatchingData(this.moreUnit.purchaseAssignUnit,this.ProductUnit).name
                          +',bom指定单位:'+this.commonJsExtend.getDictMatchingData(this.moreUnit.bomAssignUnit,this.ProductUnit).name+']' //日志拼接
                        logContent += sourceDatalog + '};' + afterDataEditLog + '}' //日志拼接
                      }
                      console.log(logContent)
                      let data={
                        id:this.moreUnit.id,
                        name:this.moreUnit.name,
                        isEnable:this.moreUnit.is_enable?1:0,
                        productTypeId:this.moreUnit.product_typeId,
                        remark:this.moreUnit.remark,
                        sysProductUnitValStr:JSON.stringify(sysProductUnitValStr),
                        logContent:logContent
                      }
                      //加载loading
                      this.loading = this.commonJsExtend.customLoading("#unitTable", 3);
                     api.productDict.updateProductUnitName(data).then((res)=>{
                       this.loading.close() //关闭loading
                       if (res.code == 200) {
                         this.getProductUnit(1) //获取新数组
                         this.$message({
                           type: 'success',
                           message: '数据修改成功!',
                           duration: 1500
                         })
                         //获取列表数据
                         this.getProductUnitName();
                       } else {
                         this.$message({
                           type: 'error',
                           message: '数据修改失败!',
                           duration: 1500
                         })
                       }
                       this.show_addUNitBox = false; //关闭弹窗
                     })
                    }
                    //关闭弹框
                    this.show_addMoreMeasure = false;
                  }
                }
              }
          }
        })

      },
      /*根据id查出名称*/
      findById(data){
        this.commonJsExtend.getDictMatchingDataByName
        this.moreUnitSelData.forEach(item=>{
          if(item.id==data){
            return item.name
          }
        })
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.7" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.ProductUnit[newVal.oldIndex].id,
            targetId: this.ProductUnit[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#moreUnitTable", 1);
          // api.productDict.updateSysProductUnitSort(data).then(res => {
          //   if (res.code == 200) {
          //     this.loading.close();
          //     this.getProductUnit(4); //获取新数组
          //   } else {
          //     this.$message({
          //       type: "error",
          //       message: res.message,
          //       duration: this.elDuration
          //     })
          //     this.loading.close();
          //   }
          // })
        }
      },
    },
    components: {
      producttype
    }
  }
</script>

<style lang="less">
  .input_unit_base{
    width: 100%;
    border: none;
  }

  /* 多计量设置框 */
  .moreUnitBox {
    height: 220px;
    overflow: auto;
    /* 单个计量*/
    .itemUnit {
      display: flex;
      align-items: center;
      padding: 10px 0;
      // border: 1px solid black;

      // 标题
      .title {
        display: block;
        width: 89px;
        padding-right: 21px;
        font-size: 14px;
        text-align: right;
        font-weight: bold;
        // border: 1px solid black;
      }

      //数量框
      .input_num {
        width: 90px;
        margin-right: 10px;
      }

      //单位框
      .input_unit {
        width: 90px;
        margin-right: 10px;
      }

      //分隔符
      .input_split {
        padding: 0 11.5px;
      }
    }

    /* 指定计量单位设置 */
    .assignUnit {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 50%;
          padding: 5px 0;
          display: flex;
          // border: 1px solid black;

          // 标题
          .title {
            display: block;
            width: 100px;
            padding-right: 10px;
            font-size: 14px;
            font-weight: bold;
            // border: 1px solid black;
          }

          // 值
          .input_unit {
            width: 90px;
          }
        }
      }
    }
  }
</style>
