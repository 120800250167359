<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关货品分类请求接口
开始时间：2021-03-13
开发人员：万攀
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="classfiy_tabs">
    <!-- 左边分类 -->
    <div class="left_classfiy scollStyle">
      <!-- 搜索框 -->
      <div class="search">
        <el-input placeholder="搜索分类" v-model="searchData" size="small">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <!-- 分类 -->
      <div class="class_fiy">
        <!-- 全部分类 -->
        <span class="classfiy_icon">
          <i class="el-icon-menu classifyAll" @click="getClassifyData(ProductClassfiy)">全部分类</i>
          <el-button type="text" class="P_addbutton">
            <i class="el-icon-plus" @click="showAddDialog(1)"></i>
          </el-button>
        </span>
        <!-- 树形分类 -->
        <el-tree :data="ProductClassfiy" :props="defaultProps" :expand-on-click-node="false" default-expand-all
          @current-change="getClassifyData" highlight-current>
          <span class="custom-tree-node" slot-scope="{node,ProductClassfiy}">
            <div class="classfiy_name">{{ node.label }}</div>
            <div>
              <el-button type="text" size="mini">
                <i class="el-icon-plus" @click="showAddDialog(0,node.data)" v-show="node.level!=3"></i>
              </el-button>
            </div>
          </span>
        </el-tree>
      </div>
    </div>
    <!-- 右边内容 -->
    <div class="right_product">
      <!-- <div class="top_checkbox">
        <el-checkbox v-model="checkboxStatus1" @click.native="showParentClassfiy($event)">显示主分类</el-checkbox>
        <el-checkbox v-model="checkboxStatus2" @click.native="showChildrenClassfiy($event)">显示所有子分类</el-checkbox>
      </div> -->
      <div class="classfiy_table billing_table">
        <el-table :data="tableData" height="100%" border style="width: 100%" id="product_type">
          <!-- 拖拽图标 -->
          <el-table-column width="50">
            <template slot-scope="scope">
              <i class="el-icon-s-grid my-handle"></i>
            </template>
          </el-table-column>
          <!-- 索引 -->
          <el-table-column type="index" width="60"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <i class="el-icon-delete icon" @click="deleteProdoctClassfiy(scope.row)" title="删除"></i>
              <i class="el-icon-edit-outline icon" @click="showUpdateDialog(scope.row)" title="修改"></i>
            </template>
          </el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="name" label="分类名称" sortable width="180"></el-table-column>
          <el-table-column label="允许客户下单" width="80">
            <template slot-scope="scope">
              <el-checkbox :value="scope.row.is_self_order == 1" disabled></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="不参与库存" width="80">
            <template slot-scope="scope">
              <el-checkbox :value="scope.row.is_depot == 1" disabled></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="不参与成本核算" width="120">
            <template slot-scope="scope">
              <el-checkbox :value="scope.row.is_cost_check == 1" disabled></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="BOM合成物料" width="120">
            <template slot-scope="scope">
              <el-checkbox :value="scope.row.is_bom_merge == 1" disabled></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <el-checkbox :value="scope.row.is_enable == 1" disabled></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="说明" min-width="12%"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 新增或编辑货品分类数据 -->
    <el-dialog :title="operateState==0?'新增分类':'编辑分类'" :visible.sync="show_addProductTypeBox" width="400px" append-to-body>
      <div class="dictionaryAddBox">
        <el-form :model="productTypeEidt" :rules="productTypeRule" ref="productType_ruleform" label-width="120px"
          class="demo-ruleForm" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="编码:">
                <el-input v-model.trim="productTypeEidt.encoding" placeholder="请输入编码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="分类名称:" prop="name">
                <el-input v-model.trim="productTypeEidt.name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="允许客户下单:">
                <el-checkbox v-model="productTypeEidt.is_self_order"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="不参与库存:">
                <el-checkbox v-model="productTypeEidt.is_depot"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="不参与成本核算:">
                <el-checkbox v-model="productTypeEidt.is_cost_check"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="BOM合成物料:">
                <el-checkbox v-model="productTypeEidt.is_bom_merge"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="productTypeEidt.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="productTypeEidt.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_addProductTypeBox = false">取 消</el-button>
        <el-button type="primary" @click="saveProductTypeData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  import api from '@/network/system/dictionary';
  export default {
    name: "product_type",
    data() {
      return {
        //左边货品分类树状图相关
        searchData: '', //搜索框数据
        //树形列表配置
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        //定义编辑源数据
        sourceData: {},

        //右边货品分类列表相关
        tableData: [], //表格数据
        productTypeEidt: {}, //当前正在编辑的数据
        productTypeRule: {}, //表单验证规则

        //加载loading框
        loading: '',

        //辅助数据
        operateState: 0, //操作类型(0:新增 1:修改)

        //控制开关
        show_addProductTypeBox: false, //控制显示编辑弹框
      }
    },
    computed: {
      ...mapState({
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        ProductTabIndex: state => state.system.ProductTabIndex, //客户数据字典选中下标
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        SortIndex: state => state.system.SortIndex, //排序下标
      })
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
      //判断vuex是否存在数据
      this.judjeProductType();
    },
    mounted() {
      //开启拖拽
      this.commonJsExtend.customDragrow("#product_type>.el-table__body-wrapper > table > tbody");
    },
    watch: {
      //监听货品相关数据字典选项卡下标
      ProductTabIndex(newVal) {
        //判断下标是否为品牌
        if (newVal == "6.1") {
          //判断vuex是否存在数据
          this.judjeProductType();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCT_CLASSFIY', //货品分类
        'SORTINDEX' //表格排序数据
      ]),
      ...mapActions([
        'getProductClassfiy', //获取货品分类数据
      ]),

      // 判断vuex中是否含有数据
      async judjeProductType() {
        // vuex中没有数据,从服务器请求
        if (this.ProductClassfiy.length == 0) {
          await this.getProductClassfiy();
        }
        //默认获取一级分类
        this.getClassifyData(this.ProductClassfiy);
      },

      /* 初始化表单验证规则 */
      initRules() {
        this.productTypeRule = {
          name: [ //货品名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 获取表格数据 */
      getClassifyData(data) {
        //获取前先清空
        this.tableData = [];
        //判断是一级还是子级
        if (!!data.length) {
          this.tableData = data;
        } else {
          if (data.hasOwnProperty('children') && !!data.children) {
            this.tableData = data.children;
          }
        }
      },

      /* 获取货品分类数据 */
      getProductTypeData(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#product_type", 3);
        //发送请求
        api.productDict.getProductClassfiy().then(res => {
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.PRODUCT_CLASSFIY(res.data);
            this.getClassifyData(res.data)
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2:修改   3:删除  4:排序)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '排序修改成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭弹框
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示新增分类弹框 */
      showAddDialog(type, data) {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        // 获取新增默认数据
        this.productTypeEidt = {
          encoding:'',
          name: '', //名称
          is_enable: true, //状态默认启用
          is_self_order:false,//允许客户下单
          is_depot:false,//不参与库存
          is_cost_check:false,//不参与成本核算
          is_bom_merge:false,//BOM合成物料
          remark: '' //备注
        }
        // 判断是新增一级还是子级
        if (type == 1) { //一级
          this.productTypeEidt.parentId = -1;
        } else if (type == 0) { //子级
          this.productTypeEidt.parentId = data.id;
        }
        //显示弹框
        this.show_addProductTypeBox = true;
        //设置操作类型
        this.operateState = 0;
      },

      /* 显示修改分类弹框 */
      showUpdateDialog(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        // 获取新增默认数据
        this.productTypeEidt = {
          id: data.id,
          encoding:data.encoding,
          name: data.name, //新名称
          oldname: data.name, //旧名称
          parentId: data.parent_id, //父级id
          is_self_order:data.is_self_order == 1 ? true : false, //允许客户下单
          is_depot:data.is_depot == 1 ? true : false, //不参与库存核算
          is_cost_check:data.is_cost_check == 1 ? true : false, //不参与成本核算
          is_bom_merge:data.is_bom_merge == 1 ? true : false, //BOM合成物料
          is_enable: data.is_enable == 1 ? true : false, //状态
          remark: data.desciption //简述
        }
        this.sourceData = JSON.parse(JSON.stringify(this.productTypeEidt)) //将源数据储存
        //显示弹框
        this.show_addProductTypeBox = true;
        //设置操作类型
        this.operateState = 1;
      },

      /* 保存货品分类数据 */
      saveProductTypeData() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['productType_ruleform'].validate(valid => {
          if (valid) {
            //关闭弹框
            this.show_addProductTypeBox = false;
            // 判断新增还是修改
            if (this.operateState == 0) { //新增
              //定义传入后端的数据
              let data = {
                name: this.productTypeEidt.name, //名称
                encoding:this.productTypeEidt.encoding,
                is_self_order: this.productTypeEidt.is_self_order ? 1 : 0, //允许客户下单
                is_depot: this.productTypeEidt.is_depot ? 1 : 0, //不参与库存
                is_cost_check: this.productTypeEidt.is_cost_check ? 1 : 0, //不参与成本核算
                is_bom_merge: this.productTypeEidt.is_bom_merge ? 1 : 0, //BOM合成物料
                is_enable: this.productTypeEidt.is_enable ? 1 : 0, //是否启用
                parent_id: this.productTypeEidt.parentId, //父级分类id
                desciption: this.productTypeEidt.remark //简述
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#product_type", 0);
              //发送添加数据请求
              api.productDict.addProductClassfiy(data).then(res => {
                this.loading.close();
                if (res.code == 200) {
                  this.getProductTypeData(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else { //修改
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.productTypeEidt, '货品分类');
              //定义传入后端的数据
              let data = {
                id: this.productTypeEidt.id, //分类id
                encoding:this.productTypeEidt.encoding,
                name: this.productTypeEidt.oldname, //旧名称
                new_name: this.productTypeEidt.name, //新名称
                parent_id: this.productTypeEidt.parentId, //父级分类id
                is_self_order: this.productTypeEidt.is_self_order ? 1 : 0, //允许客户下单
                is_depot: this.productTypeEidt.is_depot ? 1 : 0, //不参与库存
                is_cost_check: this.productTypeEidt.is_cost_check ? 1 : 0, //不参与成本核算
                is_bom_merge: this.productTypeEidt.is_bom_merge ? 1 : 0, //BOM合成物料
                is_enable: this.productTypeEidt.is_enable ? 1 : 0, //是否启用
                desciption: this.productTypeEidt.remark, //备注
                logContent//日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#product_type", 1);
              //发送添加数据请求
              api.productDict.updateProductClassfiy(data).then(res => {
                this.loading.close();
                if (res.code == 200) {
                  this.getProductTypeData(2); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          }
        })
      },

      /* 删除货品分类数据 */
      deleteProdoctClassfiy(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#product_type", 2);
          let delData = {
            productid: data.id, //货品分类id
            name: data.name //货品分类名称
          }
          //发送删除请求
          api.productDict.deleteProductClassfiy(delData).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.getProductTypeData(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.1" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.tableData[newVal.oldIndex].id,
            targetId: this.tableData[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#product_type", 1);
          //请求接口
          api.productDict.updateProClassifySort(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.getProductTypeData(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .classfiy_tabs {
    height: 85vh;
    display: flex;

    // border: solid 1px red;
    /* 左边分类 */
    .left_classfiy {
      height: 100%;
      min-width: 220px;
      overflow: auto;
      padding-right: 7px;
      border-right: 2px #eeeeee solid;

      // border: 1px solid black;
      //搜索框
      .search {
        .el-input__icon {
          cursor: pointer;
        }
      }

      .class_fiy {
        height: 81vh;
        // border: 1px solid black;

        .classfiy_icon {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-size: 14px;

          // border: 1px solid black;
          //全部分类
          .classifyAll {
            padding: 0 10px;
            // border: 1px solid black;
          }
        }

        .custom-tree-node {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          button {
            display: none;
          }

          // border: 1px solid red;
          .classfiy_name {
            line-height: 28px;
          }
        }

        .custom-tree-node:hover {
          button {
            font-size: 16px;
            display: block;
          }
        }
      }
    }

    /* 右边内容 */
    .right_product {
      height: 84vh;
      margin-left: 5px;
      flex: 2;

      // border: 1px solid black;
      .top_checkbox {
        margin-bottom: 10px;
        // border: 1px solid black;
      }

      .classfiy_table {
        height: 81vh;
        // border: red solid 1px;
      }
    }
  }
</style>
