<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：财务相关发票类型请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="add_CostInvoiceType" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="CostInvoiceType" row-key="id" height="100%" id="CostInvoiceTypeTable" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateCostInvoiceType(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteCostInvoiceType(scope.row.id)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1?true:false" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="系统默认" width="200">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system==1?true:false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>
    <!--修改增加弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '发票类型'" :visible.sync="show_saveCostInvoiceTypeBox"
      width="400px" :before-close="cancleCostInvoiceType" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="saveCost_Type" :rules="costType_rules" ref="costType_ruleform" label-width="80px" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="saveCost_Type.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="saveCost_Type.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="saveCost_Type.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleCostInvoiceType">取 消</el-button>
        <el-button size="small" type="primary" @click="save_orderType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: "financeDict_costType",
    data() {
      return {
        costType_rules: [], //表单验证规则
        saveCost_Type: { //修改保存临时值
          name: '',
          remark: '',
          is_enable: '',
          is_system: '',
          id: '',
          new_name: '',
        },
        old_name: '',
        //控制开关
        show_saveCostInvoiceTypeBox: false, //是否显示新增框
        //判断条件
        operateState: 0, //判断新增还是修改(0:新增 ， 1::修改)
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#CostInvoiceTypeTable>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        CostInvoiceType: state => state.system.CostInvoiceType, //发票类型数据
        FinanceTabIndex: state => state.system.FinanceTabIndex, //发票类型字典选中下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //监听财务相关数据字典选项卡下标
      FinanceTabIndex(newVal) {
        //判断下标是否为发票类型
        if (newVal == "3.5") {
          //判断vuex是否存在数据
          this.judjeCostInvoiceType();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'FINANCE_COSTINVOICETYPE', //存放发票类型到vuex
      ]),
      /* 取消新增或修改操作 */
      cancleCostInvoiceType() {
        this.$refs['costType_ruleform'].resetFields(); //移除表单验证规则
        this.show_saveCostInvoiceTypeBox = false;
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.costType_rules = {
          name: [ //发票类型名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },


      /* 打开新增弹窗*/
      add_CostInvoiceType() {
        this.operateState = 0;
        this.saveCost_Type.name = '';
        this.saveCost_Type.remark = '';
        this.saveCost_Type.is_enable = true;
        this.show_saveCostInvoiceTypeBox = true;
      },
      /* 打开修改窗口*/
      updateCostInvoiceType(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_saveCostInvoiceTypeBox = true;
        this.saveCost_Type.name = data.name;
        this.saveCost_Type.remark = data.remark;
        this.saveCost_Type.id = data.id;
        this.old_name = data.name;
        this.saveCost_Type.is_enable = data.is_enable == 1 ? true : false;
        this.saveCost_Type.is_system = data.is_system == 1 ? true : false;
        this.operateState = 1;

      },
      /* 判断vuex是否存在数据*/
      judjeCostInvoiceType() {
        // vuex中没有数据,从服务器请求
        if (this.CostInvoiceType.length == 0) {
          this.findAllCostInvoiceType();
        }
      },
      /* 查询发票类型*/
      findAllCostInvoiceType(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#CostInvoiceTypeTable", 3);
        // 发送请求
        api.financeDict.findAllCostInvoiceType().then(res => {
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.FINANCE_COSTINVOICETYPE(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_saveCostInvoiceTypeBox = false; //关闭新增输入框
          }
        });
      },
      /* 删除发票类型 */
      deleteCostInvoiceType(id) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#CostInvoiceTypeTable", 2);
          //发送删除请求
          api.financeDict.deleteCostInvoiceType({
            id
          }).then(res => {
            if (res.code == 200) {
              this.findAllCostInvoiceType(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
            this.loading.close(); //关闭加载框
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
          this.loading.close(); //关闭加载框
        });
      },
      /* 保存发票类型信息*/
      save_orderType() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['costType_ruleform'].validate(valid => {
          if (valid) {
            // 判断是新增还是修改
            if (this.operateState == 0) {
              let data = {
                name: this.saveCost_Type.name,
                remark: this.saveCost_Type.remark,
                is_enable: this.saveCost_Type.is_enable ? 1 : 0,
                is_system: '0',
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#CostInvoiceTypeTable", 0);
              //发送请求
              api.financeDict.addCostInvoiceType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllCostInvoiceType(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_saveCostInvoiceTypeBox = false;
              });
            } else {
              //修改数据
              var data = {
                name: this.old_name,
                remark: this.saveCost_Type.remark,
                is_enable: this.saveCost_Type.is_enable ? 1 : 0,
                is_system: '0',
                id: this.saveCost_Type.id,
                new_name: this.saveCost_Type.name,
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#CostInvoiceTypeTable", 1);
              //发送请求
              api.financeDict.updateCostInvoiceType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllCostInvoiceType(2); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_saveCostInvoiceTypeBox = false;
              });
            }
          }
        });
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.FinanceTabIndex == "3.5" && this.DictionaryLeftIndex == 3) {
          let data = {
            sourceId: this.CostInvoiceType[newVal.oldIndex].id,
            targetId: this.CostInvoiceType[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#CostInvoiceTypeTable", 1);
          api.financeDict.updateSysCostInvoiceTypeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllCostInvoiceType(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }

      }
    }
  }
</script>

<style>
</style>
