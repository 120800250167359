<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：系统相关请求接口
开始时间：2021-03-18
开发人员：刘巍骏,万攀
最后修改：2021-03-18
备注说明：用做数据字典前端模板参考 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addState">新增</el-button>
      <el-button size="small" type="primary" v-if="show_wechatWork" @click="synAllWechat">同步到企业微信</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="stateTable" row-key="id" :data="stateData" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" width="45"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateState(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteCustomerState(scope.row)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="200">
        </el-table-column>
        <!-- <el-table-column prop="color" label="颜色" width="120"></el-table-column> -->
        <!-- <el-table-column prop="zt" label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.zt" disabled>启用</el-checkbox>
          </template>
        </el-table-column> -->
        <el-table-column prop="zt" label="是否已同步企业微信" width="200">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_src_qiyeweixin==1?true:false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!--修改客户状态弹窗 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '客户状态'" :visible.sync="show_addBox" width="400px"
      :before-close="close" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="state_rules" ref="state_ruleform" :model="stateStr" size="mini" label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="stateStr.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="企业微信同步:">
                <el-checkbox v-model="sysChat"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="saveState">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';

  //导入方法
  import api from '@/network/system/dictionary'; //数据字典相关方法
  import controlApi from '@/network/system/controls.js';//控制权限相关方法
  import qywxApi from '@/network/thirdParty/qywxApi'; //企业微信相关接口

  export default {
    name: 'customer_state',
    data() {
      return {
        //当前页面数据
        stateData: [],
        state_rules: [], //表单验证
        stateStr: {
          name: '',
        },
        //定义编辑源数据
        sourceData: {},
        stateEidt: {},

        //控制开关
        show_addBox: false,//控制是否显示新增或修改弹框
        show_wechatWork: false,//控制是否显示同步到企业微信按钮

        //控制同步企业微信弹窗
        show_synWeChat: false,
        sysChat: true, //企业微信弹窗默认同步

        //判断条件
        operateState: 0,

        //loading加载
        loading: '',
      }
    },
    created() {
      this.initRules();
    },
    computed: {
      ...mapState({
        CustomerState: state => state.system.CustomerState,
        CustomerTabIndex: state => state.system.CustomerTabIndex, //客户数据字典选中下标
        SortIndex: state => state.system.SortIndex, //表格排序下标
      }),
    },
    watch: {
      //监听客户相关数据字典选项卡下标
      CustomerTabIndex(newVal) {
        //判断下标是否为客户状态
        if (newVal == "0.2") {
          //判断vuex是否存在数据
          this.getCustomerState();
        }
      },
    },
    mounted() {
      // 初始化表格拖拽
      this.commonJsExtend.customDragrow("#stateTable>.el-table__body-wrapper > table > tbody");
      //判断模块相应权限
      this.getPower();
    },
    methods: {
      ...mapMutations([
        'CUSTOMER_STATE', //获取客户状态
        'SORTINDEX' //表格排序数据
      ]),

      /* 获取相关权限 */
      getPower(){
        //获取企业微信模块权限
        this.getWechatWorkPower();
      },

      /* 判断企业微信模块是否开通 */
      getWechatWorkPower(){
        //定义后端请求参数
        let data = {
          functionCode:'enterprise_wechat',
        }
        //发送请求
        controlApi.findModelControlByModelCode(data).then(res=>{
          if(res.code == 200){
            //判断模块是否开通
            if (res.data == 0) { //开通
              this.show_wechatWork = true;
            } else { //未开通或已到期
              this.show_wechatWork = false;
            }
          }else{
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /*获取客户状态数据*/
      getCustomerState(type) {
        //加载loading
        this.loading = this.commonJsExtend.customLoading("#stateTable", 3);
        api.customerDict.getCustomerState().then(res => {
          if (res.code == 200) {
            this.CUSTOMER_STATE(res.data);
            this.stateData = res.data;
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            }else if (type == 4) {
              this.$message({
                type: 'success',
                message: '数据同步成功',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
          }
        });
      },

      /* 新增客户状态*/
      addState() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.stateStr = {
          name:''
        }
        //显示新增客户状态弹框
        this.show_addBox = true;
        //设置操作类型为新增
        this.operateState = 0;
        //默认勾选同步到企业微信
        this.sysChat = true;
      },

      /* 删除数据*/
      deleteCustomerState(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除后企业微信的标签也将同步删除!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#stateTable", 3);
          api.customerDict.deleteCustomerState({
            statusid: data.id,//客户状态id
            name:data.name//客户状态名称
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
               this.deleteWechat(data.wx_tag_id,1);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 修改数据 */
      updateState(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true; //显示编辑框
        this.stateStr.name = data.name; //将编辑信息赋值到编辑框
        this.sourceData = JSON.parse(JSON.stringify(this.stateStr)) //将源数据储存
        this.stateEidt = data; //存入当前编辑信息
        this.operateState = 1; //设置当前状态为修改
        this.sysChat = data.is_src_qiyeweixin == 1 ? true : false; //是否同步到企业微信
      },

      /* 保存数据*/
      saveState() {
        this.$refs['state_ruleform'].validate(valid => {
          if (valid) {
            //关闭弹框
            this.show_addBox = false;
            //判断是修改还是新增
            if (this.operateState == 0) {
              var data = {
                verifystr: this.stateStr.name //新增状态名称
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#stateTable", 3);
              //发送请求
              api.customerDict.addCustomerState(data).then((res) => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  // 判断是否同步到企业微信
                  if (this.sysChat) {
                    this.sysnAddToWeChat(res.data); //调用同步企业微信方法
                  }else{
                    this.getCustomerState(1) //获取新数组
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.operateState == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.stateStr, '客户状态');
              var data = {
                old_name: this.stateEidt.name,
                statusid: this.stateEidt.id,
                verifystr: this.stateStr.name,
                is_src_qiyeweixin: this.sysChat ? 1 : 0, //企业微信同步
                logContent//日志
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#stateTable", 1);
              api.customerDict.updateCustomerState(data).then(res => {
                this.loading.close(); //关闭loading
                if (res.code == 200) {
                  // 判断是否同步到企业微信
                  if (this.sysChat) {
                    this.sysnUpdateToWeChat(this.classfiyEidt.id);
                  }else{
                    // 从企业微信删除
                    this.deleteWechat(this.classfiyEidt.wx_tag_id,0);
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            }
          }
        })
      },

      /* 全部同步企业微信*/
      synAllWechat() {
        this.$confirm("是否确定同步客户状态到企业微信!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载Loading框
          this.loading = this.commonJsExtend.customLoading("#stateTable", 4,"数据同步中,请稍候!");
          //发送请求
          qywxApi.doSyncTagsToWeixin({
            tagGroupName: '客户状态'
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.getCustomerState(4); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        })
      },

      /* 同步客户标签到企业微信 */
      sysnAddToWeChat(id){
        let data = {
          id:id,
          tagGroupName:'客户状态'
        }
        qywxApi.doAddTag(data).then(res=>{
          if(res.code == 200){
            this.getCustomerState(1) //获取新数组
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
      },

      /* 修改企业微信标签 */
      sysnUpdateToWeChat(id){
        let data = {
          id:id,
          tagGroupName:'客户状态'
        }
        qywxApi.doUpdateTag(data).then(res=>{
          if(res.code == 200){
            this.getCustomerState(2) //获取新数组
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
      },

      /* 删除企业微信标签 type(0:修改时删除  1:删除时删除)*/
      deleteWechat(wxTagId,type){
        let data = {
          wx_tag_id:wxTagId,
        }
        qywxApi.doDeleteTag(data).then(res=>{
          if(res.code == 200){
            //判断是如何删除
            if(type == 0){
              this.getCustomerState(2) //获取新数组
            }else if(type == 1){
              this.getCustomerState(3) //获取新数组
            }
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        })
      },

      /* 初始化表单验证规则*/
      initRules() {
        this.state_rules = {
          name: [ //客户状态名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 取消新增输入框*/
      close() {
        this.stateStr = {
          name: ''
        }
        this.$refs['state_ruleform'].resetFields(); //移除表单验证规则
        this.show_addBox = false
      },
    },
  }
</script>

<style>
</style>
