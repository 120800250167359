<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：财务相关会计科目请求接口
开始时间：2021-03-13
开发人员：姚雨宏,刘巍骏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <!-- <div class="tabItem_headBox">
      <el-button size="small" @click="addProduct(-1)" type="primary">新增</el-button>
    </div> -->
    <!-- 内容 -->
    <div class="AppTreeTableContent" id="expenseContent">
      <!-- 左边树形结构 -->
      <div class="AppLeftBox">
        <div class="content_left">
          <!-- 分类列表 -->
          <div class="left_list searchClassfiy">
            <div class="left_all_btn" @click="getCostTypeData()">
              <div class="left_title">
                <i class="el-icon-menu"></i>
                <span>全部分类</span>
              </div>
              <div class="add_icon">
                <i class="el-icon-plus" @click="addProduct(-1)"></i>
              </div>
            </div>
            <div>
              <el-tree :data="FinanceCostType" :props="defaultProps" node-key="id" ref="tree" default-expand-all
                :expand-on-click-node="false" @node-click="getClassifyCostTypeData">
                <span class="custom-tree-node" slot-scope="{ node, FinanceCostType}">
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
          </div>
        </div>
      </div>

      <!-- 分类拉伸 -->
      <div class="AppHandelBox"></div>

      <!-- 右边表格 -->
      <div class="AppRightBox">
        <div class="tabItem_contentBox_expense billing_table">
          <el-table :data="tableCostTypeData" id="ClassfiTable" :indent="25" default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" height="100%" border
            style="width: 100%">
            <!-- 拖拽图标 -->
            <!-- <el-table-column width="50">
              <template slot-scope="scope">
                <i class="el-icon-s-grid my-handle"></i>
              </template>
            </el-table-column> -->
            <!-- 索引 -->
            <el-table-column type="index" width="50"></el-table-column>
            <!-- 操作 -->
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <i class="el-icon-edit-outline icon" @click="updateProduct(scope.row)" title="修改"></i>
                <i class="el-icon-delete icon" v-if="scope.row.is_system==0" @click="deleteCostType(scope.row)"
                  title="删除"></i>
                <i class="el-icon-circle-plus-outline icon" @click="addProduct(scope.row.id)" title="添加子级"></i>
              </template>
            </el-table-column>
            <!-- 数据内容 -->
            <el-table-column prop="encoding" label="科目编码" sortable width="120"></el-table-column>
            <el-table-column prop="name" label="科目名称" width="120"></el-table-column>
            <el-table-column prop="name" label="余额方向" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.outin_sign == 0" class="span_color_theme">贷(收)</span>
                <span v-if="scope.row.outin_sign == 1" class="span_color_red">借(支)</span>
                <span v-if="scope.row.outin_sign == 2">无</span>
              </template>
            </el-table-column>
            <el-table-column prop="is_check" label="参与核算" width="80">
              <template slot-scope="scope">
                <el-checkbox :value="scope.row.is_check==1?true:false" disabled></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="cost_voucher" label="会计凭证" width="80">
              <template slot-scope="scope">
                <el-checkbox :value="scope.row.cost_voucher==1?true:false" disabled></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="is_cost_main" label="收付款单" width="80">
              <template slot-scope="scope">
                <el-checkbox :value="scope.row.is_cost_main==1?true:false" disabled></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100">
              <template slot-scope="scope">
                <el-checkbox :value="scope.row.is_enable==1?true:false" disabled>启用</el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="系统默认" width="80">
              <template slot-scope="scope">
                <el-checkbox :checked="scope.row.is_system==1?true:false" disabled></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '会计科目'" :visible.sync="show_saveCostType" id="adupdate"
      width="600px" :before-close="cancleCostType" append-to-body :close-on-click-modal="false">
      <!-- 弹框内容-->
      <div class="financeDialog expenseClassifyDialog scollStyle" id="expenseClassifyDialog">
        <el-form :model="save_CostType" :rules="costType_rules" ref="productProperty_ruleform" label-width="100px"
          size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="12">
              <el-form-item label="科目编码:" prop="encoding">
                <el-input v-model.trim="save_CostType.encoding" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="科目名称:" prop="name">
                <el-input v-model.trim="save_CostType.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="收支标识:">
                <el-radio-group v-model="save_CostType.outin_sign">
                  <el-radio :label="0">收</el-radio>
                  <el-radio :label="1">支</el-radio>
                  <el-radio :label="2">无</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态:">
                <el-checkbox v-model="save_CostType.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="参与核算:" prop="is_check">
                <el-checkbox v-model="save_CostType.is_check"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="会计凭证:" prop="cost_voucher">
                <el-checkbox v-model="save_CostType.cost_voucher"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收付款单:" prop="is_cost_main">
                <el-checkbox v-model="save_CostType.is_cost_main"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="可见部门:" prop="power_dept_name">
                <el-tooltip placement="top" :disabled="save_CostType.power_dept_name == ''">
                  <div slot="content">{{save_CostType.power_dept_name}}</div>
                  <el-input v-model="save_CostType.power_dept_name" placeholder="请选择负责部门"
                    oninput="this.value=this.value.replace(/\S+/g,'')" @click.native="judgeRelatedData(0)" size="mini"
                    suffix-icon="el-icon-more"></el-input>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="可见类型:" prop="power_bills_type">
                <el-select v-model="save_CostType.power_bills_type" size="mini" multiple collapse-tags
                  placeholder="请选择类型">
                  <el-option v-for="item in billsTypeData" :key="item.id" :label="item.bills_type" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="辅助核算:" prop="assist_check">
                <el-checkbox-group v-model="save_CostType.assist_check">
                  <el-checkbox label="0">客户</el-checkbox>
                  <el-checkbox label="1">供应商</el-checkbox>
                  <el-checkbox label="2">职员</el-checkbox>
                  <el-checkbox label="3">部门</el-checkbox>
                  <el-checkbox label="4">项目</el-checkbox>
                  <el-checkbox label="5">存货</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="数量核算:" prop="calculate_id">
                <el-select v-model="save_CostType.calculate_id" size="mini" placeholder="请选择">
                  <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="外币核算:" prop="currency_id">
                <el-select v-model="save_CostType.currency_id" size="mini" placeholder="请选择">
                  <el-option v-for="item in FinanceCurrency" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="save_CostType.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleCostType">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCostType">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 部门选择弹框 -->
    <departmentTree @SelectedData="getDepartmentData" v-if="show_departmentComponent"></departmentTree>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'; //数据字典相关接口

  //导入组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件

  export default {
    name: "finance_expense",
    data() {
      return {
        // 界面数据
        costType_rules: [], //表单验证规则
        billsTypeData: [], //单据类型数据
        tableCostTypeData: [], //表格会计科目数据
        operateState: 0, //判断新增还是修改(0:新增 1:修改)
        save_CostType: { //修改保存临时值
          id: '', //id
          name: '', //名称
          encoding: '', //编码
          parent_id: '', //父级id
          outin_sign: '', //收支标识
          is_enable: true, //是否启用
          is_check: false, //是否参与核算
          cost_voucher: false, //会计凭证
          is_cost_main: false, //是否收付款单显示
          power_dept_id: '', //可见部门id
          power_dept_name: '', //可见部门名称
          power_bills_type: [], //作用单据类型
          assist_check: [], //辅助核算
          calculate_id: '', //计量单位id
          currency_id: '', //交易币种id
          remark: '', //备注
        },

        //修改相关
        sourceData: {}, //编辑源数据
        old_name: '', //编辑前的name
        old_code: '', //编辑前的code

        //控制开关
        show_saveCostType: false, //是否显示新增框
        show_departmentComponent: false, //是否显示部门选择弹框

        //element-ui数据
        defaultProps: { //改变tree属性名
          children: 'children',
          label: 'name'
        },
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
      // 初始化vuex数据字典数据
      this.initVuexData();
    },
    mounted() {
      // 初始化分类栏目拉伸
      this.commonJsExtend.getDunamicWidth();
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#ClassfiTable>.el-table__body-wrapper > table > tbody");
      //判断当前下标
      this.judgeTabIndex();
    },
    computed: {
      ...mapState({
        FinanceCurrency: state => state.system.FinanceCurrency, //交易币种数据
        ProductUnit: state => state.system.ProductUnit, //计量单位
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        FinanceCostType: state => state.system.FinanceCostType, //会计科目数据
        FinanceTabIndex: state => state.system.FinanceTabIndex, //会计科目字典选中下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'FINANCE_COSTTYPE', //存放会计科目到vuex
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
      ]),
      ...mapActions([
        'getFinanceCurrency', //交易币种
        'getProductUnit', //获取计量单位信息
        'getelectronicTemplate', //获取所有单据类型
      ]),

      /* 初始化数据字典vuex数据 */
      async initVuexData() {
        //交易币种
        if (this.FinanceCurrency.length == 0) {
          await this.getFinanceCurrency();
        }
        //计量单位
        if (this.ProductUnit.length == 0) {
          await this.getProductUnit();
        }
        //单据类型
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        //获取费用分类单据类型
        this.billsTypeData = this.commonJsExtend.getBillsClassifyBySign(6, 2, this.billTemplateData);
      },

      /* 判断当前界面下标 */
      judgeTabIndex() {
        this.findAllCostType();
      },

      /* 获取会计科目所有数据 */
      getCostTypeData() {
        this.tableCostTypeData = this.FinanceCostType;
      },

      /* 获取子级数据 */
      getClassifyCostTypeData(data) {
        this.tableCostTypeData = [];
        this.tableCostTypeData.push(data);
      },

      /* 查询会计科目*/
      findAllCostType(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#expenseContent", 3);
        // 发送请求
        api.financeDict.findAllCostType().then(res => {
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.FINANCE_COSTTYPE(res.data);
            //获取表格数据
            this.tableCostTypeData = res.data;
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '排序更新成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
          }
        });
      },

      /* 打开新增弹窗*/
      addProduct(parentid) {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        //设置状态为新增
        this.operateState = 0;
        //设置默认数据
        this.save_CostType = { //修改保存临时值
          id: '', //id
          name: '', //名称
          encoding: '', //编码
          parent_id: parentid, //父级id
          outin_sign: 2, //收支标识
          is_enable: true, //是否启用
          is_check: false, //是否参与核算
          cost_voucher: false, //会计凭证
          is_cost_main: false, //是否收付款单显示
          power_dept_id: '', //可见部门id
          power_dept_name: '', //可见部门名称
          power_bills_type: [], //作用单据类型
          assist_check: [], //辅助核算
          calculate_id: '', //计量单位id
          currency_id: '', //交易币种id
          remark: '', //备注
        }
        //获取默认计量单位
        if (this.ProductUnit.length > 0) {
          this.save_CostType.calculate_id = this.ProductUnit[0].id;
        }
        //获取默认币种
        if (this.FinanceCurrency.length > 0) {
          this.save_CostType.currency_id = this.FinanceCurrency[0].id;
        }
        //显示弹框
        this.show_saveCostType = true;
      },

      /* 打开修改窗口*/
      updateProduct(a) {
        let data = JSON.parse(JSON.stringify(a))
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        //设置操作类型为修改
        this.operateState = 1;
        //获取修改数据
        this.save_CostType = { //修改保存临时值
          id: data.id, //id
          name: data.name, //名称
          encoding: data.encoding, //编码
          parent_id: data.parent_id, //父级id
          outin_sign: data.outin_sign, //收支标识
          is_enable: data.is_enable == 1 ? true : false, //是否启用
          is_check: data.is_check == 1 ? true : false, //是否参与核算
          cost_voucher: data.cost_voucher == 1 ? true : false, //会计凭证
          is_cost_main: data.is_cost_main == 1 ? true : false, //是否收付款单显示
          power_dept_id: data.power_dept_id, //可见部门id
          power_dept_name: data.power_dept_name, //可见部门名称
          power_bills_type: data.power_bills_type == null || data.power_bills_type == '' ? [] : data.power_bills_type
            .split(","), //作用单据类型
          assist_check: data.assist_check == null || data.assist_check == '' ? [] : data.assist_check.split(
          ","), //辅助核算
          calculate_id: data.calculate_id, //计量单位id
          currency_id: data.currency_id, //交易币种id
          remark: data.remark, //备注
        }
        //将费用分类转换为int类型
        if (this.save_CostType.power_bills_type.length > 0) {
          let intArr = [];
          //循环处理未int数组
          this.save_CostType.power_bills_type.forEach(item => {
            intArr.push(parseInt(item));
          })
          this.save_CostType.power_bills_type = intArr;
        }
        //获取旧名称
        this.old_name = data.name;
        //获取旧编码
        this.old_code = data.encoding;
        //显示弹框
        this.show_saveCostType = true;
        //将源数据储存
        this.sourceData = JSON.parse(JSON.stringify(this.save_CostType));
      },

      /* 删除会计科目 */
      deleteCostType(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#expenseContent", 2);
          //发送删除请求
          api.financeDict.deleteCostType({
            id: data.id, //会计科目id
            name: data.name //会计科目名称
          }).then(res => {
            this.loading.close(); //关闭加载框
            if (res.code == 200) {
              this.findAllCostType(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
            this.loading.close(); //关闭加载框
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
        });
      },

      /* 保存会计科目信息*/
      saveCostType() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['productProperty_ruleform'].validate(valid => {
          if (valid) {
            // 判断是新增还是修改
            if (this.operateState == 0) {
              let data = {
                name: this.save_CostType.name, //名称
                encoding: this.save_CostType.encoding, //编码
                parent_id: this.save_CostType.parent_id, //父级id
                outin_sign: this.save_CostType.outin_sign, //收支标识
                is_enable: this.save_CostType.is_enable ? 1 : 0, //是否启用
                is_check: this.save_CostType.is_check ? 1 : 0, //是否参与核算
                cost_voucher: this.save_CostType.cost_voucher ? 1 : 0, //会计凭证
                is_cost_main: this.save_CostType.is_cost_main ? 1 : 0, //是否收付款单显示
                power_dept_id: this.save_CostType.power_dept_id, //可见部门id
                power_dept_name: this.save_CostType.power_dept_name, //可见部门名称
                power_bills_type: this.save_CostType.power_bills_type.join(","), //作用单据类型
                assist_check: this.save_CostType.assist_check.join(","), //辅助核算
                calculate_id: this.save_CostType.calculate_id, //计量单位id
                currency_id: this.save_CostType.currency_id, //交易币种id
                remark: this.save_CostType.remark, //备注
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#expenseClassifyDialog", 0);
              //发送请求
              api.financeDict.addCostType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  //关闭弹框
                  this.show_saveCostType = false;
                  //获取新数组
                  this.findAllCostType(1);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            } else {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.save_CostType, '会计科目');
              //修改数据
              let data = {
                id: this.save_CostType.id,
                name: this.old_name, //名称
                new_name: this.save_CostType.name,
                encoding: this.old_code, //旧编码
                new_encoding: this.save_CostType.encoding, //新编码
                parent_id: this.save_CostType.parent_id, //父级id
                outin_sign: this.save_CostType.outin_sign, //收支标识
                is_enable: this.save_CostType.is_enable ? 1 : 0, //是否启用
                is_check: this.save_CostType.is_check ? 1 : 0, //是否参与核算
                cost_voucher: this.save_CostType.cost_voucher ? 1 : 0, //会计凭证
                is_cost_main: this.save_CostType.is_cost_main ? 1 : 0, //是否收付款单显示
                power_dept_id: this.save_CostType.power_dept_id, //可见部门id
                power_dept_name: this.save_CostType.power_dept_name, //可见部门名称
                power_bills_type: this.save_CostType.power_bills_type.join(","), //作用单据类型
                assist_check: this.save_CostType.assist_check.join(","), //辅助核算
                calculate_id: this.save_CostType.calculate_id, //计量单位id
                currency_id: this.save_CostType.currency_id, //交易币种id
                remark: this.save_CostType.remark, //备注
                logContent //日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#expenseClassifyDialog", 1);
              //发送请求
              api.financeDict.updateCostType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  //关闭弹框
                  this.show_saveCostType = false;
                  //获取新数组
                  this.findAllCostType(2);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: 1500
                  })
                }
              });
            }
          }
        });
      },

      /* type(0:部门) */
      judgeRelatedData(type) {
        if (type == 0) { //部门
          this.SHOW_DEPARTMENTBOX(true);
          this.show_departmentComponent = true;
        }
      },

      /* 获取部门弹框选择数据*/
      getDepartmentData(data) {
        let result = this.commonJsExtend.getDeptMsg(data);
        //获取选择的id数据
        this.save_CostType.power_dept_id = result.idStr;
        //获取选择的数据
        this.save_CostType.power_dept_name = result.nameStr;
        // 关闭加载部门组件
        this.show_departmentComponent = false;
      },

      /* 取消新增或修改操作 */
      cancleCostType() {
        this.$refs['productProperty_ruleform'].resetFields(); //移除表单验证规则
        this.show_saveCostType = false;
      },

      /* 初始化表单验证规则*/
      initRules() {
        this.costType_rules = {
          name: [ //会计科目名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 拖动排序*/
      changeSortNum(newVal) { //拖动排序
        if (this.FinanceTabIndex == "3.4" && this.DictionaryLeftIndex == 3) {
          let data = {
            sourceId: this.FinanceCostType[newVal.oldIndex].id,
            targetId: this.FinanceCostType[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#ClassfiTable", 1);
          api.financeDict.updateSysCostTypeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllCostType(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
    components: {
      departmentTree
    }
  }
</script>

<!-- element ui自定义样式 -->
<style>
  /* 复选框样式*/
  .expenseClassifyDialog .el-checkbox {
    margin-right: 20px !important;
  }
</style>

<style lang="less" scoped>
  /* 内容框 */
  .AppTreeTableContent {
    height: 86vh;
    width: 100%;

    // border: 1px solid red
    .left_list {
      padding-top: 0px !important;
    }
  }

  // 表格外框
  .ItemIndex_tabItem {
    .content_left {
      border-top: 1px solid #EBEEF5;
      border-left: 1px solid #EBEEF5;
    }

    // 费用分类表格
    .tabItem_contentBox_expense {
      height: 86vh;
      padding-left: 10px;
    }
  }
</style>
