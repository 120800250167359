<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：系统相关请求接口
开始时间：2021-03-13
开发人员：刘巍骏,万攀
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->

<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addRelation">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="relationTable" :data="relationData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="45"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateRelation(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteCustomerRelation(scope.row)" title="删除"></i>

          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="color" label="颜色" width="120"></el-table-column>
        <el-table-column prop="zt" label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.zt" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!--修改客户关系弹窗 -->
    <el-dialog :title="(operateRelation == 0 ?'新增':'修改') + '客户关系'" :visible.sync="show_addBox" width="400px"
      :before-close="close" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="relation_rules" ref="relation_ruleform" :model="relationStr" size="mini" label-width="80px">

          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="relationStr.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="saveRelation">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: 'customer_relation',
    data() {
      return {
        //当前页面数据
        relationData: [],
        relationStr: {
          name: ''
        },
        //定义编辑源数据
        sourceData: {},
        relation_rules: [], //表单验证规则
        relationEdit: {},
        //控制开关
        show_addBox: false,
        //判断条件
        operateRelation: 0, //0是新增 1是修改
        loading: '',
      }
    },
    computed: {
      ...mapState({
        CustomerRelation: state => state.system.CustomerRelation,
        CustomerTabIndex: state => state.system.CustomerTabIndex, //客户数据字典选中下标
      }),
    },
    created() {
      this.initRules();
    },
    watch: {
      //监听客户相关数据字典选项卡下标
      CustomerTabIndex(newVal) {
        //判断下标是否为客户关系
        if (newVal == 0.4) {
          //判断vuex是否存在数据
          this.juejeRelation();
        }
      }
    },
    // created(){
    //   this.juejeRelation() //获取客户关系
    // },
    methods: {
      ...mapMutations([
        'CUSTOMER_RELATION' //获取客户关系
      ]),
      ...mapActions([
        'getCustomerRelation' //获取客户关系
      ]),
      /* 初始话表单验证规则*/
      initRules() {
        this.relation_rules = {
          name: [ //客户关系名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },
      /* 取消新增输入框*/
      close() {
        this.relationStr = {
            name: ''
          },
          this.show_addBox = false;
        this.$refs['relation_ruleform'].resetFields(); //移除表单验证规则
      },
      juejeRelation() {
        if (this.CustomerRelation.length == 0) {
          this.getCustomerRela();
        }
      },
      /*获取客户关系数据*/
      getCustomerRela(type) {
        this.loading = this.commonJsExtend.customLoading("#relationTable", 3);
        api.customerDict.getCustomerRelation().then(res => {
          if (res.code == 200) {
            this.CUSTOMER_RELATION(res.data);
            this.relationData = [],
              this.relationData = JSON.parse(JSON.stringify(res.data))
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭新增输入框
          }
        })
      },
      /* 新增客户关系*/
      addRelation() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.show_addBox = true;
        this.operateRelation = 0
      },
      /* 删除数据*/
      deleteCustomerRelation(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#relationTable", 2);
          api.customerDict.deleteCustomerRelation({
            relationsid: data.id, //客户关系id
            name: data.name //客户关系名称
          }).then(res => {
            this.loading.close()
            if (res.code == 200) {
              this.getCustomerRela(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },
      /* 修改数据*/
      updateRelation(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true; //显示编辑框
        this.relationStr.name = data.name; //将编辑信息赋值到编辑框
        this.sourceData = JSON.parse(JSON.stringify(this.relationStr)) //将源数据储存
        this.relationEdit = data; //存入当前编辑信息
        this.operateRelation = 1; //设置当前状态为修改
      },
      saveRelation() {
        this.$refs['relation_ruleform'].validate(valid => {
          if (valid) {
            //判断是修改还是新增
            if (this.operateRelation == 0) {
              var data = {
                verifystr: this.relationStr.name //新增状态名称
              }
              this.loading = this.commonJsExtend.customLoading("#relationTable", 0);
              //发送请求
              api.customerDict.addCustomerRelation(data).then((res) => {
                this.loading.close();
                if (res.code == 200) {
                  this.relationStr = {
                    name: ''
                  } //清空输入框
                  this.getCustomerRela(1) //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.operateRelation == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.relationStr, '客户关系');
              var data = {
                old_name: this.relationEdit.name,
                relationsid: this.relationEdit.id,
                verifystr: this.relationStr.name,
                logContent
              }
              this.loading = this.commonJsExtend.customLoading("#relationTable", 1);
              api.customerDict.updateCustomerRelation(data).then(res => {
                this.loading.close()
                if (res.code == 200) {
                  this.relationStr = {
                    name: ''
                  }; //清空输入框
                  this.getCustomerRela(2) //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            }
          }

        })


      }
    }
  }
</script>

<style>
</style>
