<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：系统相关请求接口
开始时间：2021-03-13
开发人员：刘巍骏
最后修改：2021-03-18
备注说明：用做数据字典前端模板参考 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="mini" type="primary" @click="addFollowupWay">新增</el-button>
      <el-button size="small" type="primary" @click="getCustomerFollowupWay">刷新</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="billing_body" row-key="id" :data="CustomerFollowupWay" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateFollowupWay(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteFollowupWay(scope.row)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable width="120">
        </el-table-column>
        <el-table-column label="颜色" width="120"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!-- 新增/修改弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '跟进方式'" :visible.sync="show_addBox" width="400px" append-to-body
      :before-close="cancleFollowupWay">
      <!-- 内容 -->
      <div class="dictionaryAddBox">
        <el-form :model="followupWayEidt" :rules="followway_rules" ref="follow_ruleform" label-width="80px"
          class="demo-ruleForm" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="followupWayEidt.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="颜色:">
                <el-color-picker v-model="followupWayEidt.color" size="mini"></el-color-picker>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="followupWayEidt.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="followupWayEidt.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleFollowupWay">取 消</el-button>
        <el-button size="small" type="primary" @click="saveFollowupWay">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary'

  import Sortable from "sortablejs";

  export default {
    name: 'customer_followway',
    data() {
      return {
        // 当前页面数据
        followway_rules: [], //表单验证规则
        followupWayOld: {}, //编辑前存的旧数据
        followupWayEidt: {}, //当前正在编辑的数据
        //定义编辑源数据
        sourceData: {},

        //控制开关
        show_addBox: false, //是否显示新增框

        //判断条件
        operateState: 0, //判断新增还是修改(0:新增 , 1::修改)

        //loading框
        loading: '', //控制显示loading框
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#billing_body>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        CustomerFollowupWay: state => state.system.CustomerFollowupWay, //跟进方式数据
        CustomerTabIndex: state => state.system.CustomerTabIndex, //客户数据字典选中下标
        SortIndex: state => state.system.SortIndex, //表格排序下标
      }),
    },
    watch: {
      //监听客户相关数据字典选项卡下标
      CustomerTabIndex(newVal) {
        //判断下标是否为跟进方式
        if (newVal == "0.6") {
          //判断vuex是否存在数据
          this.judjeFollowupWay();
        }
      },
      //监听排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'CUSTOMER_FOLLOWUPWAY', //存放跟进方式到vuex
      ]),
      ...mapActions([]),

      /* 初始话表单验证规则*/
      initRules() {
        this.followway_rules = {
          name: [ //跟进方式名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 判断vuex是否存在数据*/
      judjeFollowupWay() {
        // vuex中没有数据,从服务器请求
        if (this.CustomerFollowupWay.length == 0) {
          this.getCustomerFollowupWay();
        }
      },

      /* 获取跟进方式数据 */
      getCustomerFollowupWay(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#billing_body", 3);
        // 发送请求
        api.customerDict.getCustomerFollowupWay().then(res => {
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.CUSTOMER_FOLLOWUPWAY(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2:修改   3:删除  4:排序)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '排序修改成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭弹框
          }else{
            this.$message({
              type:'error',
              message:res.message,
              duration:this.elDuration
            })
          }
        });
      },

      /* 新增跟进方式 */
      addFollowupWay() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        // 获取新增默认数据
        this.followupWayEidt = {
          name: '', //名称清空
          is_enable: true, //状态默认启用
          remark: '' //备注清空
        }
        this.show_addBox = true; //显示弹框
        this.operateState = 0; //设置操作类型
      },

      /* 删除数据 */
      deleteFollowupWay(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#billing_body", 2);
          //发送删除请求
          api.customerDict.deleteCustomerFollowupWay({
            followupwayid: data.id,//跟进方式id
            name:data.name//跟进方式名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.getCustomerFollowupWay(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 修改数据 */
      updateFollowupWay(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true; //显示编辑框
        this.followupWayOld = data; //将旧数据存入data
        this.sourceData = JSON.parse(JSON.stringify(this.followupWayOld)) //将源数据储存
        this.followupWayEidt = { //存入当前编辑信息
          name: data.name, //名称赋值
          is_enable: data.is_enable == 1 ? true : false, //状态
          remark: data.remark //备注
        };
        this.operateState = 1; //设置当前状态为修改
      },

      /* 保存数据 */
      saveFollowupWay() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['follow_ruleform'].validate(valid => {
          // 表单验证全部合格
          if (valid) {
            // 判断新增还是修改
            if (this.operateState == 0) { //新增
              //关闭弹框
              this.show_addBox = false;
              //定义传入后端的数据
              let data = {
                verifystr: this.followupWayEidt.name,
                is_enable: this.followupWayEidt.is_enable ? 1 : 0,
                remark: this.followupWayEidt.remark
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#billing_body", 0);
              //发送请求
              api.customerDict.addCustomerFollowupWay(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) { //新增成功
                  this.getCustomerFollowupWay(1); //获取新数组
                } else { //新增失败
                  // 显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            } else { //修改
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.followupWayEidt, '跟进方式');
              //关闭弹框
              this.show_addBox = false;
              //定义传入后端的数据
              let data = {
                followupwayid: this.followupWayOld.id,
                old_name: this.followupWayOld.name,
                verifystr: this.followupWayEidt.name,
                is_enable: this.followupWayEidt.is_enable ? 1 : 0,
                remark: this.followupWayEidt.remark,
                logContent//日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#billing_body", 1);
              //发送请求
              api.customerDict.updateCustomerFollowupWay(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) { //修改成功
                  this.getCustomerFollowupWay(2); //获取新数组
                } else {
                  // 显示弹框
                  // this.show_addBox = true;
                  //显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            }
          }
        });
      },

      /* 取消新增或修改操作 */
      cancleFollowupWay() {
        this.$refs['follow_ruleform'].resetFields(); //移除表单验证规则
        this.show_addBox = false;
      },

      /* 改变排序方法*/
      changeSortNum(newVal){
        if (this.CustomerTabIndex != "0.6") {
          return
        }
        let data = {
          sourceId: this.CustomerFollowupWay[newVal.oldIndex].id,
          targetId: this.CustomerFollowupWay[newVal.newIndex].id
        }
        this.loading = this.commonJsExtend.customLoading("#billing_body", 1);
        api.customerDict.upadteFollowupwaySort(data).then(res => {
          if (res.code == 200) {
            this.loading.close();
            this.getCustomerFollowupWay(4); //获取新数组
          } else {
            this.$message({
              type: "error",
              message: res.message,
              duration: this.elDuration
            })
            this.loading.close();
          }
        })
      },

      /* 初始化表格行拖动功能*/
      initDrag() {
        //初始化需要拖动的容器
        const el = document.querySelectorAll("#billing_body>.el-table__body-wrapper > table > tbody")[0];
        console.log(el);
        Sortable.create(el, {
          disabled: false, // 拖拽不可用? false启用
          // ghostClass: 'sortable-ghost', //拖拽样式
          // chosenClass: "chosen",
          // dragClass: "chosen",
          animation: 150, // 拖拽延时，效果更好看
          handle: ".my-handle",
          onEnd: (e) => { // 拖拽结束时的触发
            console.log(e);
            // let arr = this.CustomerFollowupWay; // 获取表数据
            console.log(e.newIndex);
            console.log(this.CustomerFollowupWay[e.newIndex]);
            console.log(e.oldIndex);
            console.log(this.CustomerFollowupWay[e.oldIndex]);
            let data = {
              sourceId: this.CustomerFollowupWay[e.oldIndex].id,
              targetId: this.CustomerFollowupWay[e.newIndex].id
            }
            this.loading = this.commonJsExtend.customLoading("#billing_body", 1);
            api.customerDict.upadteFollowupwaySort(data).then(res => {
              if (res.code == 200) {
                this.loading.close();
                this.getCustomerFollowupWay(4); //获取新数组
              }
              console.log(res);
            })
          },
        });
      }
    },
  }
</script>

<style>
</style>
