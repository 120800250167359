<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：生产相关请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <!-- 二分-->
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addCheck">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="CostType" row-key="id" height="100%" id="costType_table" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateCheck(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteMesCostType(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1?true:false" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="系统默认" width="200">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system==1?true:false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>
    <!--修改增加弹框 -->
    <el-dialog :title="(operatedState == 0 ?'新增':'修改') + '计费方式'" :visible.sync="show_saveCostType" width="400px"
      :before-close="cancleCostType_process" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="saveCost_Type" :rules="costType_rules" ref="costType_ruleform" label-width="80px" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="saveCost_Type.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="saveCost_Type.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="saveCost_Type.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleCostType_process">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCostType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: "production_chargeMode",
    data() {
      return {
        costType_rules: [], //表单验证规则
        operatedState: 0, //0:增加 1：删除
        saveCost_Type: { //增加框存的值
          name: '',
          remark: '',
          is_system: '',
          is_enable: '',
          old_name: '',
          id: '',
        },
        //定义编辑源数据
        sourceData: {},
        show_saveCostType: false, //控制增加修改弹窗开关
      }
    },
    created() {
      this.initRules(); //初始化表单验证
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#costType_table>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        CostType: state => state.system.CostType, //vuex中CostType的值
        ProductionTabIndex: state => state.system.ProductionTabIndex, //当前下标
        SortIndex: state => state.system.SortIndex, //排序下标
      })
    },
    watch: {
      //监听生产相关数据字典选项卡下标
      ProductionTabIndex(newVal) {
        //判断下标是否为计费方式
        if (newVal == "4.3") {
          //判断vuex是否存在数据
          this.judjeCostType();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);


        }
      },

    },
    methods: {
      ...mapMutations([
        'PRODUCTION_COSTTYPE' //把计费方式存入vuex
      ]),
      /* 初始话表单验证规则*/
      initRules() {
        this.costType_rules = {
          name: [ //计费方式名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },
      cancleCostType_process() { //取消表单验证
        this.$refs['costType_ruleform'].resetFields(); //移除表单验证规则
        this.show_saveCostType = false;
      },
      addCheck() { //点击新增按钮触发
      //判断是否有新增权限
      if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
        return
      }
        this.operatedState = 0;
        this.show_saveCostType = true;
        this.saveCost_Type = {
          name: '',
          remark: '',
          is_system: '',
          is_enable: true,
          old_name: '',
          id: '',
        };
      },
      updateCheck(data) { //点击编辑修改按钮触发
      //判断是否有修改权限
      if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
        return
      }
        this.operatedState = 1;
        this.saveCost_Type.old_name = data.name;
        this.saveCost_Type.name = data.name;
        this.saveCost_Type.id = data.id;
        this.saveCost_Type.is_enable = data.is_enable == 1 ? true : false;
        this.saveCost_Type.remark = data.remark;
        this.show_saveCostType = true;
        this.sourceData = JSON.parse(JSON.stringify(this.saveCost_Type)) //将源数据储存
      },
      /* 判断vuex是否存在数据*/
      judjeCostType() {
        if (this.CostType.length == 0) {
          this.findAllMesCostType();
        }
      },
      /* 查询计费方式*/
      findAllMesCostType(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#costType_table", 3);
        //发送请求
        api.production.findAllMesCostType().then(res => {
          if (res.code == 200) {
            this.loading.close(); //关闭加载框
            this.PRODUCTION_COSTTYPE(res.data); //把查询到的数据放进vuex中
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2:修改   3:删除  4:排序)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
          }
        })
      },
      /* 增加修改方法*/
      saveCostType() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['costType_ruleform'].validate(valid => {
          if (valid) {
            if (this.operatedState == 0) { //增加
              let data = {
                name: this.saveCost_Type.name,
                remark: this.saveCost_Type.remark,
                is_enable: this.saveCost_Type.is_enable ? 1 : 0,
                is_system: '0',
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#costType_table", 0);
              //发送请求
              api.production.addMesCostType(data).then(res => {
                this.loading.close(); //关闭加载框
                if (res.code == 200) {
                  this.findAllMesCostType(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              this.show_saveCostType = false; //关闭弹窗
            } else if (this.operatedState == 1) { //修改
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.saveCost_Type, '计费方式');
              let data = {
                name: this.saveCost_Type.old_name,
                new_name: this.saveCost_Type.name,
                id: this.saveCost_Type.id,
                is_enable: this.saveCost_Type.is_enable ? 1 : 0,
                is_system: '0',
                remark: this.saveCost_Type.remark,
                logContent //日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#costType_table", 1);
              //发送请求
              api.production.updateMesCostType(data).then(res => {
                this.loading.close(); //关闭加载框
                if (res.code == 200) {
                  this.findAllMesCostType(2); //获取新数组
                } else {
                  // 显示弹框
                  // this.show_addBox = true;
                  //显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_saveCostType = false; //关闭弹窗
              })
            }
          }
        })
      },
      /* 删除计费方式 */
      deleteMesCostType(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#costType_table", 2);
          //发送删除请求
          api.production.deleteMesCostType({
            id: data.id, //计费方式id
            name: data.name //计费方式名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.findAllMesCostType(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductionTabIndex == "4.3" && this.DictionaryLeftIndex == 4) {
          let data = {
            sourceId: this.CostType[newVal.oldIndex].id,
            targetId: this.CostType[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#costType_table", 1);
          api.production.updateSysMesCostTypeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllMesCostType(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },

    },
  }
</script>

<style>
</style>
