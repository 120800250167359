<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关品牌管理接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="show_add" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="Brand" row-key="id" id="checkBrank" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="show_updatePD(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteBrand(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system == 1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>
    <!-- 新增品牌弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '品牌'" :visible.sync="show_addBrankBox"
      :before-close="cancleBrank" width="400px" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="Add_Brank" :rules="Brank_rules" ref="Brank_from" size="mini" label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="编码:">
                <el-input v-model.trim="Add_Brank.encoding" placeholder="请输入编码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="Add_Brank.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="Add_Brank.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="Add_Brank.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleBrank">取 消</el-button>
        <el-button size="small" type="primary" @click="addUpdate_PD">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: "product_brand",
    data() {
      return {
        Brank_rules: [], //表单验证规则
        operateState: '0', //修改或者增加标记
        old_name: '', //修改之前的name
        Add_Brank: { //增加框属性
          encoding: '',
          name: '',
          remark: '',
          is_enable: '', //是否启用(0:否 1:是)
          is_system: '', //是否为系统默认(0:否 1:是)
        }, //新增品牌属性
        //定义编辑源数据
        sourceData: {},
        show_addBrankBox: false, //控制新增修改品牌控制开关
        id: '', //编辑时用到的id
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      //开启拖拽
      this.commonJsExtend.customDragrow("#checkBrank>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        Brand: state => state.system.Brand, //品牌
        ProductTabIndex: state => state.system.ProductTabIndex, //客户数据字典选中下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //监听货品相关数据字典选项卡下标
      ProductTabIndex(newVal) {
        //判断下标是否为品牌
        if (newVal == "6.3") {
          //判断vuex是否存在数据
          this.judjeBrank();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCT_BRAND', //存放品牌到vuex
      ]),
      /* 取消新增或修改操作 */
      cancleBrank() {
        this.$refs['Brank_from'].resetFields(); //移除表单验证规则
        this.show_addBrankBox = false;
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.Brank_rules = {
          name: [ //品牌名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 展示增加品牌框*/
      show_add() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.operateState = 0;
        this.Add_Brank = {
            encoding: '',
            name: '',
            remark: '',
            is_enable: true, //是否启用(0:否 1:是)
            is_system: true, //是否为系统默认(0:否 1:是)
          }, //刷新增加弹框里面的数据
          this.show_addBrankBox = true;
      },
      /* 展示修改品牌框*/
      show_updatePD(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.operateState = 1;
        this.Add_Brank.encoding = data.encoding
        this.Add_Brank.name = data.name;
        this.Add_Brank.remark = data.remark;
        this.old_name = data.name;
        this.id = data.id;
        this.Add_Brank.is_enable = data.is_enable == 1 ? true : false;
        this.Add_Brank.is_system = data.is_system == 1 ? true : false;
        this.show_addBrankBox = true;
        this.sourceData = JSON.parse(JSON.stringify(this.Add_Brank)) //将源数据储存
      },
      /* 判断vuex是否存在数据*/
      judjeBrank() {
        // vuex中没有数据,从服务器请求
        if (this.Brand.length == 0) {
          this.findAllBrand();
        }
      },
      /* 查询品牌*/
      findAllBrand(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#checkBrank", 3);
        api.productDict.findAllBrand().then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.PRODUCT_BRAND(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
            this.show_addBox = false; //关闭新增输入框
          }
        });
      },
      /*增加品牌*/
      addUpdate_PD() {
        this.$refs['Brank_from'].validate(valid => {
          if (valid) {
            if (this.operateState == 0) {
              let data = {
                encoding:this.Add_Brank.encoding,
                name: this.Add_Brank.name,
                is_enable: this.Add_Brank.is_enable ? 1 : 0,
                remark: this.Add_Brank.remark
              }
              this.loading = this.commonJsExtend.customLoading("#checkBrank", 1);
              api.productDict.addBrand(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllBrand(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_addBrankBox = false; //关闭新增输入框
              });
            } else if (this.operateState == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.Add_Brank, '品牌管理');
              this.loading = this.commonJsExtend.customLoading("#checkBrank", 1);
              var data = {
                id: this.id,
                encoding:this.Add_Brank.encoding,
                name: this.old_name,
                new_name: this.Add_Brank.name,
                remark: this.Add_Brank.remark,
                is_enable: this.Add_Brank.is_enable ? 1 : 0,
                logContent //日志
              }
              api.productDict.updateBrand(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllBrand(2);
                  this.show_addBrankBox = false;
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            }
          }
        })
      },
      /* 删除品牌*/
      deleteBrand(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#checkBrank", 2);
          //发送删除请求
          api.productDict.deleteBrand({
            id: data.id, //品牌管理id
            name: data.name //品牌管理名称
          }).then(res => {
            this.loading.close(); //关闭加载框
            if (res.code == 200) {
              this.findAllBrand(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
        });
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.3" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.Brand[newVal.oldIndex].id,
            targetId: this.Brand[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#checkBrank", 1);
          api.productDict.updateSysProductBrandSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllBrand(4); //获取新数组
            } else {
              console.log("e");
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
  }
</script>

<style>
</style>
