<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：财务相关币种相关请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addCurrency">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="currencyTable" row-key="id" :data="FinanceCurrency" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click="updateCurrency(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteFinanceCurrency(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="currency_symbol" label="币种符号" width="120"></el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_system==1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="exchange_rate" label="汇率" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operateCurrency == 0 ?'新增':'修改') + '交易币种'" :visible.sync="show_addBox" width="400px"
      :before-close="cancleFinanceCurrency" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="save_financeCurrency" :rules="currency_rules" ref="currency_ruleform" label-width="100px"
          size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="save_financeCurrency.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="币种符号" prop="currency_symbol">
                <el-input v-model="save_financeCurrency.currency_symbol" placeholder="请输入币种符号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="与人民币汇率:" prop="exchange_rate">
                <el-input v-model="save_financeCurrency.exchange_rate"
                  oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" placeholder="请输入与人民币汇率"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="save_financeCurrency.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="save_financeCurrency.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleFinanceCurrency">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCurrency">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'

  export default {
    name: 'finance_currency',
    data() {
      return {
        currency_rules: [], //表单校验
        save_financeCurrency: { //增加框里面的值
          name: '', //名称
          currency_symbol: '', //币种符号
          is_enable: '', // 是否启用
          is_system: '', // 是否系统默认
          remark: '', // 备注说明
          id: '', // id
          old_name: '', // 更改之前的name
          exchange_rate: '', //汇率
        },
        //定义编辑源数据
        sourceData: {},

        //判断条件
        operateCurrency: 0,

        //增加修改弹窗开关
        show_addBox: false,

        //loading加载
        loading: '',
      }
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        FinanceCurrency: state => state.system.FinanceCurrency, //币种数据
        FinanceTabIndex: state => state.system.FinanceTabIndex, // 鼠标点击下标
        SortIndex: state => state.system.SortIndex, //排序下标
      })
    },
    watch: {
      //监听财务相关数据字典选项卡下标
      FinanceTabIndex(newVal) {
        if (newVal == "3.3") {
          this.judjeCurrency();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    created() {
      this.initRules(); //初始化表单检验
      /* this.getFinanceCurrency(); */
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#currencyTable>.el-table__body-wrapper > table > tbody");
    },
    methods: {
      ...mapMutations([
        'FINANCE_CURRENCY'
      ]),

      /* 新增*/
      addCurrency() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.show_addBox = true;
        this.operateCurrency = 0;
        this.save_financeCurrency = {
          name: '',
          is_enable: true,
          is_system: '',
          remark: '',
          exchange_rate: '',
          currency_symbol: '',
          id: '',
        }
      },

      /*判断vuex中是否有数据*/
      judjeCurrency() {
        if (this.FinanceCurrency.length == 0) {
          this.getFinanceCurrency();
        }
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.currency_rules = {
          name: [ //交易币种名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ],
          currency_symbol: [{
            required: true, //是否必填
            message: '请输入币种符号', //该规则错误信息提示
            trigger: 'blur', //哪种事件触发(blur为失去焦点事件)
          }, ]
        }
      },
      /* 取消增加或修改*/
      cancleFinanceCurrency() {
        this.show_addBox = false;
        this.$refs['currency_ruleform'].resetFields(); //移除表单验证规则
      },
      /* 点击修改按钮触发*/
      updateCurrency(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true;
        this.operateCurrency = 1;
        this.save_financeCurrency.name = data.name;
        this.save_financeCurrency.currency_symbol = data.currency_symbol; //币种符号
        this.save_financeCurrency.remark = data.remark;
        this.save_financeCurrency.is_enable = data.is_enable == 1 ? true : false;
        this.save_financeCurrency.id = data.id;
        this.sourceData = JSON.parse(JSON.stringify(this.save_financeCurrency)) //将源数据储存
      },
      /* 获取币种数据 */
      getFinanceCurrency(type) {
        this.loading = this.commonJsExtend.customLoading("#currencyTable", 3);
        api.financeDict.getFinanceCurrency().then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) {
            this.FINANCE_CURRENCY(res.data);
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '数据排序成功',
                duration: this.elDuration
              })
            }
          }
        })
      },
      /* 增加修改方法*/
      saveCurrency() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['currency_ruleform'].validate(valid => {
          if (valid) {
            //判断是新增还是修改
            if (this.operateCurrency == 0) {
              var data = {
                name: this.save_financeCurrency.name,
                currency_symbol: this.save_financeCurrency.currency_symbol,
                exchange_rate: this.save_financeCurrency.exchange_rate,
                is_enable: this.save_financeCurrency.is_enable ? 1 : 0,
                remark: this.save_financeCurrency.remark,
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#currencyTable", 0);
              //发送请求
              api.financeDict.addCurrency(data).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getFinanceCurrency(1); //重新加载数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                //关闭弹窗
                this.show_addBox = false;
              })
            } else if (this.operateCurrency == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.save_financeCurrency, '交易币种');
              var data = {
                name: this.save_financeCurrency.old_name,
                id: this.save_financeCurrency.id,
                new_name: this.save_financeCurrency.name,
                remark: this.save_financeCurrency.remark,
                currency_symbol: this.save_financeCurrency.currency_symbol,
                exchange_rate: this.save_financeCurrency.exchange_rate,
                is_enable: this.save_financeCurrency.is_enable ? 1 : 0,
                logContent//日志
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#currencyTable", 1);
              //发送请求
              api.financeDict.updateCrmCurrency(data).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getFinanceCurrency(2);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              this.show_addBox = false;
            }
          }
        })
      },
      /* 删除数据*/
      deleteFinanceCurrency(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#currencyTable", 2);
          //发送请求
          api.financeDict.deleteCrmCurrency({
            id: data.id, //交易币种id
            name: data.name //交易币种名称
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              this.getFinanceCurrency(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },
      /* 拖动排序*/
      changeSortNum(newVal) { //拖动排序
        if (this.FinanceTabIndex == "3.3" && this.DictionaryLeftIndex == 3) {
          let data = {
            sourceId: this.FinanceCurrency[newVal.oldIndex].id,
            targetId: this.FinanceCurrency[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#currencyTable", 1);
          api.financeDict.updateSysCostCurrencySort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getFinanceCurrency(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    }
  }
</script>

<style>
</style>
