<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：仓储相关单据类型请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addMode">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="typeDocumentTable" row-key="id" :data="DypeofDocument" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateMode(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteDypeofDocument(scope.row.id)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_system==1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="120"></el-table-column>
        <el-table-column prop="warehouse_sign" label="出入库标识" min-width="120">
          <template slot-scope="scope">
            <span>{{scope.row.warehouse_sign==1?'入库':'出库'}}</span>
          </template>

        </el-table-column>
        </el-table-column>
      </el-table>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operatedState == 0 ?'新增':'修改') + '单据类型'" :visible.sync="show_typeDocument" width="400px"
      :before-close="cancleTypeDocument" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <!--   <div>
          <el-button type="primary" v-show="out" @click="addCompanyAccount" size="small">出库</el-button>
          <el-button type="primary" v-show="In" @click="addPersonalAccount" size="small">入库</el-button>
        </div> -->
        <template>
          <el-radio v-model="inORout" v-show="out" label="0">出库</el-radio>
          <el-radio v-model="inORout" v-show="In" label="1">入库</el-radio>
        </template>
        <el-form :model="saveDypeofDocument" :rules="DypeofDocument_rules" ref="DypeofDocument_ruleform"
          label-width="80px" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="saveDypeofDocument.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="saveDypeofDocument.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="saveDypeofDocument.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleTypeDocument">取 消</el-button>
        <el-button size="small" type="primary" @click="saveMode">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: 'storage_DypeofDocument',
    data() {
      return {
        In: true, //入库按钮显示
        out: true, //出库按钮显示
        inORout: '0', //判断是出库还是入库
        update: '0', //判断修改出库还是入库
        saveDypeofDocument: { //编辑框的值
          name: '',
          is_enable: '',
          is_system: '',
          remark: '',
          old_name: '',
          id: '',
        },
        DypeofDocument_rules: [], //表单验证
        //控制开关
        show_typeDocument: false,
        //判断条件
        operatedState: 0,
        //加载loading
        loading: '',
      }
    },
    watch: {
      //监听仓库相关数据字典选项卡下标
      StorageTabIndex(newVal) {
        if (newVal == "2.5") {
          this.judjeMode()
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        DypeofDocument: state => state.system.DypeofDocument, //单据类型数据
        StorageTabIndex: state => state.system.StorageTabIndex, //点击单据类型下标
        SortIndex: state => state.system.SortIndex, //排序下标
      })
    },
    created() {
      //初始化表单验证
      this.initRules();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#typeDocumentTable>.el-table__body-wrapper > table > tbody");
    },
    methods: {
      ...mapMutations([
        'DYPEOFDOCUMENT'
      ]),

      /* 点击新增按钮触发*/
      addMode() {
        this.show_typeDocument = true; //显示增加修改弹窗
        this.In = true; //入库按钮显示
        this.out = true; //出库按钮显示
        this.saveDypeofDocument = {
          name: '',
          is_enable: true,
          is_system: '',
          remark: '',
          id: '',
        };
        this.operatedState = 0;
      },
      /* 控制入库按钮*/
      /*  addR() {
          this.inORout = 1;
        }, */
      /* 控制出库按钮*/
      /* addC() {
         this.inORout = 0;
       }, */
      judjeMode() {
        if (this.DypeofDocument.length == 0) {
          this.getDypeofDocument();
        }
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.DypeofDocument_rules = {
          name: [ //单据类型名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 查询单据类型*/
      getDypeofDocument(type) {
        //加载loading
        this.loading = this.commonJsExtend.customLoading("#typeDocumentTable", 3);
        //发送请求
        api.storageDict.findAllDocumentType().then(res => {
          if (res.code == 200) {
            this.DYPEOFDOCUMENT(res.data); //把查询的数据放入vuex中
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '数据排序成功',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭新增输入框
          }
        })
      },
      /* 删除数据*/
      deleteDypeofDocument(id) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提升", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#typeDocumentTable", 2);
          //发送请求
          api.storageDict.deleteDocumentType({
            id: id
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              this.getDypeofDocument(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },
      /*修改数据*/
      updateMode(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        if (data.warehouse_sign == 1) { //入库
          this.In = false; //不显示入库按钮
          this.out = false; //不显示出库按钮
          this.update = 1;
          this.operatedState = 1; //设置当前状态为修改
          this.show_typeDocument = true; //显示编辑框
          this.saveDypeofDocument.name = data.name; //将编辑信息赋值到编辑框
          this.saveDypeofDocument.remark = data.remark;
          this.saveDypeofDocument.is_enable = data.is_enable == 1 ? true : false;
          this.saveDypeofDocument.old_name = data.name;
          this.saveDypeofDocument.id = data.id;
        }
        if (data.warehouse_sign == 0) { //出库
          this.In = false; //不显示入库按钮
          this.out = false; //不显示出库按钮
          this.update = 0;
          this.operatedState = 1; //设置当前状态为修改
          this.show_typeDocument = true; //显示编辑框
          this.saveDypeofDocument.name = data.name; //将编辑信息赋值到编辑框
          this.saveDypeofDocument.remark = data.remark;
          this.saveDypeofDocument.is_enable = data.is_enable == 1 ? true : false;
          this.saveDypeofDocument.old_name = data.name;
          this.saveDypeofDocument.id = data.id;
        }
      },

      /* 取消增加或者修改表单验证*/
      cancleTypeDocument() {
        this.$refs['DypeofDocument_ruleform'].resetFields(); //移除表单验证规则
        this.show_typeDocument = false;
      },
      /*保存数据*/
      saveMode() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['DypeofDocument_ruleform'].validate(valid => {
          if (valid) {
            //判断是新增还是修改
            if (this.operatedState == 0) { //新增
              console.log(this.inORout);
              if (this.inORout == 0) { //出库
                var data = {
                  name: this.saveDypeofDocument.name,
                  is_enable: this.saveDypeofDocument.is_enable ? 1 : 0,
                  is_system: '0',
                  remark: this.saveDypeofDocument.remark,
                  warehouse_sign: '0',//出库
                  consult_sign:'0',//内部流转标识
                }
              }
              if (this.inORout == 1) { //入库
                var data = {
                  name: this.saveDypeofDocument.name,
                  is_enable: this.saveDypeofDocument.is_enable ? 1 : 0,
                  is_system: '0',
                  remark: this.saveDypeofDocument.remark,
                  warehouse_sign: '1', //入库
                  consult_sign:'0',//内部流转标识
                }
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#typeDocumentTable", 0);
              //发送请求
              api.storageDict.addDocumentType(data).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getDypeofDocument(1); //重新加载数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                //关闭弹窗
                this.show_typeDocument = false;
              })
            } else if (this.operatedState == 1) { //修改
              if (this.update == 1) { //入库
                var datainfo = {
                  name: this.saveDypeofDocument.old_name,
                  id: this.saveDypeofDocument.id,
                  new_name: this.saveDypeofDocument.name,
                  remark: this.saveDypeofDocument.remark,
                  is_enable: this.saveDypeofDocument.is_enable ? 1 : 0,
                  is_system: '0',
                  warehouse_sign: '1',
                }
              }
              if (this.update == 0) { //出库
                var datainfo = {
                  name: this.saveDypeofDocument.old_name,
                  id: this.saveDypeofDocument.id,
                  new_name: this.saveDypeofDocument.name,
                  remark: this.saveDypeofDocument.remark,
                  is_enable: this.saveDypeofDocument.is_enable ? 1 : 0,
                  is_system: '0',
                  warehouse_sign: '0',
                }
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#typeDocumentTable", 1);
              //发送请求
              api.storageDict.updateDocumentType(datainfo).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getDypeofDocument(2);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              this.show_typeDocument = false;
            }
          }
        })
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.StorageTabIndex == "2.5" && this.DictionaryLeftIndex == 2) {
          let data = {
            sourceId: this.DypeofDocument[newVal.oldIndex].id,
            targetId: this.DypeofDocument[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#typeDocumentTable", 1);
          api.storageDict.updateSysDepotDocumentTypeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getDypeofDocument(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
  }
</script>
