// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：第三方.企业微信相关接口
// 开始时间：2021-03-13
// 开发人员：刘巍骏
// 最后修改：2021-03-13
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  // 客户分类相关
  doSyncTagsToWeixin(data) { //客户分类全部同步企业微信标签
    return request({
      method: 'POST',
      url: '/weixin/doSyncTagsToWeixin',
      data: data,
    })
  },
  doAddTag(data) { //客户分类添加企业微信标签
    return request({
      method: 'POST',
      url: '/weixin/doAddTag',
      data: data,
    })
  },
  doDeleteTag(data) { //删除企业微信标签
    return request({
      method: 'POST',
      url: '/weixin/doDeleteTag',
      data: data,
    })
  },
  doUpdateTag(data) { //修改企业微信标签
    return request({
      method: 'POST',
      url: '/weixin/doUpdateTag',
      data: data,
    })
  },
}
