<template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="SupplierTabIndex" v-if="isPower">
      <el-tab-pane label="分类管理" name="5.1">
        <supplierType></supplierType>
      </el-tab-pane>
      <el-tab-pane label="会员等级" name="5.2">
        <supplierGrade></supplierGrade>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入组件
  import supplierType from './supplierItem/supplier_type.vue'; //分类管理
  import supplierGrade from './supplierItem/supplier_grade.vue'; //会员等级
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  export default {
    name: 'dictonary_supplier',
    data() {
      return {
        SupplierTabIndex: '5.1',
        isPower: false, //是否有查询权限
      }
    },
    watch: {
      SupplierTabIndex(newVal) {
        this.SUPPLIER_TAB_INDEX(newVal)
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
    },
    methods: {
      ...mapMutations([
        'SUPPLIER_TAB_INDEX',
      ]),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),
    },
    components: {
      supplierType,
      supplierGrade,
    }
  }
</script>

<style>
</style>
