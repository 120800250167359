<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关计量单位请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addUnit">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="unitTable" row-key="id" :data="ProductUnit" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateUnit(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteProductUnit(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="单位名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system == 1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>

    <!-- 新增计量单位弹框 -->
    <el-dialog :title="(operateUnit == 0 ?'新增':'修改') + '计量单位'" :visible.sync="show_addUNitBox"
      :before-close="cancleUnit" width="400px" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="sava_unit" :rules="Unit_rules" ref="Unit_from" size="mini" label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="sava_unit.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="sava_unit.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="sava_unit.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleUnit">取 消</el-button>
        <el-button size="small" type="primary" @click="saveUnit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'; //数据字典相关方法

  export default {
    name: "product_unit",
    data() {
      return {
        //计量单位数据
        sava_unit: { //新增框里面的数据
          name: '', //计量名称
          old_name: '', //计量名称(修改时用的旧名称)
          is_enable: '', //是否启用
          remark: '', //备注
        },
        sourceData: {},//定义编辑源数据
        Unit_rules: [], //增加修改表单验证

        // 控制开关
        show_addUNitBox: false, //控制增加修改弹窗开关

        //判断条件
        operateUnit: 0, //判断是新增计量还是修改计量

        //loading加载
        loading: '',
      }
    },
    created() {
      this.initRules(); //初始话表单验证规则
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#unitTable>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProductUnit: state => state.system.ProductUnit, //计量单位属性
        ProductTabIndex: state => state.system.ProductTabIndex, //鼠标点击下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //监听货品相关数据字典选项卡下标
      ProductTabIndex(newVal) {
        //判断下标是否为计量单位
        if (newVal == "6.2") {
          //判断vuex是否存在数据
          this.juejeUnit();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCT_UNIT', //存放计量单位数据到vuex
        'SHOW_PRODUCTTYPEBOX', //控制货品分类弹框显示
      ]),
      /* 判断vuex是否有多计量数据 */
      juejeUnit() {
        if (this.ProductUnit.length == 0) {
          this.getProductUnit();
        }
      },

      /* 点击新增触发*/
      addUnit() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        //初始化默认信息
        this.sava_unit = {
          name: '',
          is_enable: true,
          remark: '',
        };
        //显示弹框
        this.show_addUNitBox = true;
        //设置类型为新增
        this.operateUnit = 0;
      },

      /* 获取计量单位数据*/
      getProductUnit(type) {
        this.loading = this.commonJsExtend.customLoading("#unitTable", 3);
        api.productDict.getProductUnit().then(res => {
          this.loading.close(); //关闭loading框
          if (res.data.length > 0) {
            this.PRODUCT_UNIT(res.data);
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: 1500
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: 1500
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: 1500
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功',
                duration: 1500
              })
            }
          }
        })
      },

      /* 删除数据*/
      deleteProductUnit(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(data);
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#unitTable", 3);
          api.productDict.deleteProductUnit({
            id: data.id, //计量单位id
            name: data.name //计量单位名称
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              this.getProductUnit(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: 1500
          });
        });
      },

      /* 修改数据 */
      updateUnit(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addUNitBox = true; //显示编辑框
        this.sava_unit.id = data.id;
        this.sava_unit.name = data.name;
        this.sava_unit.old_name = data.name;
        this.sava_unit.is_enable = data.is_enable == 1 ? true : false;
        this.sava_unit.remark = data.remark;
        this.operateUnit = 1; //设置当前状态为修改
        this.sourceData = JSON.parse(JSON.stringify(this.sava_unit)) //将源数据储存
      },

      /* 取消增加修改框*/
      cancleUnit() {
        this.show_addUNitBox = false;
        this.$refs['Unit_from'].resetFields(); //移除表单验证规则
      },

      /* 初始话表单验证规则*/
      initRules() {
        this.Unit_rules = {
          name: [ //品牌名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 保存数据*/
      saveUnit() {
        this.$refs['Unit_from'].validate(valid => {
          if (valid) {
            //判断是修改还是新增
            if (this.operateUnit == 0) {
              console.log(this.sava_unit.remark);
              let data = {
                name: this.sava_unit.name,
                remark: this.sava_unit.remark,
                is_enable: this.sava_unit.is_enable ? 1 : 0,
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#unitTable", 3);
              //发送请求
              api.productDict.addProductUnit(data).then((res) => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getProductUnit(1) //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: '数据添加失败!',
                    duration: 1500
                  })
                }
                this.show_addUNitBox = false; //关闭弹窗
              })
            } else if (this.operateUnit == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.sava_unit, '计量单位');
              var data = {
                name: this.sava_unit.old_name,
                new_name: this.sava_unit.name,
                id: this.sava_unit.id,
                is_enable: this.sava_unit.is_enable ? 1 : 0,
                remark: this.sava_unit.remark,
                logContent //日志
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#unitTable", 1);
              api.productDict.updateProductUnit(data).then(res => {
                this.loading.close(); //关闭loading
                if (res.code == 200) {
                  this.getProductUnit(2) //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: '数据修改失败!',
                    duration: 1500
                  })
                }
                this.show_addUNitBox = false; //关闭弹窗
              });
            }
          }
        })
      },

      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.2" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.ProductUnit[newVal.oldIndex].id,
            targetId: this.ProductUnit[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#unitTable", 1);
          api.productDict.updateSysProductUnitSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getProductUnit(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
  }
</script>

<style lang="less">
  /* 多计量设置框 */
  .moreUnitBox {

    /* 单个计量*/
    .itemUnit {
      display: flex;
      align-items: center;
      padding: 10px 0;
      // border: 1px solid black;

      // 标题
      .title {
        display: block;
        width: 89px;
        padding-right: 21px;
        font-size: 14px;
        text-align: right;
        font-weight: bold;
        // border: 1px solid black;
      }

      //数量框
      .input_num {
        width: 90px;
        margin-right: 10px;
      }

      //单位框
      .input_unit {
        width: 90px;
        margin-right: 10px;
      }

      //分隔符
      .input_split {
        padding: 0 11.5px;
      }
    }

    /* 指定计量单位设置 */
    .assignUnit {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 50%;
          padding: 5px 0;
          display: flex;
          // border: 1px solid black;

          // 标题
          .title {
            display: block;
            width: 100px;
            padding-right: 10px;
            font-size: 14px;
            font-weight: bold;
            // border: 1px solid black;
          }

          // 值
          .input_unit {
            width: 90px;
          }
        }
      }
    }
  }
</style>
