<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary"  @click="addProcess">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="processTable" :data="processData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="45"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateProcess(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteCustomerProcess(scope.row)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="color" label="颜色" width="120"></el-table-column>
        <el-table-column prop="zt" label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.zt" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!--修改销售进程弹窗 -->
    <el-dialog :title="(operateProcess == 0 ?'新增':'修改') + '销售进程'" :visible.sync="show_addBox" width="400px"
      :before-close="close" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="sellprocess_rules" ref="sellprocess_ruleform" :model="processStr" size="mini"
          label-width="80px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="processStr.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="saveProcess">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: 'customer_sellprocess',
    data() {
      return {
        //当前页面数据
        processData: [],
        processStr: {
          name:''
        },
        //定义编辑源数据
        sourceData: {},
        processEidt: {},
        //控制开关
        show_addBox: false,
        //判断条件
        operateProcess: 0,
      }
    },
    computed: {
      ...mapState({
        CustomerProcess: state => state.system.CustomerProcess,
        CustomerTabIndex: state => state.system.CustomerTabIndex, //客户数据字典选中下标
      })
    },
    watch: {
      //监听客户相关数据字典选项卡下标
      CustomerTabIndex(newVal) {
        //判断下标是否为销售进程
        if (newVal == 0.5) {
          //判断vuex是否存在数据
          this.judjeProcess();
        }
      }
    },
    created() {
      this.initRules();
    },
    methods: {
      ...mapMutations([
        'CUSTOMER_PROCESS' //获取销售进程
      ]),
      ...mapActions([
        'getCustomerSell' //获取销售进程
      ]),
      /* 取消新增输入框*/
      close() {
        this.processStr = {
            name: ''
          },
          this.show_addBox = false;
        this.$refs['sellprocess_ruleform'].resetFields(); //移除表单验证规则
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.sellprocess_rules = {
          name: [ //销售进程名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },
      judjeProcess() {
        if (this.CustomerProcess.length == 0) {
          this.getCustomerProcess();
        }
      },
      /*获取销售进程数据*/
      getCustomerProcess(type) {
        //加载loading
        this.loading = this.commonJsExtend.customLoading("#processTable", 3);
        api.customerDict.getCustomerSell().then(res => {
          if (res.code == 200) {
            this.CUSTOMER_PROCESS(res.data);
            this.processData = [];
            this.processData = JSON.parse(JSON.stringify(res.data));
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭新增输入框
          }
        })
      },
      /* 新增*/
      addProcess() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.show_addBox = true;
        this.operateProcess = 0
      },
      /* 删除数据*/
      deleteCustomerProcess(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // console.log(id);
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#processTable", 2);
          api.customerDict.deleteCustomerSell({
            processid: data.id,//销售进程id
            name:data.name//销售进程名称
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              this.getCustomerProcess(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },
      /* 修改数据 */
      updateProcess(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true; //显示编辑框
        this.processStr.name = data.name; //将编辑信息赋值到编辑框
        this.sourceData = JSON.parse(JSON.stringify(this.processStr)) //将源数据储存
        this.processEidt = data; //存入当前编辑信息
        this.operateProcess = 1; //设置当前状态为修改
      },
      /* 保存数据*/
      saveProcess() {
        this.$refs['sellprocess_ruleform'].validate(valid => {
          if (valid) { //判断是修改还是新增
            if (this.operateProcess == 0) {
              var data = {
                verifystr: this.processStr.name //新增状态名称
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#processTable", 0);
              //发送请求
              api.customerDict.addCustomerSell(data).then((res) => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.processStr = {
                    name: ''
                  } //清空输入框
                  this.getCustomerProcess(1) //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.operateProcess == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.processStr, '销售进程');
              var data = {
                old_name: this.processEidt.name,
                processid: this.processEidt.id,
                verifystr: this.processStr.name,
                logContent//日志
              }
              console.log(data);
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#processTable", 1);
              api.customerDict.updateCustomerSell(data).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.processStr = {
                    name: ''
                  }; //清空输入框
                  this.getCustomerProcess(2) //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });

            }
          }
        })


      },
    }
  }
</script>

<style>
</style>
