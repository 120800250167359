<template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="FinanceTabIndex" v-if="isPower">
      <el-tab-pane label="收款类型" name="3.1">
        <paymentType v-if="FinanceTabIndex == '3.1'"></paymentType>
      </el-tab-pane>
      <el-tab-pane label="收款帐号" name="3.2">
        <paymentAccount v-if="FinanceTabIndex == '3.2'"></paymentAccount>
      </el-tab-pane>
      <el-tab-pane label="交易币种" name="3.3">
        <currency v-if="FinanceTabIndex == '3.3'"></currency>
      </el-tab-pane>
      <el-tab-pane label="会计科目" name="3.4">
        <expenseClassfi v-if="FinanceTabIndex == '3.4'"></expenseClassfi>
      </el-tab-pane>
      <!--      <el-tab-pane label="发票类型" name="3.5">
        <costInvoiceType></costInvoiceType>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapActions,
    mapState
  } from 'vuex'
  // 导入组件
  import paymentType from './financeItem/finance_paymentType.vue'; //收款类型
  import paymentAccount from './financeItem/finance_paymentAccount.vue'; //收款帐号
  import currency from './financeItem/finance_currency.vue'; //交易币种
  import expenseClassfi from './financeItem/finance_expenseClassfi.vue'; //费用分类
  import costInvoiceType from './financeItem/finance_costType.vue'; //发票类型
  export default {
    name: 'dictonary_finance',
    data() {
      return {
        FinanceTabIndex: '3.1',
        isPower: false, //是否有查询权限
      }
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
        // this.getSysCostBankInfo() //收款账号
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    watch: {
      /* 监听下标改变*/
      FinanceTabIndex(newVal) {
        this.FINANCE_TAB_INDEX(newVal);
      }
    },
    components: {
      paymentType,
      paymentAccount,
      currency,
      expenseClassfi,
      costInvoiceType
    },
    methods: {
      ...mapMutations([
        'FINANCE_TAB_INDEX', //下标方法
      ]),
      ...mapActions([
        'getSysCostBankInfo', //获取银行账号数据
        'getUserPowerInfo', //获取用户操作权限
      ]),
    }
  }
</script>
