<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：财务相关收款类型请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="add_showbox" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="PaymentType" row-key="id"  height="100%" id="payment_table" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="update_showbox(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deletePaymentType(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="200"></el-table-column>
        <el-table-column prop="assign_currency" label="指定货币"   width="200"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="系统默认" width="200">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system == 1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>
    <!--修改货品来源弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '收款类型'" :visible.sync="showbox" width="400px"
      :before-close="canclePaymenttype" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form ref="paymentType_form" :model="addpaymenttype" :rules="paymentType_rules" size="mini"
          label-width="90px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="addpaymenttype.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="指定货币:"  prop="assign_currency">
                <el-select v-model="addpaymenttype.assign_currency" style="width: 100%" placeholder="请选择">
                  <el-option v-for="c in FinanceCurrency " :label="c.name" :value="c.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:" prop="is_enable">
                <el-checkbox v-model="addpaymenttype.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="addpaymenttype.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="canclePaymenttype">取 消</el-button>
        <el-button size="small" type="primary" @click="savePaymentType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'
  export default {

    name: 'finance_paymentType',
    data() {
      return {
        paymentType_rules: [], //表单验证规则
        showbox: false, //弹窗控制开关
        operateState: 0, //0:增加 1：修改
        addpaymenttype: { //增加框里面的数据
          name: '',
          is_system: '0',
          is_enable: '',
          remark: '',
          assign_currency: '',
          id: '',
        },
        //定义编辑源数据
        sourceData: {},
        old_name: '', //修改之前的name

        //loading框
        loading: '', //控制显示loading框
      }
    },
    created() {
      //初始化表单验证规则
      this.initRules();
      //判断交易币种是否存在
      this.judgeCurrency();
      this.judjePaymentType();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#payment_table>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        PaymentType: state => state.system.PaymentType, //收款类型属性
        FinanceCurrency: state => state.system.FinanceCurrency, //货币类型
        FinanceTabIndex: state => state.system.FinanceTabIndex, //下标属性
        SortIndex: state => state.system.SortIndex, //排序下标
      })
    },
    watch: {
      /* 监听鼠标点击下标*/
      FinanceTabIndex(newVal) {
        if (newVal == "3.1") {
          this.judjePaymentType();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
          handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'FINANCE_PAYMENTTYPE', //把收款信息放入vuex
        'FINANCE_CURRENCY', //把币种信息放入vuex
      ]),
      ...mapActions([
        'getFinanceCurrency', //获取交易币种数据
      ]),
      /* 取消新增或修改操作 */
      canclePaymenttype() {
        this.$refs['paymentType_form'].resetFields(); //移除表单验证规则
        this.showbox = false;
      },
      /* 判断交易币种是否存在vuex*/
      judgeCurrency() {
        if (this.FinanceCurrency.length == 0) {
          this.getFinanceCurrency();
        }
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.paymentType_rules = {
          name: [ //收款类型规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ],
          assign_currency: [{
            required: true,
            message: '请至少选择一个货币',
            trigger: 'change'
          }]
        }
      },
      /* 打开增加弹窗*/
      add_showbox() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.operateState = 0;
        this.addpaymenttype = {
          name: '',
          is_system: '0',
          is_enable: true,
          remark: '',
          assign_currency: '',
          id: '',
        };
        this.showbox = true;
      },
      /* 打开修改弹窗*/
      update_showbox(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.operateState = 1;
        this.addpaymenttype.id = data.id;
        this.addpaymenttype.assign_currency = data.assign_currency;
        this.addpaymenttype.name = data.name;
        this.addpaymenttype.is_enable = data.is_enable == 1 ? true : false;
        this.addpaymenttype.remark = data.remark;
        this.old_name = data.name;
        this.sourceData = JSON.parse(JSON.stringify(this.addpaymenttype)) //将源数据储存
        this.showbox = true;
      },
      /* 查询PaymentType里面是否有数据 */
      judjePaymentType() {
        if (this.PaymentType.length == 0) {
          this.findAllPaymentType();
        }
      },

      /* 查询收款类型*/
      findAllPaymentType(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#payment_table", 3);
        api.financeDict.findAllPaymentType().then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) {
            //给PaymentType赋值
            this.FINANCE_PAYMENTTYPE(res.data);
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }

          }
        })
      },
      /* 删除收款类型*/
      deletePaymentType(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#payment_table", 2);
          //发送请求
          api.financeDict.deletePaymentType({
            id: data.id,//收款类型id
            name:data.name//收款类型名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.findAllPaymentType(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        })
      },
      /* 保存修改或增加*/
      savePaymentType() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['paymentType_form'].validate(valid => {
          if (valid) { // 判断是新增还是修改
            if (this.operateState == 0) {
              let data = {
                code: this.addpaymenttype.code,
                remark: this.addpaymenttype.remark,
                is_enable: this.addpaymenttype.is_enable ? 1 : 0,
                is_system: this.addpaymenttype.is_system,
                assign_currency: this.addpaymenttype.assign_currency,
                name: this.addpaymenttype.name,
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#payment_table", 0);
              //发送请求
              api.financeDict.addPaymentType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllPaymentType(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.showbox = false;
              })
            }
            /* 修改*/
            else if (this.operateState == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.addpaymenttype, '收款类型');
              //修改数据
              var data = {
                name: this.old_name,
                code: this.addpaymenttype.code,
                remark: this.addpaymenttype.remark,
                is_enable: this.addpaymenttype.is_enable ? 1 : 0,
                is_system: this.addpaymenttype.is_system,
                id: this.addpaymenttype.id,
                new_name: this.addpaymenttype.name,
                assign_currency: this.addpaymenttype.assign_currency,
                logContent//日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#payment_table", 1);
              //发送请求
              api.financeDict.updatePaymentType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllPaymentType(2); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.showbox = false;
              });
            }
          }
        })
        this.loading.close(); //关闭loading框
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.FinanceTabIndex == "3.1" && this.DictionaryLeftIndex == 3) {
          let data = {
            sourceId: this.PaymentType[newVal.oldIndex].id,
            targetId: this.PaymentType[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#payment_table", 1);
          api.financeDict.updateSysCostDocumentTypeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllPaymentType(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
  }
</script>

<style>
</style>
