<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：订单相关收汇方式请求接口
开始时间：2021-05-05
开发人员：万攀
最后修改：2021-05-05
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="add_box">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="payment_mode" row-key="id" ref="singleTable" :data="PaymentMode" height="100%" border
        style="width: 100%">
        <!--  拖拽图标-->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updatePaymentMode(scope.row)" title="修改"></i>
            <i v-show="scope.row.is_system != 1" class="el-icon-delete icon" @click="deletePaymentMode(scope.row)"
              title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="200"></el-table-column>
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1?true:false" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="系统默认" width="80">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system==1?true:false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>
    <!--修改客户分类弹窗 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '采购类型'" :visible.sync="show_addBox" width="400px"
      :before-close="close" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="from_rules" ref="from_rules" :model="PaymentEidt" size="mini" label-width="80px">

          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="PaymentEidt.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="启用:">
                <el-checkbox v-model="PaymentEidt.is_enable"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注:">
                <el-input type="textarea" :rows="2" v-model.trim="PaymentEidt.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: 'payment_mode',
    data() {
      return {
        loading: '', //loading框
        show_addBox: false, //控制新增弹框
        PaymentEidt: { //新增和编辑表单信息
          id: '',
          oldname: '',
          name: '',
          is_enable: true,
          remark: '',
        },
        //定义编辑源数据
        sourceData: {},
        from_rules: {},
        operateState: 0, //判断操作是新增还是删除
      }

    },
    created() {
      this.initRules() //初始化表单验证
    },
    mounted() {
      this.commonJsExtend.customDragrow("#payment_mode>.el-table__body-wrapper > table > tbody");
    },
    watch: {
      //监听销售相关数据字典选项卡下标
      SalesTabIndex(newVal) {
        //判断下标是否为报价类型
        if (newVal == "1.6") {
          //判断vuex是否存在数据
          this.judjePurchaseType();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          console.log(newVal);
          this.changeSortNum(newVal)
        }
      },
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        PaymentMode: state => state.system.PaymentMode, //报价类型数据
        SalesTabIndex: state => state.system.SalesTabIndex, //订单相关字典选中下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    methods: {
      ...mapMutations([
        'PAYMENTMODE', //存放跟进方式到vuex
      ]),
      /* Vuex无数据发起请求 */
      judjePurchaseType() {
        if (this.PaymentMode.length == 0) {
          this.getPaymentMode()
        }
      },
      /* 查询价格条款*/
      getPaymentMode(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#payment_mode", 3);
        // 发送请求
        api.sales.getPaymentMode().then(res => {
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.PAYMENTMODE(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭新增输入框
          }
        });
      },
      /* 显示修改弹框 */
      updatePaymentMode(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true
        this.operateState = 1 //修改操作判断为1
        this.PaymentEidt.id = data.id
        this.PaymentEidt.oldname = data.name
        this.PaymentEidt.name = data.name
        this.PaymentEidt.remark = data.remark
        this.PaymentEidt.is_enable = data.is_enable == 1 ? true : false
        this.sourceData = JSON.parse(JSON.stringify(this.PaymentEidt)) //将源数据储存
      },
      /* 保存数据 */
      saveData() {
        this.$refs['from_rules'].validate(valid => {
          if (valid) {
            if (this.operateState == 0) {
              var data = {
                name: this.PaymentEidt.name, //名称
                is_enable: this.PaymentEidt.is_enable ? 1 : 0,
                remark: this.PaymentEidt.remark, //备注
              }
              api.sales.addPaymentMode(data).then(res => {
                if (res.code == 200) {
                  this.getPaymentMode(1)
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.PaymentEidt, '收汇方式')
              var data = {
                id: this.PaymentEidt.id,
                name: this.PaymentEidt.oldname, //名称
                new_name: this.PaymentEidt.name, //名称
                is_enable: this.PaymentEidt.is_enable ? 1 : 0,
                remark: this.PaymentEidt.remark, //备注
                logContent//日志
              }
              api.sales.updatePaymentMode(data).then(res => {
                if (res.code == 200) {
                  this.getPaymentMode(2)
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          }
        })
        this.close()
      },
      /* 删除收汇方式 */
      deletePaymentMode(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#payment_mode", 2);
          //发送删除请求
          api.sales.delectPaymentMode({
            id: data.id, //收汇方式id
            name: data.name //收汇方式名称
          }).then(res => {
            if (res.code == 200) {
              this.getPaymentMode(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
            this.loading.close(); //关闭加载框
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
          this.loading.close(); //关闭加载框
        });
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.from_rules = {
          name: [ //报价类型名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },
      /* 改变排序方法*/
      changeSortNum(newVal) {
        if (this.SalesTabIndex == "1.6" && this.DictionaryLeftIndex == '1') {
          let data = {
            sourceId: this.PaymentMode[newVal.oldIndex].id,
            targetId: this.PaymentMode[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#payment_mode", 1);
          api.sales.updatePaymentModeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getPaymentMode(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }

      },
      /* 显示新增盒子 */
      add_box() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.operateState = 0
        this.show_addBox = true
      },
      close() {
        this.$refs['from_rules'].resetFields(); //移除表单验证规则
        for (let i in this.PaymentEidt) {
          if (typeof this.PaymentEidt[i] == 'string') {
            this.PaymentEidt[i] = ''
          } else {
            this.PaymentEidt[i] = true
          }

        }
        this.show_addBox = false
      }
    },
  }
</script>

<style>
</style>
