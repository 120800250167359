  <template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="productTabIndex" v-if="isPower">
      <el-tab-pane label="货品分类" name="6.1">
        <productType></productType>
      </el-tab-pane>
      <el-tab-pane label="计量单位" name="6.2">
        <productUnit></productUnit>
      </el-tab-pane>
      <el-tab-pane label="多计量公式" name="6.7">
        <productMoreUnit></productMoreUnit>
      </el-tab-pane>
      <el-tab-pane label="品牌管理" name="6.3">
        <productBrand></productBrand>
      </el-tab-pane>
      <el-tab-pane label="货品属性" name="6.4">
        <productAttribute></productAttribute>
      </el-tab-pane>
      <el-tab-pane label="货品来源" name="6.5">
        <productSource></productSource>
      </el-tab-pane>
      <el-tab-pane label="货品规格" name="6.6">
        <productSpecification></productSpecification>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  // 导入组件
  import productType from './productItem/product_type.vue';//货品分类
  import productUnit from './productItem/product_unit.vue';//计量单位
  import productMoreUnit from './productItem/product_moreunit.vue';//多计量单位
  import productBrand from './productItem/product_brand.vue';//品牌管理
  import productAttribute from './productItem/product_attribute.vue';//货品属性
  import productSource from './productItem/product_source.vue';//货品来源
  import productSpecification from './productItem/product_specification.vue';//货品规格

  export default{
    name:'dictonary_product',
    data(){
      return{
        productTabIndex:'6.1',
        isPower: false, //是否有查询权限
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
    },
    watch:{
      productTabIndex(newVal){
        this.PRODUCT_TAB_INDEX(newVal)
      }
    },
    methods:{
    ...mapMutations([
      'PRODUCT_TAB_INDEX'
    ]),
    ...mapActions([
      'getUserPowerInfo', //获取用户操作权限
    ]),
    },
    components:{
      productType,
      productUnit,
      productMoreUnit,
      productBrand,
      productAttribute,
      productSource,
      productSpecification
    }
  }
</script>

<style>
</style>
