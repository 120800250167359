<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：财务相关收款账号请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->

<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addBox">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="SysCostBankInfo" id="finance_payment" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="80">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click="updatePaymentAccount(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deletePaymentAccount(scope.row)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="account_type" label="类型" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.account_type == 0">个人</span>
            <span v-if="scope.row.account_type == 1">公司</span>
            <span v-if="scope.row.account_type == 2">工具</span>
          </template>
        </el-table-column>
        <el-table-column prop="encoding" label="编码" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column label="开户名称" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="people" v-if="scope.row.account_name">{{scope.row.account_name}}</div>
            <div class="people" v-if="scope.row.company_name">{{scope.row.company_name}}</div>
          </template>
        </el-table-column>
        <el-table-column label="开户银行" prop="company_bank" sortable="" width="200"></el-table-column>
        <el-table-column label="开户账号" prop="account_num" width="180"></el-table-column>
        <!-- <el-table-column prop="initial_amount" label="初始金额" width="120"></el-table-column> -->
        <el-table-column prop="balance" label="余额" width="120"></el-table-column>
        <el-table-column prop="other_name" label="收款工具名称" width="150"></el-table-column>
        <el-table-column prop="other_account_num" label="收款工具账号" width="120">
        </el-table-column>
        <el-table-column prop="remark" label="交易明细" width="80">
          <template slot-scope="scope">
            <i class="el-icon-view" @click="showLogDialog(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="is_enable" label="状态" width="80">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_enable==1?true :false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operatedState == 0 ?'新增':'修改') + '银行账号'" style="height: 100%; "
      :visible.sync="show_paymentAccount" id="adupdate" width="800px" :before-close="canclePaymentAccount"
      append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-tabs v-model="active" type="border-card" style="min-height: 400px;">
          <el-tab-pane label="公司账号" name="0" :disabled="operatedState==1 && active != '0'">
            <!-- 公司账号 -->
            <div>
              <el-form label-width="100px" :model="save_CompanyAccount" ref="paymentAccount_ruleform"
                :rules="paymentAccount_rules" size="mini">
                <!-- 分行显示 -->
                <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="编码:" prop="encoding">
                      <el-input v-model="save_CompanyAccount.encoding" placeholder="请输入编码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="公司名称:" prop="company_name">
                      <el-input v-model="save_CompanyAccount.company_name" placeholder="请输入公司名称"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="开户银行:" prop="company_bank">
                      <el-input v-model="save_CompanyAccount.company_bank" placeholder="请输入开户银行"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开户账号:" prop="account_num">
                      <el-input v-model="save_CompanyAccount.account_num" placeholder="请输入开户账号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="公司地址:" prop="company_address">
                      <el-input v-model="save_CompanyAccount.company_address" placeholder="请输入公司地址"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="纳税编号:" prop="taxes_number">
                      <el-input v-model="save_CompanyAccount.taxes_number" placeholder="请输入纳税编号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="币种:">
                      <el-select v-model="save_CompanyAccount.currency" style="width: 100%" placeholder="请选择币种">
                        <el-option v-for="c in FinanceCurrency" :key="c.id" :label="c.name" :value="c.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="会计科目:" prop="subject">
                      <el-input class="ze_input" v-model="save_CompanyAccount.subjectName" placeholder="请选择会计科目"
                        autocomplete="off" suffix-icon="el-icon-more" @focus="judgeRelatedData"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="联系电话:" prop="company_telephone">
                      <el-input v-model="save_CompanyAccount.company_telephone" placeholder="请输入会计科目"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="初始余额:" prop="initial_amount">
                      <el-input :disabled="show_disabled" v-model="save_CompanyAccount.initial_amount"
                        placeholder="请输入初始余额"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="余额:" prop="balance">
                      <el-input disabled v-model="save_CompanyAccount.balance" placeholder="请输入余额">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="默认税率:" prop="tax_rate">
                      <el-input class="defaultTaxInput" v-model="save_CompanyAccount.tax_rate" placeholder="默认税率">
                      </el-input>
                      <span class="sign">%</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="启用:" prop="is_enable">
                      <el-checkbox v-model="save_CompanyAccount.is_enable"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="个人账号" name="1" :disabled="operatedState==1 && active != '1'">
            <!-- 个人账号 -->
            <div>
              <el-form label-width="120px" :model="save_CompanyAccount" ref="person_ruleform" :rules="peroson_rules"
                size="mini">
                <!-- 分行显示 -->
                <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="开户人姓名:" prop="account_name">
                      <el-input v-model="save_CompanyAccount.account_name" placeholder="请输入开户人姓名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开户银行:" prop="company_bank">
                      <el-input v-model="save_CompanyAccount.company_bank" placeholder="请输入开户银行"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="12">
                    <el-form-item label="开户账号:" prop="account_num">
                      <el-input v-model="save_CompanyAccount.account_num" placeholder="请输入开户账号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="编码:" prop="encoding">
                      <el-input v-model="save_CompanyAccount.encoding" placeholder="请输入编码"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="初始余额:" prop="initial_amount">
                      <el-input :disabled="show_disabled" v-model.number="save_CompanyAccount.initial_amount"
                        placeholder="初始余额">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="余额:" prop="balance">
                      <el-input v-model.number="save_CompanyAccount.balance" disabled placeholder="请输入余额">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="币种:">
                      <el-select v-model="save_CompanyAccount.currency" style="width: 100%" placeholder="请选择币种">
                        <el-option v-for="c in FinanceCurrency" :key="c.id" :label="c.name" :value="c.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="会计科目:" prop="subject">
                      <el-input class="ze_input" v-model="save_CompanyAccount.subjectName" placeholder="请选择会计科目"
                        autocomplete="off" suffix-icon="el-icon-more" @focus="judgeRelatedData">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="默认税率:" prop="tax_rate">
                      <el-input class="defaultTaxInput" v-model="save_CompanyAccount.tax_rate" placeholder="默认税率">
                      </el-input>
                      <span class="sign">%</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="启用:" prop="is_enable">
                      <el-checkbox v-model="save_CompanyAccount.is_enable"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="收款工具" name="2" :disabled="operatedState==1 && active != '2'">
            <div>
              <el-form label-width="100px" :model="save_CompanyAccount" ref="tool_ruleform" size="mini"
                :rules="other_rules">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="编码:" prop="encoding">
                      <el-input v-model="save_CompanyAccount.encoding" placeholder="请输入编码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否启用:" prop="is_enable">
                      <el-checkbox v-model="save_CompanyAccount.is_enable"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="账号名称:" prop="AccountName">
                      <el-input v-model="save_CompanyAccount.AccountName" placeholder="请输入账号名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="收款账号:" prop="toolAccount">
                      <el-input v-model="save_CompanyAccount.toolAccount"
                        oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" placeholder="请输入收款账号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="初始余额:" prop="initial_amount">
                      <el-input :disabled="show_disabled" v-model="save_CompanyAccount.initial_amount"
                        placeholder="请输入初始余额">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="余额:" prop="balance">
                      <el-input disabled v-model="save_CompanyAccount.balance" placeholder="请输入余额">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div style="margin-left: 100px;">
              <el-upload :action="Api" ref="upload" class="avatar-uploader" :show-file-list="false" :headers="headers"
                :on-success="UploadSuccess">
                <img v-if="imgUrl !='' " class="avatar" :src="AllimgUrl">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="margin-top: 10px;"><span class="mustHint">*</span>点击上传收款码图片</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="canclePaymentAccount">取 消</el-button><!--  -->
        <el-button size="small" type="primary" @click="savePaymentAccount">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 日志弹窗-->
    <el-dialog title="交易明细" :visible.sync="dialogVisible" width="55%" :before-close="colseLogDialog" append-to-body>
      <div class="paymentLogBox billing_table" id="log_tableBox">
        <el-table height="100%" :data="logData" border="">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="35"></el-table-column>
          <el-table-column property="billsNo" sortable label="单据编号" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column property="date" label="交易日期" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column property="amount" label="交易金额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- 判断收支标识 -->
              <span class="inSign" v-if="scope.row.outinSign == 1">+</span>
              <span class="outSign" v-if="scope.row.outinSign == 0">-</span>
              <span>{{scope.row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column property="balance" label="余额" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column property="operatorName" label="经办人" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column property="payer" label="交易方" width="180" show-overflow-tooltip></el-table-column>
          <el-table-column property="zhaiyao" label="摘要" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column property="up" label="借方(增加)" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column property="low" label="货方(减少)" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column property="remark" label="说明" width="120"></el-table-column>
        </el-table>
      </div>
      <!-- 表格分页 -->
      <div class="billing_tablePage">
        <el-pagination @size-change="changePageSize" @current-change="changeCurrentPage"
          :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
        </el-pagination>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="colseLogDialog">关 闭</el-button>
      </span>
    </el-dialog>


    <!-- 费用分类弹框 -->
    <costTypeTree @SelectedData="getCostTypeData"></costTypeTree>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'
  import costTypeTree from '@/components/commComponent/tree/tree_costType.vue'; //费用分类组件
  export default {
    name: 'finance_paymentAccount',
    data() {
      return {
        currecyId: '', //存放修改币种框里的币种id

        thisData: {}, //当前选中数据对象

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //boolean运算值
        showCom: true, //显示公司按钮
        showPer: true, //显示个人按钮
        operatedState: 0, //0:增加 1：修改
        show_disabled: false, //是否禁用初始余额
        active: '0', //标签页下标

        //工具上传图片
        headers: { //请求头
          token: '',
        },
        Api: baseUrl + '/system/dictionary/QRCodeUpload', //图片请求路径
        AllimgUrl: '', //完整图片路径
        imgUrl: '', //图片路径

        //弹窗
        dialogVisible: false, //日志弹框
        show_paymentAccount: false, //增加修改弹窗

        //表单验证
        paymentAccount_rules: {}, //公司表单验证
        peroson_rules: {}, //个人表单验证
        other_rules: {}, //工具表单验证规则
        personal: '', //个人账号按钮

        //定义编辑源数据
        sourceData: {},
        logData: [], //日志
        save_CompanyAccount: {}, //增加账户框里面的值
        old_account_num: '', //编辑之前的账号
      }
    },
    created() {
      this.headers.token = getCookie('token'); //获取请求头
      // 初始化表单验证规则
      this.initRules();
      //判断交易币种是否存在
      this.judgeCurrency();
      //初始化data
      this.resetData()
    },
    mounted() {
      this.findAllSysCostBankInfo();
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        SysCostBankInfo: state => state.system.SysCostBankInfo, //收款账号数据
        FinanceTabIndex: state => state.system.FinanceTabIndex, //数据字典选中下标
        FinanceCurrency: state => state.system.FinanceCurrency, //货币类型
      }),
    },
    watch: {},
    methods: {
      ...mapMutations([
        'FINANCE_COSTBANKINFO', //存放收款账号数据到vuex
        'FINANCE_CURRENCY', //把币种信息放入vuex
        'SHOW_COSTTYPEBOX', //控制费用分类选择弹框是否显示
      ]),
      ...mapActions([
        'getFinanceCurrency', //获取交易币种数据
        'getSysCostBankInfo', //获取银行账号数据
      ]),
      /* 判断交易币种是否存在vuex*/
      judgeCurrency() {
        if (this.FinanceCurrency.length == 0) {
          this.getFinanceCurrency();
        }
      },

      /* 显示费用分类弹框 */
      judgeRelatedData() {
        this.SHOW_COSTTYPEBOX(true)
      },

      /* 获取所选择的费用分类 */
      getCostTypeData(data) {
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个费用',
            duration: this.elDuration
          })
          return
        }
        this.save_CompanyAccount.subject = data[0].id; //费用分类id
        this.save_CompanyAccount.subjectName = data[0].name; //费用分类名称
      },

      /* 图片上传成功回调*/
      UploadSuccess(response, file, fileList) {
        this.imgUrl = response.data
        this.AllimgUrl = baseUrl + this.imgUrl
      },

      /* 点击增加按钮触发*/
      addBox() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        //设置操作类型为新增
        this.operatedState = 0;
        //设置需要控制的几种输入框为可编辑
        this.show_disabled = false; //启用修改初始余额
        //设置默认启用
        this.save_CompanyAccount.is_enable = true;
        //设置默认币种为人民币
        let defaultCurrency = this.commonJsExtend.getDictMatchingDataByCode('DICT-SCC-001', this.FinanceCurrency);
        this.save_CompanyAccount.currency = defaultCurrency.id;
        //显示弹框
        this.show_paymentAccount = true;
      },

      /* 点击编辑按钮触发*/
      updatePaymentAccount(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        //设置操作状态为修改
        this.operatedState = 1;
        //禁用修改初始余额
        this.show_disabled = true;
        if (data.account_type == 0) {//个人
          this.company = false; //公司按钮禁用
          this.personal = true; //个人按钮启用
          this.show_paymentAccount = true; //显示编辑弹窗
          this.showPer = false; //不显示个人按钮
          this.showCom = false; //不显示公司按钮
          this.active = '1'
          //个人
          this.save_CompanyAccount.id = data.id;
          this.save_CompanyAccount.account_name = data.account_name; //开户名称
          this.save_CompanyAccount.company_bank = data.company_bank;//开户银行
          this.save_CompanyAccount.account_num = data.account_num;//开户账号
          this.old_account_num = data.account_num;//旧的开户账号
          this.save_CompanyAccount.currency = parseInt(data.currency); //交易币种
          this.save_CompanyAccount.subject = data.subject; //会计科目
          this.save_CompanyAccount.encoding = data.encoding; //编码
          this.save_CompanyAccount.initial_amount = data.initial_amount; //初始余额
          this.save_CompanyAccount.balance = data.balance; //余额
          this.save_CompanyAccount.is_enable = data.is_enable == 1 ? true : false; //是否启用
          this.save_CompanyAccount.tax_rate = data.tax_rate; //默认税率
          this.save_CompanyAccount.subjectName = data.subjectName //会计科目
        }
        //公司
        if (data.account_type == 1) {
          this.show_disabled = true; //禁用修改初始余额
          this.company = true;
          this.personal = false;
          this.show_paymentAccount = true; //显示编辑弹窗
          this.showCom = false; //不显示公司按钮
          this.showPer = false; //不显示个人账号按钮
          //公司
          this.save_CompanyAccount.company_telephone = data.company_telephone;
          this.save_CompanyAccount.company_address = data.company_address;
          this.save_CompanyAccount.account_num = data.account_num;
          this.save_CompanyAccount.company_bank = data.company_bank;
          this.save_CompanyAccount.taxes_number = data.taxes_number;
          this.save_CompanyAccount.company_name = data.company_name;
          this.save_CompanyAccount.currency = parseInt(data.currency);
          this.save_CompanyAccount.tax_rate = data.tax_rate; //默认税率
          this.save_CompanyAccount.subjectName = data.subjectName //会计科目
          this.save_CompanyAccount.subject = data.subject; //会计科目
          this.save_CompanyAccount.encoding = data.encoding; //编码
          this.save_CompanyAccount.initial_amount = data.initial_amount; //初始余额
          this.save_CompanyAccount.balance = data.balance; //余额
          this.save_CompanyAccount.is_enable = data.is_enable == 1 ? true : false; //是否启用
          this.old_account_num = data.account_num;
          this.save_CompanyAccount.id = data.id;
          this.active = '0'
        }
        if (data.account_type == 2) {
          this.save_CompanyAccount.id = data.id;
          this.show_paymentAccount = true; //显示编辑弹窗
          this.save_CompanyAccount.toolAccount = data.other_account_num
          this.save_CompanyAccount.AccountName = data.other_name
          this.imgUrl = data.other_file_path
          this.AllimgUrl = baseUrl + data.other_file_path
          this.save_CompanyAccount.initial_amount = data.initial_amount; //初始余额
          this.save_CompanyAccount.is_enable = data.is_enable == 1 ? true : false; //是否启用
          this.save_CompanyAccount.balance = data.balance; //余额
          this.save_CompanyAccount.encoding = data.encoding; //编码
          this.active = '2'
          this.$forceUpdate();
        }
        this.sourceData = JSON.parse(JSON.stringify(this.save_CompanyAccount)) //将源数据储存
      },

      /* 查询收款账号*/
      async findAllSysCostBankInfo(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#finance_payment", 3);
        await this.getSysCostBankInfo();
        this.loading.close(); //关闭loading框
        //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
        if (type == 1) { //添加
          this.$message({
            type: 'success',
            message: '数据添加成功!',
            duration: this.elDuration
          })
        } else if (type == 2) { //修改
          this.$message({
            type: 'success',
            message: '数据修改成功!',
            duration: this.elDuration
          })
        } else if (type == 3) { //删除
          this.$message({
            type: 'success',
            message: '数据删除成功!',
            duration: this.elDuration
          })
        } else if (type == 4) { //排序
          this.$message({
            type: 'success',
            message: '数据排序成功!',
            duration: this.elDuration
          })
        }
      },

      /* 显示日志弹框 */
      showLogDialog(data) {
        this.thisData = data
        this.dialogVisible = true
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#log_tableBox", 4, '数据记载中,请稍候...');
        // 发送请求
        api.financeDict.findAllBankLog({
          bankId: data.id,
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
        }).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.logData = res.data.rows
            //获取分页数据
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 取消表单验证规则*/
      canclePaymentAccount() {
        //初始化data
        this.resetData()
        this.active = '0';
        this.AllimgUrl = "";
        this.imgUrl = '';
        this.$refs['paymentAccount_ruleform'].resetFields(); //移除表单验证规则(企业)
        this.$refs['person_ruleform'].resetFields(); //移除表单验证规则(个人)
        this.$refs['tool_ruleform'].resetFields(); //移除表单验证规则(工具)
        this.show_paymentAccount = false;
      },

      /* 删除数据*/
      deletePaymentAccount(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提升", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#finance_payment", 2);
          //发送请求
          api.financeDict.deleteSysCostBankInfo({
            id: data.id, //收款账号id
            name: data.name //收款账号名称
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              this.findAllSysCostBankInfo(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 增加或者修改方法*/
      savePaymentAccount() {
        if (this.operatedState == 0) { //添加
          if (this.active == 0) { //公司账号
            //表单验证
            this.$refs['paymentAccount_ruleform'].validate(valid => {
              if (valid) {
                var data = {
                  encoding: this.save_CompanyAccount.encoding, //编码
                  company_name: this.save_CompanyAccount.company_name, //公司名称
                  company_bank: this.save_CompanyAccount.company_bank, //开户银行
                  account_num: this.save_CompanyAccount.account_num, //开户账号
                  company_telephone: this.save_CompanyAccount.company_telephone, //联系电话
                  company_address: this.save_CompanyAccount.company_address, //公司地址
                  taxes_number: this.save_CompanyAccount.taxes_number, //默认税率
                  currency: this.save_CompanyAccount.currency, //币种
                  subject: this.save_CompanyAccount.subject, //会计科目
                  initial_amount: this.save_CompanyAccount.initial_amount, //初始余额
                  balance: this.save_CompanyAccount.initial_amount, //余额
                  is_enable: this.save_CompanyAccount ? 1 : 0, //是否启用
                  account_type: '1', //类型设置为公司
                  tax_rate: this.save_CompanyAccount.tax_rate, //税率
                }
                //加载loading框
                this.loading = this.commonJsExtend.customLoading("#finance_payment", 0);
                //发送请求
                api.financeDict.addSysCostBankInfo(data).then(res => {
                  //关闭loading
                  this.loading.close();
                  if (res.code == 200) {
                    this.canclePaymentAccount()
                    this.findAllSysCostBankInfo(1); //重新加载数组
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            })
          } else if (this.active == 1) { //个人账号
            this.$refs['person_ruleform'].validate(valid => {
              if (valid) {
                var data = {
                  account_name: this.save_CompanyAccount.account_name,
                  company_bank: this.save_CompanyAccount.company_bank,
                  account_num: this.save_CompanyAccount.account_num,
                  account_type: '0',
                  encoding: this.save_CompanyAccount.encoding, //编码
                  currency: this.save_CompanyAccount.currency, //币种
                  subject: this.save_CompanyAccount.subject, //会计科目
                  initial_amount: this.save_CompanyAccount.initial_amount, //初始余额
                  balance: this.save_CompanyAccount.initial_amount, //余额
                  is_enable: this.save_CompanyAccount ? 1 : 0, //是否启用
                  tax_rate: this.save_CompanyAccount.tax_rate, //税率
                }
                //加载loading框
                this.loading = this.commonJsExtend.customLoading("#finance_payment", 0);
                //发送请求
                api.financeDict.addSysCostBankInfo(data).then(res => {
                  this.loading.close(); //关闭loading框
                  //关闭loading
                  if (res.code == 200) {
                    this.canclePaymentAccount()
                    this.findAllSysCostBankInfo(1); //重新加载数组
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            })
          } else if (this.active == 2) { //工具
            this.$refs['tool_ruleform'].validate(valid => {
              if (valid) {
                var data = {
                  encoding: this.save_CompanyAccount.encoding, //编码
                  other_name: this.save_CompanyAccount.AccountName, //工具名称
                  other_account_num: this.save_CompanyAccount.toolAccount, //工具账号
                  other_file_path: this.imgUrl, //工具二维码截图
                  account_type: '2', //类型设置为工具
                  is_enable: this.save_CompanyAccount ? 1 : 0, //是否启用
                  initial_amount: this.save_CompanyAccount.initial_amount, //初始余额
                  balance: this.save_CompanyAccount.initial_amount, //余额
                }
                //加载loading框
                this.loading = this.commonJsExtend.customLoading("#finance_payment", 0);
                //发送请求
                api.financeDict.addSysCostBankInfo(data).then(res => {
                  //关闭loading框
                  this.loading.close();
                  if (res.code == 200) {
                    this.findAllSysCostBankInfo(1); //重新加载数组
                    this.canclePaymentAccount()
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            })
          }
        } else if (this.operatedState == 1) { //修改
          let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.save_CompanyAccount, '收款账号');
          if (this.active == 0) {
            //修改公司
            this.$refs['paymentAccount_ruleform'].validate(valid => {
              if (valid) {
                var data = {
                  id: this.save_CompanyAccount.id, //修改的数据id
                  company_name: this.save_CompanyAccount.company_name, //企业名称
                  company_bank: this.save_CompanyAccount.company_bank, //开户银行
                  account_num: this.save_CompanyAccount.account_num, //开户账号
                  old_account_num: this.old_account_num, //就开户账号
                  company_telephone: this.save_CompanyAccount.company_telephone, //公司电话
                  company_address: this.save_CompanyAccount.company_address, //公司地址
                  taxes_number: this.save_CompanyAccount.taxes_number, //默认税率
                  encoding: this.save_CompanyAccount.encoding, //编码
                  currency: this.save_CompanyAccount.currency, //币种
                  subject: this.save_CompanyAccount.subject, //会计科目
                  initial_amount: this.save_CompanyAccount.initial_amount, //初始余额
                  balance: this.save_CompanyAccount.balance, //余额
                  is_enable: this.save_CompanyAccount ? 1 : 0, //是否启用
                  tax_rate: this.save_CompanyAccount.tax_rate, //税率
                  account_type: '1',
                  logContent //日志
                }
                //加载loading框
                this.loading = this.commonJsExtend.customLoading("#finance_payment", 0);
                //发送请求
                api.financeDict.updateSysCostBankInfo(data).then(res => {
                  //关闭loading框
                  this.loading.close();
                  //关闭loading
                  if (res.code == 200) {
                    this.canclePaymentAccount()
                    this.findAllSysCostBankInfo(2); //重新加载数据
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            })
          } else if (this.active == 1) { //个人账号
            this.$refs['person_ruleform'].validate(valid => {
              if (valid) {
                var data = {
                  id: this.save_CompanyAccount.id, //需要修改的数据id
                  old_account_num: this.old_account_num, //旧的账号
                  account_name: this.save_CompanyAccount.account_name, //账号名称
                  company_bank: this.save_CompanyAccount.company_bank, //开户银行
                  account_num: this.save_CompanyAccount.account_num, //新账号
                  account_type: '0',
                  encoding: this.save_CompanyAccount.encoding, //编码
                  currency: this.save_CompanyAccount.currency, //币种
                  subject: this.save_CompanyAccount.subject, //会计科目
                  initial_amount: this.save_CompanyAccount.initial_amount, //初始余额
                  balance: this.save_CompanyAccount.balance, //余额
                  is_enable: this.save_CompanyAccount ? 1 : 0, //是否启用
                  tax_rate: this.save_CompanyAccount.tax_rate, //税率
                  logContent //日志
                }
                //加载loading框
                this.loading = this.commonJsExtend.customLoading("#finance_payment", 0);
                //发送请求
                api.financeDict.updateSysCostBankInfo(data).then(res => {
                  //关闭loading框
                  this.loading.close();
                  if (res.code == 200) {
                    this.canclePaymentAccount()
                    this.findAllSysCostBankInfo(2); //重新加载数组
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            })
          } else if (this.active == 2) {
            this.$refs['tool_ruleform'].validate(valid => {
              if (valid) {
                var data = {
                  id: this.save_CompanyAccount.id, //需要修改的工具数据id
                  other_account_num: this.save_CompanyAccount.toolAccount, //工具账号
                  other_name: this.save_CompanyAccount.AccountName, //工具名称
                  other_file_path: this.imgUrl, //工具图片
                  account_type: '2',
                  is_enable: this.save_CompanyAccount ? 1 : 0, //是否启用
                  initial_amount: this.save_CompanyAccount.initial_amount, //初始余额
                  balance: this.save_CompanyAccount.balance, //余额
                  logContent //日志
                }
                //加载loading框
                this.loading = this.commonJsExtend.customLoading("#finance_payment", 0);
                //发送请求
                api.financeDict.updateSysCostBankInfo(data).then(res => {
                  //关闭loading
                  this.loading.close();
                  if (res.code == 200) {
                    this.findAllSysCostBankInfo(2); //重新加载数组
                    this.canclePaymentAccount()
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                      duration: this.elDuration
                    })
                  }
                })
              }
            })
          }
        }
      },

      /* 初始话表单验证规则*/
      initRules() {
        //企业账号表单验证
        this.paymentAccount_rules = {
          company_name: [ //公司名称
            { //规则1
              required: true, //是否必填
              message: '请输入企业名称', //该规则错误信息提示
              trigger: 'change' //哪种事件触发
            },
          ],
          account_num: [{
            required: true,
            message: '银行账号不能为空'
          }],
          taxes_number: [{
            required: true, //是否必填
            message: '纳税编号不能为空', //该规则错误信息提示
            trigger: 'change' //哪种事件触发
          }],
          company_bank: [{
            required: true, //是否必填
            message: '开户银行不能为空', //该规则错误信息提示
            trigger: 'change' //哪种事件触发
          }],
        }
        //个人账号表单验证
        this.peroson_rules = {
          account_name: [{
            required: true,
            message: '开户人姓名不能为空',
            trigger: 'change'
          }],
          company_bank: [{
            required: true,
            message: '开户银行不能为空',
            trigger: 'change'
          }],
          account_num: [{
            required: true,
            message: '开户账号不能为空',
            trigger: 'change'
          }],
        }
        this.other_rules = {
          AccountName: [{
            required: true,
            message: '账号名称不能为空',
            trigger: 'change'
          }],
          toolAccount: [{
            required: true,
            message: '收款账号不能为空',
            trigger: 'change'
          }],
        }
      },

      /* 关闭日志弹框 */
      colseLogDialog() {
        this.dialogVisible = false
      },

      /* 分页数据大小改变 */
      changePageSize(val) {
        //获取分页大小
        this.pageData.pageSize = val;
        //请求数据
        this.showLogDialog(this.thisData)
      },

      /* 当前页改变 */
      changeCurrentPage(val) {
        //获取当前页
        this.pageData.currentPage = val;
        //请求数据
        this.showLogDialog(this.thisData)
      },

      /* 初始化data */
      resetData() {
        this.save_CompanyAccount = { //增加账户框里面的值
          id: '',
          //公司
          company_telephone: '', //公司电话
          company_address: '', // 公司地址
          taxes_number: '', // 公司纳税编号
          company_name: '', // 公司名称
          company_bank: '', // 公司开户银行
          tax_rate: '', //税率
          //个人
          account_name: '', // 个人开户人姓名
          account_num: '', // 个人开户账号
          //收款工具
          toolName: '', //工具名称
          toolAccount: '', //工具账号
          AccountName: '', //账号名称
          //币种
          currency: '', //个人币种
          subject: '', //会计科目id
          subjectName: '', //会计科目
          encoding: '', //编码
          initial_amount: '', //初始余额
          balance: '', //余额
          is_enable: '', //是否启用
        }
      }
    },
    filters: {},
    components: {
      costTypeTree,
    }
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  /* 文本超出长度显示省略号 */
  .people {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
<style lang="less" scoped>
  /* 日志弹框 */
  .paymentLogBox {
    height: 50vh;
  }

  /* 默认税率输入框 */
  .defaultTaxInput {
    width: 80px;
  }

  // 税率百分号输入框
  .sign {
    padding: 0 10px;
  }

  /* 收支标识样式*/
  .inSign {
    //收
    font-weight: bold;
    color: green;
  }

  .outSign {
    //支
    font-weight: bold;
    color: red;
  }
</style>
