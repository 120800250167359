<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关-货品属性请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="addProductP" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="ProductProperty" row-key="id" height="100%" id="attribute_table" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateProduct(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteProductProperty(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1?true:false" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="销售报价可见" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.visible_sell==1?true:false" disabled>可见</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="bom配料可见" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.visible_material==1?true:false" disabled>可见</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="生产自由添加" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.visible_product==1?true:false" disabled>是</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="系统默认" width="100">
          <template slot-scope="scope">
            <el-checkbox :checked="scope.row.is_system==1?true:false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="user_name" label="可见人员" width="240" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>
    <!--修改增加弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '货品属性'" :visible.sync="show_saveProductPropertyBox"
      id="adupdate" width="400px" :before-close="cancleProductProperty" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="saveProduct_Property" :rules="productProperty_rules" ref="productProperty_ruleform"
          label-width="108px" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="编码:">
                <el-input v-model.trim="saveProduct_Property.encoding" placeholder="请输入编码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="saveProduct_Property.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="saveProduct_Property.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售报价可见:">
                <el-checkbox v-model="saveProduct_Property.visible_sell"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="BOM配料可见:">
                <el-checkbox v-model="saveProduct_Property.visible_material"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="生产自由添加:">
                <el-checkbox v-model="saveProduct_Property.visible_product"></el-checkbox>
                <span class="tips">该配置必须在BOM配料可见下才能生效</span>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 员工 -->
          <el-row>
            <el-col :span="24">
              <el-form-item label="可见员工:" prop="userName">
                <el-input class="ze_input" v-model="saveProduct_Property.userNameStr" placeholder="请选择员工"
                  autocomplete="off" suffix-icon="el-icon-more" @focus="judgeRelatedData(1)">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="saveProduct_Property.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleProductProperty">取 消</el-button>
        <el-button size="small" type="primary" @click="saveProduct">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData" :defaultSelected="defaultSelected"></personnelList>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from '@/network/system/dictionary'

  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件

  export default {
    name: "product_attribute",
    data() {
      return {
        productProperty_rules: [], //表单验证规则
        saveProduct_Property: { //修改保存临时值
          id: '',
          encoding: '',
          name: '', //名称
          remark: '', //备注
          visible_sell: true, //销售可见
          visible_material: true, //BOM配料
          visible_product: true, //是否生产是自由增加
          is_enable: true, //是否启用
          is_system: true, //是否系统
          new_name: '',
          userNameStr: '', //员工
          userId: '', //员工id
        },
        old_name: '', //编辑之前的名字

        defaultSelected: [], //可见员工默认勾选

        //定义编辑源数据
        sourceData: {},
        //控制开关
        show_saveProductPropertyBox: false, //是否显示新增框
        //判断条件
        operateState: 0, //判断新增还是修改(0:新增 ， 1::修改)
      }
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#attribute_table>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProductProperty: state => state.system.ProductProperty, //货品属性数据
        ProductTabIndex: state => state.system.ProductTabIndex, //货品属性字典选中下标
        SortIndex: state => state.system.SortIndex, //排序下标

      }),
    },
    watch: {
      //监听货品相关数据字典选项卡下标
      ProductTabIndex(newVal) {
        //判断下标是否为货品属性
        if (newVal == "6.4") {
          //判断vuex是否存在数据
          this.judjeFollowupWay();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCT_PROPERTIES', //存放货品属性数据到vuex
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
      ]),

      /* 取消新增或修改操作 */
      cancleProductProperty() {
        this.$refs['productProperty_ruleform'].resetFields(); //移除表单验证规则
        this.show_saveProductPropertyBox = false;
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.productProperty_rules = {
          name: [ //货品属性名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 打开新增弹窗*/
      addProductP() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.saveProduct_Property = { //修改保存临时值
            name: '', //名称
            encoding: '', //编码
            visible_sell: true, //销售可见
            visible_material: true, //BOM配料
            visible_product: false, //是否生产自由增加
            is_enable: true, //是否启用
            remark: '', //备注
          },
          this.defaultSelected = []
        //设置操作状态为新增
        this.operateState = 0;
        //显示新增弹框
        this.show_saveProductPropertyBox = true;
      },

      /* 打开修改窗口*/
      updateProduct(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.saveProduct_Property.encoding = data.encoding;
        this.saveProduct_Property.id = data.id;
        this.saveProduct_Property.name = data.name;
        this.old_name = data.name;
        this.saveProduct_Property.is_enable = data.is_enable == 1 ? true : false;
        this.saveProduct_Property.is_system = data.is_system == 1 ? true : false;
        this.saveProduct_Property.visible_sell = data.visible_sell == 1 ? true : false;
        this.saveProduct_Property.visible_material = data.visible_material == 1 ? true : false;
        this.saveProduct_Property.visible_product = data.visible_product == 1 ? true : false;
        this.saveProduct_Property.remark = data.remark;
        this.saveProduct_Property.userNameStr = data.user_name;
        this.saveProduct_Property.userId = data.user_id;
        this.sourceData = JSON.parse(JSON.stringify(this.saveProduct_Property)) //将源数据储存
        if (data.user_id) {
          this.defaultSelected = data.user_id.split(',')
        }
        //设置操作状态为修改
        this.operateState = 1;
        //显示弹框
        this.show_saveProductPropertyBox = true;
      },
      /* 判断vuex是否存在数据*/
      judjeFollowupWay() {
        // vuex中没有数据,从服务器请求
        if (this.ProductProperty.length == 0) {
          this.findProductProperty();
        }
      },
      /* 查询货品属性*/
      findProductProperty(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#attribute_table", 3);
        // 发送请求
        api.productDict.findAllProductProperty().then(res => {
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.PRODUCT_PROPERTIES(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2::修改)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_saveProductPropertyBox = false; //关闭新增输入框
          }
        });
      },

      /* 删除货品属性 */
      deleteProductProperty(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#attribute_table", 2);
          //发送删除请求
          api.productDict.deleteProductProperty({
            id: data.id, //货品属性id
            name: data.name //货品属性名称
          }).then(res => {
            if (res.code == 200) {
              this.findProductProperty(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
            this.loading.close(); //关闭加载框
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          });
          this.loading.close(); //关闭加载框
        });
      },

      /* 保存货品属性信息*/
      saveProduct() {
        // 保存前判断是否满足所有表单验证规则
        this.$refs['productProperty_ruleform'].validate(valid => {
          if (valid) {
            // 判断是新增还是修改
            if (this.operateState == 0) {
              let data = {
                encoding: this.saveProduct_Property.encoding,
                name: this.saveProduct_Property.name,
                remark: this.saveProduct_Property.remark,
                is_enable: this.saveProduct_Property.is_enable ? 1 : 0,
                visible_sell: this.saveProduct_Property.visible_sell ? 1 : 0,
                visible_material: this.saveProduct_Property.visible_material ? 1 : 0,
                visible_product: this.saveProduct_Property.visible_product ? 1 : 0,
                user_id: this.saveProduct_Property.userId
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#attribute_table", 0);
              //发送请求
              api.productDict.addProductProperty(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findProductProperty(1); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_saveProductPropertyBox = false;
              });
            } else {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.saveProduct_Property,
                '货品属性');
              //修改数据
              var data = {
                id: this.saveProduct_Property.id,
                encoding: this.saveProduct_Property.encoding,
                name: this.old_name,
                new_name: this.saveProduct_Property.name,
                is_enable: this.saveProduct_Property.is_enable ? 1 : 0,
                visible_sell: this.saveProduct_Property.visible_sell ? 1 : 0,
                visible_material: this.saveProduct_Property.visible_material ? 1 : 0,
                visible_product: this.saveProduct_Property.visible_product ? 1 : 0,
                remark: this.saveProduct_Property.remark,
                user_id: this.saveProduct_Property.userId, //员工
                logContent //日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#attribute_table", 1);
              //发送请求
              api.productDict.updateProductProperty(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findProductProperty(2); //获取新数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                this.show_saveProductPropertyBox = false;
              });
            }
          }
        });
      },

      /* 获取所选择的员工数据 */
      getPersonnelData(data) {
        let result = this.commonJsExtend.getPersonnelMsg(data);
        this.saveProduct_Property.userNameStr = result.nameShowStr; //员工
        this.saveProduct_Property.userId = result.idStr; //员工id
        this.$forceUpdate()
      },

      /* 控制部门员工弹框弹窗显示隐藏 */
      judgeRelatedData(type) {
        this.SHOW_PERSONNELBOX(true);
      },

      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.4" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.ProductProperty[newVal.oldIndex].id, //原Id
            targetId: this.ProductProperty[newVal.newIndex].id //目标Id
          }
          this.loading = this.commonJsExtend.customLoading("#attribute_table", 1); //Loading加载框
          api.productDict.updateSysProductPropertySort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findProductProperty(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },
    components: {
      personnelList,
    }
  }
</script>

<style>
</style>
