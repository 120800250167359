<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：生产相关-加工单位请求接口
开始时间：2022-10-31
开发人员：王铭宇
最后修改：2022-10-31
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="addCheck" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="ProcessRejects" row-key="id" id="ProcessRejects_table" height="100%" border
        style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateCheck(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteProcessRejects(scope.row)" title="删除"></i>
           <!-- <div v-if="scope.row.is_system==0">

            </div> -->
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="remark" label="说明" min-width="180"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="180"></el-table-column>
      </el-table>
    </div>
    <!--修改增加弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '不良品项'" :visible.sync="show_saveProcessRejects" width="400px"
     :before-close="cancleProcessRejects"  append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
       <el-form :model="save_ProcessRejects" label-width="80px"
         ref="ProcessUnit_ruleform" size="mini">
         <!-- 分行显示 -->
         <el-row>
           <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
           <el-col :span="24">
             <el-form-item label="名称:" prop="name">
               <el-input v-model.trim="save_ProcessRejects.name" placeholder="请输入名称"></el-input>
             </el-form-item>
           </el-col>
         </el-row>
         <!-- 分行显示 -->
         <el-row>
           <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
           <el-col :span="24">
             <el-form-item label="备注:" prop="remark">
               <el-input v-model.trim="save_ProcessRejects.remark" placeholder="请输入备注"></el-input>
             </el-form-item>
           </el-col>
         </el-row>
       </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleProcessRejects">取 消</el-button>
        <el-button size="small" type="primary" @click="saveProcessRejects">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
 // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary';//数据字典相关方法
  export default {
    name:"process_rejects",
    data(){
      return{
        //不良品项数据
        ProcessRejects:[],
        //弹框数据
        save_ProcessRejects:{
          id:'',//主键id
          name:'',//名称
          remark:'',//备注
        },
        //弹窗显示
        show_saveProcessRejects:false,
        //操作类型 0:新增 1:修改
        operateState:0,
      }
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProcessUnit: state => state.system.ProcessUnit, //vuex中的加工单位数据
        ProductionTabIndex: state => state.system.ProductionTabIndex, //当前下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch:{
      //监听生产相关数据字典选项卡下标
      ProductionTabIndex(newVal) {
        //判断下标是否为加工单位
        if (newVal == "4.6") {
          this.findAllProcessRejects();
        }
    }
    },
    methods:{
      addCheck(){
        this.show_saveProcessRejects=true
      },
      cancleProcessRejects(){
        this.save_ProcessRejects={}
        this.show_saveProcessRejects=false

      },
      //保存新增或修改 (0:为新增 1:修改)
      saveProcessRejects(){
        if(this.operateState==0){//新增
          let data={
            name:this.save_ProcessRejects.name,
            remark:this.save_ProcessRejects.remark,
          }
         api.production.addProcessRejects(data).then(res=>{
           if(res.code==200){
             this.$message({
               type: 'success',
               message: "新增成功",
               duration: this.elDuration
             })
             this.save_ProcessRejects={}
             this.show_saveProcessRejects=false
             this.findAllProcessRejects()
           }
           else{
             // 显示提示信息
             this.$message({
               type: 'error',
               message: res.message,
               duration: this.elDuration
             })
           }
         })
        }
        else if(this.operateState==1){//当为修改的时候
          let data={
            id:this.save_ProcessRejects.id,
            name:this.save_ProcessRejects.name,
            remark:this.save_ProcessRejects.remark,
          }
          api.production.updateProcessRejects(data).then(res=>{
            if(res.code==200){
              this.$message({
                type: 'success',
                message: "修改成功",
                duration: this.elDuration
              })
              this.show_saveProcessRejects=false
              this.findAllProcessRejects()
            }else{
              // 显示提示信息
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })

        }

      },
      //查询相关不良品数据
      findAllProcessRejects(){

        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#ProcessRejects_table", 3);
        //发送请求
        api.production.findAllProcessRejects().then(res=>{
          if(res.code==200){
            this.ProcessRejects=res.data
          }
          else{
            // 显示提示信息
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          this.loading.close()
        })
      },
      //不良品项修改操作
      updateCheck(data){
        this.operateState = 1;
        this.show_saveProcessRejects=true
        this.save_ProcessRejects.name=data.name
        this.save_ProcessRejects.remark=data.remark
        this.save_ProcessRejects.id=data.id
      },
      deleteProcessRejects(data){
       let deleteData={
         id:data.id
       }
       this.$confirm("确认要删除该条数据吗，删除后将不能还原，请确认", "提示", {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
       }).then(()=>{
         this.loading = this.commonJsExtend.customLoading("#ProcessRejects_table", 4,"数据删除中 请稍后");
         api.production.deleteProcessRejects(deleteData).then(res=>{
           if(res.code==200){
             this.$message({
               type: 'success',
               message: "删除成功",
               duration: this.elDuration
             })
             this.loading.close()
             this.findAllProcessRejects()
           }else{
             // 显示提示信息
             this.$message({
               type: 'error',
               message: res.message,
               duration: this.elDuration
             })
           }
         })
       }).catch(()=>{
          this.$message({
            type: 'info',
            message: '已取消完成操作',
            duration: this.elDuration
          });
        })

      },
    }
    }
</script>
<style>

</style>
