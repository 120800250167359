<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：仓储相关物流方式请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addMode">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="ModeTabel" row-key="id" :data="LogisticsMode" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateMode(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteLogisticsMode(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column prop="is_enable" label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="is_system" label="系统默认" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_system==1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operatedState == 0 ?'新增':'修改') + '物流方式'" :visible.sync="show_savelogistics" width="400px"
      :before-close="cancleLogistics_Mode" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="saveLogistics_mode" :rules="LogisticsMode_rules" ref="logisticsMode_ruleform"
          label-width="80px" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="saveLogistics_mode.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="saveLogistics_mode.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="saveLogistics_mode.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleLogistics_Mode">取 消</el-button>
        <el-button size="small" type="primary" @click="saveMode">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: 'storage_logisticsMode',
    data() {
      return {

        saveLogistics_mode: { //编辑框的值
          name: '',
          is_enable: '',
          is_system: '',
          remark: '',
          old_name: '',
          id: '',
        },
        //定义编辑源数据
        sourceData: {},
        LogisticsMode_rules: [], //表单验证
        //控制开关
        show_savelogistics: false,
        //判断条件
        operatedState: 0,
        //加载loading
        loading: '',
      }
    },
    watch: {
      //监听仓库相关数据字典选项卡下标
      StorageTabIndex(newVal) {
        if (newVal == "2.1") {
          this.judjeMode()
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      },
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        LogisticsMode: state => state.system.LogisticsMode, //物流方式数据
        StorageTabIndex: state => state.system.StorageTabIndex, //点击物流方式下标
        SortIndex: state => state.system.SortIndex, //排序下标
      })
    },
    created() {
      //初始化表单验证
      this.initRules();
      this.judjeMode()
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#ModeTabel>.el-table__body-wrapper > table > tbody");
    },
    methods: {
      ...mapMutations([
        'LOGISTICS_MODE'
      ]),
      /* 点击新增按钮触发*/
      addMode() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.saveLogistics_mode = {
          name: '',
          is_enable: true,
          is_system: '',
          remark: '',
          old_name: '',
          id: '',
        }
        this.show_savelogistics = true;
        this.operatedState = 0;
      },
      
      judjeMode() {
        if (this.LogisticsMode.length == 0) {
          this.getLogisticsMode();
        }
      },
      
      /* 初始化表单验证规则*/
      initRules() {
        this.LogisticsMode_rules = {
          name: [ //物流方式名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 查询物流方式*/
      getLogisticsMode(type) {
        //加载loading
        this.loading = this.commonJsExtend.customLoading("#ModeTabel", 3);
        //发送请求
        api.storageDict.findAllSysDepotLogisticWayService().then(res => {
          if (res.code == 200) {
            this.LOGISTICS_MODE(res.data); //把查询的数据放入vuex中
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '数据排序成功',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
            this.show_addBox = false; //关闭新增输入框
          }
        })
      },
      
      /* 删除数据*/
      deleteLogisticsMode(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading
          this.loading = this.commonJsExtend.customLoading("#ModeTabel", 2);
          //发送请求
          api.storageDict.deleteSysDepotLogisticWayService({
            id: data.id, //物流方式id
            name: data.name //物流方式名称
          }).then(res => {
            this.loading.close() //关闭loading
            if (res.code == 200) {
              this.getLogisticsMode(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },
      
      /*修改数据*/
      updateMode(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.operatedState = 1; //设置当前状态为修改
        this.show_savelogistics = true; //显示编辑框
        this.saveLogistics_mode.name = data.name; //将编辑信息赋值到编辑框
        this.saveLogistics_mode.remark = data.remark;
        this.saveLogistics_mode.is_enable = data.is_enable == 1 ? true : false;
        this.saveLogistics_mode.old_name = data.name;
        this.saveLogistics_mode.id = data.id;
        this.sourceData = JSON.parse(JSON.stringify(this.saveLogistics_mode)) //将源数据储存
      },

      /*取消增加或者修改表单验证*/
      cancleLogistics_Mode() {
        this.$refs['logisticsMode_ruleform'].resetFields(); //移除表单验证规则
        this.show_savelogistics = false;
      },
      /*保存数据*/
      saveMode() {
        console.log(this.operatedState);
        // 保存前判断是否满足所有表单验证规则
        this.$refs['logisticsMode_ruleform'].validate(valid => {
          if (valid) {
            //判断是新增还是修改
            if (this.operatedState == 0) {
              var data = {
                name: this.saveLogistics_mode.name,
                is_enable: this.saveLogistics_mode.is_enable ? 1 : 0,
                remark: this.saveLogistics_mode.remark,
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#ModeTabel", 0);
              //发送请求
              api.storageDict.addSysDepotLogisticWayService(data).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getLogisticsMode(1); //重新加载数组
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
                //关闭弹窗
                this.show_savelogistics = false;
              })
            } else if (this.operatedState == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.saveLogistics_mode, '物流方式');
              var data = {
                name: this.saveLogistics_mode.old_name,
                id: this.saveLogistics_mode.id,
                new_name: this.saveLogistics_mode.name,
                remark: this.saveLogistics_mode.remark,
                is_enable: this.saveLogistics_mode.is_enable ? 1 : 0,
                logContent//日志
              }
              //加载loading
              this.loading = this.commonJsExtend.customLoading("#ModeTabel", 1);
              //发送请求
              api.storageDict.updateSysDepotLogisticWayService(data).then(res => {
                this.loading.close() //关闭loading
                if (res.code == 200) {
                  this.getLogisticsMode(2);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              this.show_savelogistics = false;
            }
          }
        })
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.StorageTabIndex == "2.1" && this.DictionaryLeftIndex == 2) {
          let data = {
            sourceId: this.LogisticsMode[newVal.oldIndex].id,
            targetId: this.LogisticsMode[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#ModeTabel", 1);
          api.storageDict.updateSysDepotLogisticWaySort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getLogisticsMode(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }


      },
    },
  }
</script>
