<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品相关规格请求接口
开始时间：2021-03-13
开发人员：万攀,康泽鑫
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="show_AddBox">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="specificationTable" row-key="id" :data="specificationData" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateSpecification(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="delectProductSpecification(scope.row)" title="删除"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="规格名称" sortable="" width="120"></el-table-column>
        <!-- <el-table-column prop="product_type_name" label="货品分类" width="120"></el-table-column> -->
        <el-table-column prop="specificationStr" label="规格值" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="specificationStr">{{scope.row.specificationStr || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="货品分类" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="specificationStr">{{scope.row.typeName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_enable" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200" :formatter="formatterCellval"></el-table-column>
      </el-table>
    </div>

    <!-- 弹框 -->
    <!-- 新增,修改规格 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + '规格'" :visible.sync="dialogVisible" width="570px" append-to-body
      :before-close="close_box">
      <div class="dictionaryAddBox">
        <el-form :model="specificationEidt" :rules="specification_rules" ref="specification_ruleform"
          label-width="100px" class="demo-ruleForm" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <el-col :span="20">
              <el-form-item label="规格名:" prop="name">
                <el-input v-model.trim="specificationEidt.name" placeholder="请输入规格名"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="货品分类:" prop="product_type">
                <el-input v-model="specificationEidt.product_type" @click.native="show_productType"
                  placeholder="请选择货品分类"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="状态:">
                <el-checkbox v-model="specificationEidt.state">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="specificationEidt.remark"
                  resize="none">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div>
        <el-button type="primary" size="mini" style="margin-bottom: 5px;" @click="add()">新增行</el-button>
      </div>
      <!-- 表格 -->
      <div class="billing_table">
        <el-table :data="tableData_speDetails" style="width: 100%;" border>
          <el-table-column type="index" width="40" align='center' sortable></el-table-column>
          <el-table-column label="规格值名称" width="200" align='center'>
            <template slot-scope="scope">
              <el-input v-model="scope.row.value" size="mini" placeholder="请输入规格值名称"></el-input>
            </template>
          </el-table-column>
          <!--<el-table-column label="货品分类" width="150" align='center'>
              <template slot-scope="scope">
                <el-input v-model="scope.row.product_type" size="mini"></el-input>
              </template>
            </el-table-column>-->
          <el-table-column label="状态" width="120" align='center'>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_enable">启用</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="100" align='center'>
            <template slot-scope="scope">
              <i class="el-icon-delete-solid" @click="remove(scope.row,scope.$index)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_box">取 消</el-button>
        <el-button type="primary" @click="saveAddorUpdate">确 定</el-button>
      </span>
    </el-dialog>

    <!--  货品分类选择组件 -->
    <producttype @SelectedData="getSelectClassfiy" v-if="show_productTypebox"></producttype>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary'
  //导入拖拽方法
  import Sortable from "sortablejs";
  //导入货品分类选择公共组件
  import producttype from '@/components/commComponent/tree/tree_productType';

  export default {
    name: "product_specification",
    data() {
      return {
        dialogVisible: false,
        oldName: '', //旧名称
        specificationId: '', //规格ID
        specification_rules: {}, //表单验证规则
        specificationEidt: { //当前正在编辑的数据
          name: '',
          state: '',
          remark: '',
          product_type: '', //货品分类
          product_typeId: '', //货品分类ID
          state: true, //默认启用
        },

        specificationData: [], //规格名称数据

        tableData_speDetails: [],

        show_productTypebox:false,//

        operateState: 0, //判断新增还是修改(0:新增 , 1::修改)
        loading: '', //loading加载框

        //日志相关
        sourceData: {}, //定义编辑源数据(规格信息)
        sourceSpeDetails: [], //定义编辑源数据(规格值信息)

      }
    },
    created() {
      //初始化表单验证
      this.initRules()
    },
    mounted() {
      //初始化拖拽
      this.commonJsExtend.customDragrow("#specificationTable>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProductSpecification: state => state.system.ProductSpecification, //规格名称数据
        ProductTabIndex: state => state.system.ProductTabIndex, //货品相关标签页下标
        SortIndex: state => state.system.SortIndex, //表格排序下标
      })
    },
    watch: {
      ProductTabIndex(newVal) {
        if (newVal == "6.6") {
          this.judjeSpecificatio()
        }
      },
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);
        }
      }
    },
    methods: {
      ...mapMutations([
        'PRODUCT_SPECIFICATION',
        'SHOW_PRODUCTTYPEBOX' //控制货品分类弹框显示
      ]),
      /* 如果Vuex中没有数据就请求 */
      judjeSpecificatio() {
        if (this.ProductSpecification.length == 0) {
          this.getProductSpecification()
        } else {
          this.specificationData = this.ProductSpecification
        }
      },
      /* 获取规格名称 */
      getProductSpecification(type) {
        this.loading = this.commonJsExtend.customLoading("#specificationTable", 3);
        api.productDict.getProductSpecification().then(res => {
          if (res.code == 200) {
            this.loading.close()
            this.PRODUCT_SPECIFICATION(res.data)
            this.specificationData = res.data
            this.specificationData.forEach(item => {
              let specificationStr = []
              item.value.forEach((obj, index) => {
                specificationStr.push(obj.value)
              })
              specificationStr = specificationStr.join(',')
              this.$set(item, 'specificationStr', specificationStr)
              if (item.is_enable == 1) {
                item.is_enable = true
              } else {
                item.is_enable = false
              }
            })
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: 1500
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: 1500
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: 1500
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '排序修改成功',
                duration: 1500
              })
            }
          }
        })
      },
      /*  表单验证规则 */
      initRules() {
        this.specification_rules = {
          name: [ //跟进方式名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 10, //最大长度
              message: '长度在 1 到 10个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ],
          product_type: [ //货品分类规则
            { //规则1
              required: true, //是否必填
              message: '请选择分类', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
          ]
        }
      },
      /* 获取点击货品分类信息 */
      getSelectClassfiy(data) {
        const newarr = data.map(item => ({
          id: item.id,
          name: item.name
        }))
        let product_type = ''
        let product_typeId = ''
        newarr.forEach(item => {
          product_type += item.name + ','
          product_typeId += item.id + ','
        })
        if (product_type.substring(product_type.length - 1) == ',') {
          product_type = product_type.substring(0, product_type.length - 1)
        }
        if (product_typeId.substring(product_typeId.length - 1) == ',') {
          product_typeId = product_typeId.substring(0, product_typeId.length - 1)
        }
        this.specificationEidt.product_typeId = product_typeId
        this.specificationEidt.product_type = product_type
        this.$refs['specification_ruleform'].validateField('product_type');

        this.show_productTypebox = false;
      },
      /* 添加或修改保存数据 */
      saveAddorUpdate() {
        if (this.operateState == 0) {
          this.tableData_speDetails.forEach(item => {
            if (item.is_enable == true) {
              item.is_enable = 1
            } else {
              item.is_enable = 0
            }
          })
          var data = {
            name: this.specificationEidt.name,
            is_enable: this.specificationEidt.state == true ? 1 : 0,
            is_system: 0,
            remark: this.specificationEidt.remark,
            product_type_id: this.specificationEidt.product_typeId,
            values: JSON.stringify(this.tableData_speDetails)
          }
          this.loading = this.commonJsExtend.customLoading("#specificationTable", 1);
          api.productDict.addProductSpecification(data).then(res => {
            if (res.code == 200) {
              this.loading.close()
              this.getProductSpecification(1)
            } else {
              this.loading.close()
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              })
            }

          })
        } else if (this.operateState == 1) {
          this.tableData_speDetails.forEach(item => {
            if (item.is_enable == true) {
              item.is_enable = 1
            } else {
              item.is_enable = 0
            }
            if (!item.hasOwnProperty('id')) {
              item.id = 0
              item.specs_id = this.specificationId
              item.domain = ''
            }
          })
          let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.specificationEidt, '货品规格');//日志
          //规格值日志拼接
          if (JSON.stringify(this.sourceSpeDetails) != JSON.stringify(this.tableData_speDetails)) { //当规格值进行编辑操作之后
            let sourceSpeDetailsLog = '更改了【货品规格】中的【规格值】,更改前规格值数据:{[' //定义更改前规格值数据字符串变量
            this.sourceSpeDetails.forEach((item, index) => { //循环规格值源数据数组
              if (index == this.sourceSpeDetails.length - 1) { //当为数组最后一个元素时
                sourceSpeDetailsLog += '规格值名称:' + item['value'] + ',状态:' + (item['is_enable'] == 1 ? '启用' : '不启用') +
                  ']' //日志拼接
              } else { //当不为数组最后一个元素时
                sourceSpeDetailsLog += '规格值名称:' + item['value'] + ',状态:' + (item['is_enable'] == 1 ? '启用' : '不启用') +
                  '],[' //日志拼接
              }
            })
            let afterEditLog = '更改后规格值数据:{[' //定义更改后规格值数据字符串变量
            this.tableData_speDetails.forEach((item, index) => { //循环规格值编辑后数据数组
              if (index == this.tableData_speDetails.length - 1) { //当为数组最后一个元素时
                afterEditLog += '规格值名称:' + item['value'] + ',状态:' + (item['is_enable'] == 1 ? '启用' : '不启用') +
                  ']' //日志拼接
              } else { //当不为数组最后一个元素时
                afterEditLog += '规格值名称:' + item['value'] + ',状态:' + (item['is_enable'] == 1 ? '启用' : '不启用') +
                  '],[' //日志拼接
              }
            })
            logContent += sourceSpeDetailsLog + '};' + afterEditLog + '}' //日志拼接
          }

          var data = {
            id: this.specificationId,
            name: this.oldName,
            new_name: this.specificationEidt.name,
            is_enable: this.specificationEidt.state == true ? 1 : 0,
            is_system: 0,
            remark: this.specificationEidt.remark,
            product_type_id: this.specificationEidt.product_typeId,
            values: JSON.stringify(this.tableData_speDetails),
            logContent //日志
          }
          this.loading = this.commonJsExtend.customLoading("#specificationTable", 3);
          api.productDict.updateProductSpecification(data).then(res => {
            if (res.code == 200) {
              this.loading.close()
              this.getProductSpecification(2)
            } else {
              this.loading.close()
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              })
            }
          })
        }
        this.tableData_speDetails = [],
          this.specificationEidt = { //当前正在编辑的数据
            product_type: '', //货品分类
            product_typeId: '', //货品分类ID
          }

        this.dialogVisible = false
      },
      /* 删除规格信息 */
      delectProductSpecification(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm('您确定要删除这个规格信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#specificationTable", 2);
          api.productDict.delectProductSpecification({
            id: data.id, //货品规格id
            name: data.name //货品规格名称
          }).then(res => {
            if (res.code == 200) {
              this.loading.close()
              this.getProductSpecification(3);
            } else {
              this.loading.close()
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              })
            }

          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          })
        })
      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductTabIndex == "6.6" && this.DictionaryLeftIndex == 6) {
          let data = {
            sourceId: this.ProductSpecification[newVal.oldIndex].id,
            targetId: this.ProductSpecification[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#specificationTable", 1);
          api.productDict.updateProductSpecificationSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.getProductSpecification(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: 1500
              })
              this.loading.close();
            }
          })
        }
      },

      /* 无内容显示占位符 */
      formatterCellval(row, column, cellValue, index) {
        // console.log(Boolean(cellValue), cellValue);
        if (!Boolean(cellValue)) {
          return "-";
        } else {
          return cellValue;
        }
      },

      /* 显示新增弹框 */
      show_AddBox() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.operateState = 0
        this.dialogVisible = true
        this.specificationEidt.state = true //默认启用
      },

      /* 显示修改弹框 */
      updateSpecification(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.oldName = data.name
        this.specificationId = data.id
        this.specificationEidt = { //将当前修改的数据传到页面
          name: data.name,
          state: data.is_enable == 1 ? true : false,
          remark: data.remark,
          product_type: data.typeName,
          product_typeId: data.product_type_id
        }
        this.sourceData = JSON.parse(JSON.stringify(this.specificationEidt)) //将源数据储存
        this.sourceSpeDetails = JSON.parse(JSON.stringify(data.value)) //将源数据储存
        data.value.forEach(item => {
          if (item.is_enable == 1) {
            item.is_enable = true
          } else {
            item.is_enable = false
          }
        })
        this.tableData_speDetails = JSON.parse(JSON.stringify(data.value))
        this.operateState = 1
        this.dialogVisible = true
      },

      /* 显示货品分类弹框 */
      show_productType() {
        this.show_productTypebox = true;

        this.SHOW_PRODUCTTYPEBOX(true)
      },

      /* 关闭弹框 */
      close_box() {
        this.$refs['specification_ruleform'].resetFields(); //移除表单验证规则
        this.dialogVisible = false
        this.tableData_speDetails = [],
          this.specificationEidt = { //当前正在编辑的数据
            name: '',
            state: '',
            remark: '',
            product_type: '', //货品分类
            product_typeId: '', //货品分类ID
            state: true, //默认启用
          }
      },

      /* 新增行 */
      add() {
        this.tableData_speDetails.push({
          value: '',
          is_enable: true,
        })
      },

      /* 删除行 */
      remove(data, index) {
        this.tableData_speDetails.splice(index, 1)
      },
    },
    components: {
      producttype,
    },
    filters: {
      getProclassfiName(value) {
        let result = "";
        if (!value && typeof(value) != 'undefined' && value != 0) {
          result = result
        } else {
          result = value.name
        }
        return result
      }
    }
  }
</script>

<style lang="less" scoped="scoped">
  .billing_table {
    height: 240px;
    overflow-y: auto;
  }

  .specificationStr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
