<template>
  <div class="ItemIndex_tabBox">
    <el-tabs v-model="ProductionTabIndex" v-if="isPower">
      <el-tab-pane label="工序分类" name="4.1">
        <bom></bom>
      </el-tab-pane>
<!--      <el-tab-pane label="工序类型" name="4.2">
        <process></process>
      </el-tab-pane> -->
      <el-tab-pane label="计费方式" name="4.3">
        <chargeMode></chargeMode>
      </el-tab-pane>
      <el-tab-pane label="生产类型" name="4.4">
        <type></type>
      </el-tab-pane>
      <el-tab-pane label="加工单位" name="4.5">
        <processUnit></processUnit>
      </el-tab-pane>
      <el-tab-pane label="不良品项" name="4.6">
        <processRejects></processRejects>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  // 导入组件
  import bom from './productionItem/production_bom.vue';//BOM分类
 /* import process from './productionItem/production_process.vue'; *///工序类型
  import chargeMode from './productionItem/production_chargeMode.vue';//计费方式
  import type from './productionItem/production_type.vue';//生产类型
  import processUnit from './productionItem/production_processUnit.vue';//加工单位
  import processRejects from './productionItem/production_processRejects.vue';//不良品项

  export default{
    name:'dictonary_production',
    data(){
      return{
        ProductionTabIndex:'4.1',
        isPower: false, //是否有查询权限
      }
    },
    watch:{
      ProductionTabIndex(newVal){
        this.PRODUCTION_TAB_INDEX(newVal)
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    created() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 7, 1, '查询')) {
            return
          }
          this.isPower = true
        }
    },
    methods:{
    ...mapMutations([
      'PRODUCTION_TAB_INDEX'
    ]),
    ...mapActions([
      'getUserPowerInfo', //获取用户操作权限
    ]),
    },
    components:{
      bom,
    /*  process, */
      chargeMode,
      type,
      processUnit,
      processRejects
    }
  }
</script>

<style>
</style>
