<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：系统相关请求接口
开始时间：2021-03-13
开发人员：刘巍骏,万攀
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->

<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" type="primary" @click="addClassfiy">新增</el-button>
      <el-button size="small" type="primary" v-if="show_wechatWork" @click="synAllWechat">同步到企业微信</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table id="classifyTable" ref="singleTable" :data="classfiyData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateClassfiy(scope.row)" title="修改"></i>
            <i class="el-icon-delete icon" @click="deleteCustomerClassfiy(scope.row)" title="删除"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="200"></el-table-column>
        <!-- <el-table-column prop="ys" label="颜色" width="120">
          <template slot-scope="scope">
            <el-color-picker v-model="scope.row.ys" size="mini" :predefine="predefineColors"></el-color-picker>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="zt" label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.zt" disabled>启用</el-checkbox>
          </template>
        </el-table-column> -->
        <el-table-column prop="zt" label="是否已同步企业微信" width="200">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_src_qiyeweixin==1?true:false" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="sm" label="说明" min-width="120"></el-table-column>
      </el-table>
    </div>

    <!--修改客户分类弹窗 -->
    <el-dialog :title="(operateClassfiy == 0 ?'新增':'修改') + '客户分类'" :visible.sync="show_addBox" width="400px"
      :before-close="close" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :rules="classfi_rules" ref="classfi_ruleform" :model="classfiyStr" size="mini" label-width="120px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="classfiyStr.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="企业微信同步:">
                <el-checkbox v-model="sysChat"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="saveClassfiy">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';
  //导入方法
  import api from '@/network/system/dictionary'; //数据字典相关方法
  import controlApi from '@/network/system/controls.js'; //控制权限相关方法
  import qywxApi from '@/network/thirdParty/qywxApi'; //企业微信相关接口

  export default {
    name: 'customer_classfi',
    data() {
      return {
        //当前添加的数据
        classfiyStr: {
          name: '',
        },
        sourceData: {}, //定义编辑源数据
        classfi_rules: [], //表单验证
        classfiyData: [],
        classfiyEidt: {},

        sysChat: true, //企业微信默认同步

        //控制开关
        show_addBox: false, //控制是否显示新增修改弹框
        show_wechatWork: false, //控制是否显示同步到企业微信按钮

        //判断条件
        operateClassfiy: 0,

        //loading加载
        loading: '',
      }
    },
    computed: {
      ...mapState({
        CustomerClassfiy: state => state.system.CustomerClassfiy,
        CustomerTabIndex: state => state.system.CustomerTabIndex, //客户数据字典选中下标
      }),
    },
    created() {
      // 初始化表单验证规则
      this.initRules();
    },
    mounted() {
      //默认获取客户分类数据
      this.getCustomerClassfiy();
      //判断模块相应权限
      this.getPower();
    },
    watch: {
      //监听客户相关数据字典选项卡下标
      CustomerTabIndex(newVal) {
        console.log(newVal);
        //判断下标是否为客户分类
        if (newVal == "0.1") {
          //判断vuex是否存在数据
          this.getCustomerClassfiy();
        }
      }
    },
    methods: {
      ...mapMutations([
        'CUSTOMER_CLASSIFY' //获取客户分类
      ]),
      ...mapActions([
        'getCustomerClassify' //获取客户分类
      ]),

      /* 获取相关权限 */
      getPower() {
        //获取企业微信模块权限
        this.getWechatWorkPower();
      },

      /* 判断企业微信模块是否开通 */
      getWechatWorkPower() {
        //定义后端请求参数
        let data = {
          functionCode: 'enterprise_wechat',
        }
        //发送请求
        controlApi.findModelControlByModelCode(data).then(res => {
          if (res.code == 200) {
            //判断模块是否开通
            if (res.data == 0) { //开通
              this.show_wechatWork = true;
            } else { //未开通或已到期
              this.show_wechatWork = false;
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /*获取客户分类数据*/
      getCustomerClassfiy(type) {
        //加载Loading
        this.loading = this.commonJsExtend.customLoading("#classifyTable", 3);
        //发送请求
        api.customerDict.getCustomerClassfiy().then(res => {
          if (res.code == 200) {
            //将数据存到vuex
            this.CUSTOMER_CLASSIFY(res.data);
            //将数据存到当前页面
            this.classfiyData = res.data;
            //判断操作类型
            if (type == 1) {
              this.$message({
                type: 'success',
                message: '数据添加成功',
                duration: this.elDuration
              })
            } else if (type == 2) {
              this.$message({
                type: 'success',
                message: '数据修改成功',
                duration: this.elDuration
              })
            } else if (type == 3) {
              this.$message({
                type: 'success',
                message: '数据删除成功',
                duration: this.elDuration
              })
            } else if (type == 4) {
              this.$message({
                type: 'success',
                message: '数据同步成功,可前往企业微信查看!',
                duration: this.elDuration
              })
            }
          }
          this.loading.close(); //关闭loading框
        })
      },

      /* 新增客户分类*/
      addClassfiy() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        //清空添加数据
        this.classfiyStr = {
          name: '', //客户分类名称
        }
        //显示新增弹框
        this.show_addBox = true;
        //设置操作类型为新增
        this.operateClassfiy = 0;
      },

      /* 删除数据*/
      deleteCustomerClassfiy(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除后企业微信的标签也将同步删除!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载Loading框
          this.loading = this.commonJsExtend.customLoading("#classifyTable", 2);
          //发送请求
          api.customerDict.deleteCustomerClassfiy({
            classifyid: data.id, //客户分类id
            name: data.name //客户分类名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              //删除企业微信分类
              this.deleteWechat(data.wx_tag_id, 1);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: this.elDuration
          });
        });
      },

      /* 修改数据 */
      updateClassfiy(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.show_addBox = true; //显示编辑框
        this.classfiyStr.name = data.name; //将编辑信息赋值到编辑框
        this.sourceData = JSON.parse(JSON.stringify(this.classfiyStr)) //将源数据储存
        this.classfiyEidt = data; //存入当前编辑信息
        this.operateClassfiy = 1; //设置当前状态为修改
        this.sysChat = data.is_src_qiyeweixin == 1 ? true : false;
      },

      /* 保存数据*/
      saveClassfiy() {
        // 校验输入款是否满足规则
        this.$refs['classfi_ruleform'].validate(valid => {
          if (valid) {
            this.show_addBox = false; //关闭新增输入框
            //判断是修改还是新增
            if (this.operateClassfiy == 0) {
              var data = {
                verifystr: this.classfiyStr.name, //新增状态名称
              }
              this.loading = this.commonJsExtend.customLoading("#classifyTable", 0);
              //发送请求
              api.customerDict.addCustomerClassfiy(data).then((res) => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  // 判断是否同步到企业微信
                  if (this.sysChat) {
                    this.sysnAddToWeChat(res.data); //调用同步企业微信方法
                  } else {
                    this.getCustomerClassfiy(1) //获取新数组
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (this.operateClassfiy == 1) {
              let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.classfiyStr, '客户分类');
              var data = {
                can_repeat_cus: 1,
                old_can_repeat_cus: 1,
                old_value: this.classfiyEidt.name,
                classifyid: this.classfiyEidt.id,
                verifystr: this.classfiyStr.name,
                is_src_qiyeweixin: this.sysChat ? 1 : 0,
                logContent //日志
              }
              //加载Loading
              this.loading = this.commonJsExtend.customLoading("#classifyTable", 1);
              api.customerDict.updateCustomerClassfiy(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  // 判断是否同步到企业微信
                  if (this.sysChat) {
                    this.sysnUpdateToWeChat(this.classfiyEidt.id);
                  } else {
                    // 从企业微信删除
                    this.deleteWechat(this.classfiyEidt.wx_tag_id, 0);
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              });
            }
          }
        })
      },

      /* 全部同步企业微信*/
      synAllWechat() {
        this.$confirm("是否确定同步客户分类到企业微信!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载Loading框
          this.loading = this.commonJsExtend.customLoading("#classifyTable", 4, "数据同步中,请稍候!");
          //发送请求
          qywxApi.doSyncTagsToWeixin({
            tagGroupName: '客户分类'
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.getCustomerClassfiy(4); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          })
        })
      },

      /* 同步客户标签到企业微信 */
      sysnAddToWeChat(id) {
        let data = {
          id: id,
          tagGroupName: '客户分类'
        }
        qywxApi.doAddTag(data).then(res => {
          if (res.code == 200) {
            this.getCustomerClassfiy(1) //获取新数组
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 修改企业微信标签 */
      sysnUpdateToWeChat(id) {
        let data = {
          id: id,
          tagGroupName: '客户分类'
        }
        qywxApi.doUpdateTag(data).then(res => {
          if (res.code == 200) {
            this.getCustomerClassfiy(2) //获取新数组
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 删除企业微信标签 type(0:修改时删除  1:删除时删除)*/
      deleteWechat(wxTagId, type) {
        let data = {
          wx_tag_id: wxTagId,
        }
        qywxApi.doDeleteTag(data).then(res => {
          if (res.code == 200) {
            //判断是如何删除
            if (type == 0) {
              this.getCustomerClassfiy(2) //获取新数组
            } else if (type == 1) {
              this.getCustomerClassfiy(3) //获取新数组
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 初始话表单验证规则*/
      initRules() {
        this.classfi_rules = {
          name: [ //客户分类名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 40, //最大长度
              message: '长度在 1 到 40 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },

      /* 取消新增输入框*/
      close() {
        this.classfiyStr = {
          name: '',
        };
        this.$refs['classfi_ruleform'].resetFields(); //移除表单验证规则
        this.show_addBox = false
      },
    }
  }
</script>

<style>
</style>
