<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：生产相关计费分类请求接口
开始时间：2021-03-13
开发人员：姚雨宏
最后修改：2021-03-18
备注说明：如需修改请联系开发人员 -->
<template>
  <div class="ItemIndex_tabItem">
    <!-- 头部按钮 -->
    <div class="tabItem_headBox">
      <el-button size="small" @click="addCheck" type="primary">新增</el-button>
    </div>
    <!-- 内容表格 -->
    <div class="tabItem_contentBox billing_table">
      <el-table :data="ProductionBomType" row-key="id" id="bomType_table" height="100%" border style="width: 100%">
        <!-- 拖拽图标 -->
        <el-table-column width="50">
          <template slot-scope="scope">
            <i class="el-icon-s-grid my-handle"></i>
          </template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" @click="updateCheck(scope.row)" title="修改"></i>
            <div v-if="scope.row.is_system==0">
              <i class="el-icon-delete icon" @click="deleteteBomType(scope.row)" title="删除"></i>
            </div>

          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable="" width="120"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_enable==1" disabled>启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="系统默认" width="200">
          <template slot-scope="scope">
            <el-checkbox :value="scope.row.is_system==1" disabled></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="说明" min-width="200"></el-table-column>
      </el-table>
    </div>

    <!--修改增加弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + 'BOM分类'" :visible.sync="show_saveProductBoxTypeBox"
      id="adupdate" width="400px" :before-close="cancleBomType" append-to-body>
      <!-- 弹框内容-->
      <div class="financeDialog scollStyle">
        <el-form :model="saveBomType" label-width="80px" :rules="bomType_rules" ref="bomType_ruleform" size="mini">
          <!-- 分行显示 -->
          <el-row>
            <!-- 分列显示,span的值为24/列数 如:一行一列为24  一行两列为12-->
            <el-col :span="24">
              <el-form-item label="名称:" prop="name">
                <el-input v-model.trim="saveBomType.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="状态:">
                <el-checkbox v-model="saveBomType.is_enable">启用</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="说明:">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="saveBomType.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancleBomType">取 消</el-button>
        <el-button size="small" type="primary" @click="saveProductBomType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/dictionary'
  export default {
    name: "production_bom",
    data() {
      return {
        bomType_rules: [], //弹窗表单规则
        show_saveProductBoxTypeBox: false, //控制弹窗开关
        //判断条件
        operateState: 0, //判断新增还是修改(0:新增 , 1::修改)
        saveBomType: {
          name: '',
          remark: '',
          is_enable: '',
          is_system: '',
          old_name: '',
        }, //存放增加修改的值

        //定义编辑源数据
        sourceData: {},
        //loading框
        loading: '', //控制显示loading框
      }
    },
    created() {
      this.initRules();
      //判断vuex是否存在数据
      this.judjeProductionBomType();
    },
    mounted() {
      //初始化排序拖动
      this.commonJsExtend.customDragrow("#bomType_table>.el-table__body-wrapper > table > tbody");
    },
    computed: {
      ...mapState({
        DictionaryLeftIndex: state => state.system.DictionaryLeftIndex, //左侧导航栏下标
        ProductionBomType: state => state.system.ProductionBomType, //vuex中的bom分类数据
        ProductionTabIndex: state => state.system.ProductionTabIndex, //当前下标
        SortIndex: state => state.system.SortIndex, //排序下标
      }),
    },
    watch: {
      //监听生产相关数据字典选项卡下标
      ProductionTabIndex(newVal) {
        //判断下标是否为BOM分类
        if (newVal == "4.1") {
          //判断vuex是否存在数据
          this.judjeProductionBomType();
        }
      },
      //拖动排序
      SortIndex: {
        deep: true,
        handler(newVal) {
          this.changeSortNum(newVal);


        }
      },
    },
    methods: {
      ...mapMutations([
        'PRODUCTION_BOMTYPE', //把bom分类放入vuex
      ]),
      cancleBomType() {
        this.$refs['bomType_ruleform'].resetFields(); //移除表单验证规则
        this.show_saveProductBoxTypeBox = false;
      },
      /* 初始话表单验证规则*/
      initRules() {
        this.bomType_rules = {
          name: [ //bom分类名称规则
            { //规则1
              required: true, //是否必填
              message: '请输入名称', //该规则错误信息提示
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            },
            { //规则2
              min: 1, //最小长度
              max: 5, //最大长度
              message: '长度在 1 到 5 个字符', //该规则错误提示信息
              trigger: 'blur' //哪种事件触发(blur为失去焦点事件)
            }
          ]
        }
      },
      /* 判断vux中是否有数据*/
      judjeProductionBomType() {
        // vuex中没有数据,从服务器请求
        if (this.ProductionBomType.length == 0) {
          this.findAllBomType();
        }
      },
      /* 点击新增按钮触发*/
      addCheck() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 2, '新增')) {
          return
        }
        this.operateState = 0;
        this.saveBomType = {
          name: '',
          remark: '',
          is_enable: true,
          is_system: '',
          old_name: '',
        };
        this.show_saveProductBoxTypeBox = true;
      },
      /* 点击修改按钮触发*/
      updateCheck(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 4, '修改')) {
          return
        }
        this.operateState = 1;
        this.saveBomType.old_name = data.name;
        this.saveBomType.name = data.name;
        this.saveBomType.id = data.id;
        this.saveBomType.remark = data.remark;
        this.saveBomType.is_enable = data.is_enable == 1 ? true : false;
        this.show_saveProductBoxTypeBox = true;
        this.sourceData = JSON.parse(JSON.stringify(this.saveBomType)) //将源数据储存
      },

      /* 查询所有BOM分类*/
      findAllBomType(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#bomType_table", 3);
        //发送请求
        api.production.findAllBomType().then(res => {
          if (res.code == 200) {
            //把查询到的数据放入vuex
            this.PRODUCTION_BOMTYPE(res.data);
            //判断获取数据之前是哪种操作(0:刷新   1:新增   2:修改   3:删除  4:排序)
            if (type == 1) { //添加
              this.$message({
                type: 'success',
                message: '数据添加成功!',
                duration: this.elDuration
              })
            } else if (type == 2) { //修改
              this.$message({
                type: 'success',
                message: '数据修改成功!',
                duration: this.elDuration
              })
            } else if (type == 3) { //删除
              this.$message({
                type: 'success',
                message: '数据删除成功!',
                duration: this.elDuration
              })
            } else if (type == 4) { //排序
              this.$message({
                type: 'success',
                message: '数据排序成功!',
                duration: this.elDuration
              })
            }
            this.loading.close(); //关闭loading框
          }
        });
      },
      /* 增加修改bom分类*/
      saveProductBomType() {
        this.$refs['bomType_ruleform'].validate(valid => {
          if (valid) {
            //判断是增加还是修改
            if (this.operateState == 0) { //增加
              let data = {
                name: this.saveBomType.name,
                remark: this.saveBomType.remark,
                is_enable: this.saveBomType.is_enable ? 1 : 0,
                is_system: '0'
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#bomType_table", 0);
              //发送请求
              api.production.addBomType(
                data
              ).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllBomType(1); //获取新数组
                } else { //新增失败
                  // 显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              this.show_saveProductBoxTypeBox = false;
            } else if (this.operateState == 1) { //修改
            let logContent = this.commonJsExtend.mergeLogContent(this.sourceData, this.saveBomType, 'BOM分类');
              let data = {
                name: this.saveBomType.old_name,
                new_name: this.saveBomType.name,
                id: this.saveBomType.id,
                remark: this.saveBomType.remark,
                is_enable: this.saveBomType.is_enable ? 1 : 0,
                is_system: '0',
                logContent//日志
              }
              //加载loading框
              this.loading = this.commonJsExtend.customLoading("#bomType_table", 1);
              //发送请求
              api.production.updateBomType(data).then(res => {
                this.loading.close(); //关闭loading框
                if (res.code == 200) {
                  this.findAllBomType(2); //获取新数组
                } else {
                  //显示提示信息
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
              this.show_saveProductBoxTypeBox = false;
            }
          }
        })

      },

      /* 删除bom分类*/
      deleteteBomType(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 7, 3, '删除')) {
          return
        }
        this.$confirm("您确认删除该数据吗?删除的数据不可恢复!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#bomType_table", 2);
          //发送请求
          api.production.deleteteBomType({
            id: data.id,//bom分类id
            name:data.name//bom分类名称
          }).then(res => {
            this.loading.close(); //关闭loading框
            if (res.code == 200) {
              this.findAllBomType(3); //获取新数组
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              });
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
              duration: this.elDuration
            });
          })
        })

      },
      /* 拖动排序*/
      changeSortNum(newVal) {
        if (this.ProductionTabIndex == "4.1" && this.DictionaryLeftIndex == 4) {
          let data = {
            sourceId: this.ProductionBomType[newVal.oldIndex].id,
            targetId: this.ProductionBomType[newVal.newIndex].id
          }
          this.loading = this.commonJsExtend.customLoading("#bomType_table", 1);
          api.production.updateSysMesBomTypeSort(data).then(res => {
            if (res.code == 200) {
              this.loading.close();
              this.findAllBomType(4); //获取新数组
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: this.elDuration
              })
              this.loading.close();
            }
          })
        }
      },
    },

  }
</script>

<style>
</style>
